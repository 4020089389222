import React, { useEffect, useState } from "react";
import {
  Text,
  Flex,
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  VStack,
  Divider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { getUserAll, updateUserPermissions, addAgencyUsers,deleteAgencyUser } from "../../Services/api";
import { useNavigate } from "react-router-dom";
import TableHead from "../Campaigns/components/TableHead";
import { Checkbox } from "@chakra-ui/react";
import { OvalSpinner } from "../../components/Loaders";
import Swal from "sweetalert2";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import SecondaryButton from "../../components/themeComponents/SecondaryButton";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const OrgUserDetails = () => {
  const [adminUsers, setAdminUsers] = useState([]);
  const [agencyUsers, setAgencyUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userPermissions, setUserPermissions] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorMessage, setErrorMessage] = useState();
  
  const navigate = useNavigate();
  const token = window.location.pathname.split("/")[3];

  const adminHeadings = ["Name", "Email", "Email Verified", "Allowed By Admin"];
  const agencyHeadings = ["Name", "Email", "Email Verified", "Actions"];

  const fetchUsers = async (token) => {
    setIsLoading(true);
    try {
      const response = await getUserAll(token);
      setAdminUsers(response.data.admins);
      setAgencyUsers(response.data.agency_users);
      setAllUsers(response.data.all);

      const permissions = {};
      const allFetchedUsers = [
        ...response.data.admins,
        ...response.data.agency_users,
        ...response.data.all,
      ];

      allFetchedUsers.forEach((user) => {
        permissions[user.email] = {
          isEmailVerified: user.emailVerified,
          isAllowedByAdmin: user.allowedByAdmin,
        };
      });
      setUserPermissions(permissions);
    } catch (error) {
      console.error("Error fetching data", error);
    }
    setIsLoading(false);
  };

  const handleCheckboxChange = (email, type) => {
    setUserPermissions((prev) => ({
      ...prev,
      [email]: {
        ...prev[email],
        [type]: !prev[email][type],
      },
    }));
  };

  const handleRevokeAccess = async (email) => {
    Swal.fire({
      title: "Are you sure?",
      html: '<div style="text-align: center;">This will revoke access for this user.</div>',
      // text: "This will revoke access for this user.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, revoke it!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteAgencyUser(email, token);
          // Swal.fire("Revoked!", "Access has been revoked.", "success");
          Swal.fire({
            title: "Revoked!",
            html: '<div style="text-align: center;">Access has been revoked.!.</div>',
            // text: "Permissions updated successfully!",
            icon: "success",
            position: "center",
            // showConfirmButton: true,
          });
          fetchUsers(token); // Refresh the user list
        } catch (error) {
          console.error("Error revoking access", error);
          // Swal.fire("Error!", "There was an error revoking access.", "error");
          Swal.fire({
            title: "Error!",
            html: '<div style="text-align: center;">There was an error revoking access!.</div>',
            // text: "Permissions updated successfully!",
            icon: "error",
            // position: "center",
            // showConfirmButton: true,
          });
          
        }
      }
    });
  };
  
  

  const handleSubmit = async () => {
    const updateData = Object.entries(userPermissions).map(
      ([email, { isEmailVerified, isAllowedByAdmin }]) => ({
        email,
        isEmailVerified,
        isAllowedByAdmin,
      })
    );

    Swal.fire({
      title: "Are you sure?",
      html: '<div style="text-align: center;">to update user permissions.</div>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateUserPermissions(token, updateData);
          Swal.fire({
            title: "Updated!",
            html: '<div style="text-align: center;">Permissions updated successfully!.</div>',
            // text: "Permissions updated successfully!",
            icon: "success",
            position: "center",
            showConfirmButton: true,
          });
        } catch (error) {
          console.error("Error updating permissions", error);
          Swal.fire(
            "Error!",
            "There was an error updating the permissions.",
            "error"
          );
        }
      }
    });
  };

  const handleAddNewUser = async (values, { setSubmitting }) => {
    setErrorMessage(""); 
    try {
      const response = await addAgencyUsers(values, token);
      
      if (response.data["agent-addition-error"]) {
        Swal.fire("Error!", response.data["agent-addition-error"], "error");
      } else {
        // Swal.fire("Success!", "User added successfully!", "success");
        Swal.fire({
          title: "Success!",
          html: '<div style="text-align: center;">User added successfully!.</div>',
          // text: "Permissions updated successfully!",
          icon: "success",
          position: "center",
          // showConfirmButton: true,
        });
        onClose();
        fetchUsers(token);
      }
    } catch (error) {
      console.error("Error adding user", error);
      
      // Swal.fire("Error!", "There was an error adding the user.", "error");
      Swal.fire({
        title: "Error!",
        html: '<div style="text-align: center;">There was an error adding the user!.</div>',
        // text: "Permissions updated successfully!",
        icon: "error",
        position: "center",
        // showConfirmButton: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchUsers(token);
  }, [token]);

  const renderUserTable = (users, title, isAgencyUsers = false) => (
    <Box w="full" borderWidth={1} borderRadius="md" borderColor="gray.200" p={4}>
      <Flex justify="space-between" align="center">
        <Text fontSize="lg" fontWeight="semibold">
          {title}
        </Text>
        {isAgencyUsers && (
          <PrimaryButton label={"Add New Sales/CS Agent +"} size={"sm"} onClick={onOpen} />
        )}
      </Flex>

      <Table variant="simple" mt={4}>
        <TableHead headersProps={isAgencyUsers ? agencyHeadings : adminHeadings} />
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={isAgencyUsers ? 4 : 3} textAlign="center">
                <OvalSpinner height={20} />
              </Td>
            </Tr>
          ) : (
            users.map((item, index) => (
              <Tr key={index}>
                <Td>
                  {item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1)}
                </Td>
                <Td>{item.email}</Td>
                <Td>
                  <Checkbox
                    isChecked={userPermissions[item.email]?.isEmailVerified}
                    onChange={() => handleCheckboxChange(item.email, "isEmailVerified")}
                  />
                </Td>
                {isAgencyUsers ? (
                  <Td>
                    <PrimaryButton
                    label={"Revoke Access"}
                      // colorScheme="blue"
                      onClick={() => handleRevokeAccess(item.email)}
                    >
                      Revoke Access
                    </PrimaryButton>
                  </Td>
                ) : (
                  <Td>
                      <Checkbox
                    isChecked={userPermissions[item.email]?.isAllowedByAdmin}
                    onChange={() => handleCheckboxChange(item.email, "isAllowedByAdmin")}/>
                  </Td>
                )}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </Box>
  );

  return (
    <Box p={6} maxW="container.xl" mx="auto">
      <Flex direction="column" gap={6}>
        <Divider my={4} />
        <VStack align="start" spacing={4}>
          {renderUserTable(adminUsers, "Administrators")}
          <SecondaryButton label={"Update Permissions"} size={"sm"} onClick={handleSubmit} />

          {renderUserTable(allUsers, "User List")}
          <SecondaryButton label={"Update Permissions"} size={"sm"} onClick={handleSubmit} />

          {renderUserTable(agencyUsers, "Sales/CS Agents List", true)}
          <SecondaryButton label={"Update Permissions"} size={"sm"} onClick={handleSubmit} />
        </VStack>
      </Flex>

      {/* Modal for adding new user */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Sales/CS Agent</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("Invalid email address")
                  .required("Email is required"),
              })}
              onSubmit={handleAddNewUser}
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormControl>
                    <FormLabel htmlFor="email">Email Address</FormLabel>
                    <Field name="email">
                      {({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter email address"
                          size="md"
                        />
                      )}
                    </Field>
                    <ErrorMessage name="email" component={FormErrorMessage} />
                  </FormControl>

                  {/* Display error message if exists */}
                  {errorMessage && (
                    <Alert status="error" mt={4}>
                      <AlertIcon />
                      {errorMessage}
                    </Alert>
                  )}

                  <ModalFooter>
                    <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
                      Add User
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default OrgUserDetails;
