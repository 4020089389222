import axiosInstance from "../../../Services/axiosInstance";
import RappidService from "./RappidService";

let getGenericActionProps = (actionType, elementType, shapeColor, labelText, strokeColor, options, select_existing_type,
  create_new_type, templatesFetchUrl, createNewTemplateUrl, optionsType, iconUrl, connectableShapeTypes,
  tooltipDesc, availableActionEvents, ports, templateEditUrl) => {
  return {
    actionType, elementType, shapeColor, labelText, strokeColor, options, select_existing_type, create_new_type,
    templatesFetchUrl, createNewTemplateUrl, optionsType, iconUrl, connectableShapeTypes, tooltipDesc,
    availableActionEvents, ports, templateEditUrl
  };
};
let getConnectableShapes = () => {
  return {
    iniital: [FlowEditService.elementTypes.action, FlowEditService.elementTypes.thirdParty, FlowEditService.elementTypes.delay],
    condition: [FlowEditService.elementTypes.action, FlowEditService.elementTypes.thirdParty, FlowEditService.elementTypes.delay],
    delay: [FlowEditService.elementTypes.action, FlowEditService.elementTypes.thirdParty,FlowEditService.elementTypes.condition],
    actions: [FlowEditService.elementTypes.delay, FlowEditService.elementTypes.condition]
  }
}
let getGraphData = () => {
  let data = localStorage.getItem("graphData");
  data = JSON.parse(data);
  return data
}
const FlowEditService = {
  elementTypes: {
    initial: 'initial',
    condition: 'condition',
    delay: 'delay',
    action: 'action',
    thirdParty: 'thirdParty',
    comingSoon: 'comingSoon',
  },
  getInitialActionProps: () => {
    return getGenericActionProps('initial', 'initial', '#fe8f4f', 'Start', '#643b3b', null, null, null, '', '', 'radio',
      `${process.env.PUBLIC_URL}/images/flows/white/start.svg`, getConnectableShapes().iniital,
      'Start your flow by dragging this to the right on the canvas. This will be the starting point of the automation. You can then select actions, Delay or condition to come after it based on your use case.',
      null, [{ groupName: 'out', position: 'bottom', labelPosition: 'right', magnet: true }], '')
  },
  getConditionShapeProps: () => {
    return getGenericActionProps('condition', 'condition', '#5755a1', 'Condition', '#000000', null, null, null, null, null, 'radio',
      `${process.env.PUBLIC_URL}/images/flows/white/condition.svg`, getConnectableShapes().condition, 'Use this shape to make your selected action conditional.', null,
      [{ groupName: 'in', position: 'top', labelPosition: 'right', magnet: 'passive' }, { groupName: 'yes', position: 'left', labelPosition: 'left', magnet: true },
      { groupName: 'no', position: 'right', labelPosition: 'right', magnet: true }], '')
  },
  getDelayShapeProps: () => {
    return getGenericActionProps('delay', 'delay', "#5755a1", 'Delay', '#000000', null, null, null, null, null, null, `${process.env.PUBLIC_URL}/images/flows/white/delay.svg`,
      getConnectableShapes().delay, 'Provide the dealy to trigger the following action after the desired duration. Set delay to 0(zero) to trigger the follwing action immediately. ',
      null, [{ groupName: 'in', position: 'top', labelPosition: 'right', magnet: 'passive' }, { groupName: 'out', position: 'bottom', labelPosition: 'right', magnet: true }], '')
  },
  getEmailShape: () => {
    return getGenericActionProps('email', 'action', '#8c2b2b', 'Email', '#000000', ['select_existing', 'create_new'], 'dropdown', 'open_editor_window',
      '/rest/v1/flow/email/templates/all', '/emailtemplate/#/edit', 'radio', `${process.env.PUBLIC_URL}/images/flows/white/email.svg`, getConnectableShapes().actions,
      'This action sends an Email to every user that preceeds in the Automation based on the condition set.', [{ code: 'opened', label: 'Opened' }, { code: 'clicked', label: 'Clicked' },{ code: 'delivered', label: 'Delivered' }],
      [{ groupName: 'in', position: 'top', labelPosition: 'right', magnet: 'passive' }, { groupName: 'out', position: 'bottom', labelPosition: 'right', magnet: true }],
      '/emailtemplate/#/edit?uuid=')
  },
  getSmsShape: () => {
    return getGenericActionProps('sms', 'action', '#8c2b2b', 'SMS', '#000000', ['select_existing', 'create_new'], 'dropdown', 'create_in_inspector',
      '/rest/v1/flow/sms/templates/all', '/sms/#/template-edit', 'radio', `${process.env.PUBLIC_URL}/images/flows/white/sms.svg`, getConnectableShapes().actions,
      'This action sends an SMS to every user that preceeds in the Automation based on the condition set.', [{ code: 'clicked', label: 'Clicked URL in SMS' },{ code: 'delivered', label: 'Delivered' }],
      [{ groupName: 'in', position: 'top', labelPosition: 'right', magnet: 'passive' }, { groupName: 'out', position: 'bottom', labelPosition: 'right', magnet: true }],
      '/sms/#/template-edit?uuid=',
    )
  },
  getWhatsappShape: () => {
    return getGenericActionProps('whatsapp', 'action', '#8c2b2b', 'WhatsApp', '#000000', ['select_existing', 'create_new'], 'dropdown', 'open_editor_window',
      '/rest/v1/flow/whatsapp/templates/all', '/whatsapp/whatsapp-templates/#/create/', 'radio', `${process.env.PUBLIC_URL}/images/flows/white/whatsapp.svg`, getConnectableShapes().actions,
      'This action sends an message on whatsapp to every user that preceeds in the Automation based on the condition set.', [{ code: 'clicked', label: 'Clicked' },{ code: 'delivered', label: 'Delivered' }],
      [{ groupName: 'in', position: 'top', labelPosition: 'right', magnet: 'passive' }, { groupName: 'out', position: 'bottom', labelPosition: 'right', magnet: true }],
      '/whatsapp/whatsapp-templates/#/create/?uuid='
    )
  },
  getWebHookShape: () => {
    return getGenericActionProps('webhook', 'action', '#8c2b2b', 'webhook', '#000000', ['select_existing', 'create_new'], 'dropdown', 'open_editor_window',
      '/hooks/rest/v1/template', '/hooks/rest/v1/template', 'radio', `${process.env.PUBLIC_URL}/images/flows/white/webhook.svg`, getConnectableShapes().actions,
      'This action sends an message on whatsapp to every user that preceeds in the Automation based on the condition set.', [{ code: 'clicked', label: 'Clicked' },{ code: 'delivered', label: 'Delivered' }],
      [{ groupName: 'in', position: 'top', labelPosition: 'right', magnet: 'passive' }, { groupName: 'out', position: 'bottom', labelPosition: 'right', magnet: true }],
      '/webhook/webhook-templates/#/create/?uuid='
    )
  },
  getWebPush:()=>{
    return getGenericActionProps('webpush', 'action', '#8c2b2b', 'webpush', '#000000', ['select_existing', 'create_new'], 'dropdown', 'open_editor_window',
      '/rest/v1/flow/web_push/templates/all', '/push/#/template-edit', 'radio', `${process.env.PUBLIC_URL}/images/flows/white/webpush.svg`, getConnectableShapes().actions,
      'This action sends an message on whatsapp to every user that preceeds in the Automation based on the condition set.', [{ code: 'clicked', label: 'Clicked' },{ code: 'delivered', label: 'Delivered' }],
      [{ groupName: 'in', position: 'top', labelPosition: 'right', magnet: 'passive' }, { groupName: 'out', position: 'bottom', labelPosition: 'right', magnet: true }],
      '/webPush/webPush-templates/#/create/?uuid='
    )
  },
  getAppPush:()=>{
    return getGenericActionProps('appPush', 'action', '#8c2b2b', 'appPush', '#000000', ['select_existing', 'create_new'], 'dropdown', 'open_editor_window',
      '/rest/v1/flow/mobile/templates/all', '/mobile/#/template-edit', 'radio', `${process.env.PUBLIC_URL}/images/flows/white/appPush.svg`, getConnectableShapes().actions,
      'This action sends an message on whatsapp to every user that preceeds in the Automation based on the condition set.', [{ code: 'clicked', label: 'Clicked' },{ code: 'delivered', label: 'Delivered' }],
      [{ groupName: 'in', position: 'top', labelPosition: 'right', magnet: 'passive' }, { groupName: 'out', position: 'bottom', labelPosition: 'right', magnet: true }],
      '/webPush/webPush-templates/#/create/?uuid='
    )

  },
  getFacebook:()=>{
    return getGenericActionProps('facebook', 'action', '#8c2b2b', 'facebook', '#000000', ['select_existing', 'create_new'], 'dropdown', 'open_editor_window',
      '', '', 'radio', `${process.env.PUBLIC_URL}/images/flows/white/facebook.svg`, getConnectableShapes().actions,
      'This action sends an message on whatsapp to every user that preceeds in the Automation based on the condition set.', [{ code: 'clicked', label: 'Clicked' },{ code: 'delivered', label: 'Delivered' }],
      [{ groupName: 'in', position: 'top', labelPosition: 'right', magnet: 'passive' }, { groupName: 'out', position: 'bottom', labelPosition: 'right', magnet: true }],
      '/webPush/webPush-templates/#/create/?uuid='
    )
  },
  prepareShapes: (actionProps, joint) => {
    return {
      initialShape: RappidService.setElementProps((RappidService.createCustomShapeObject(actionProps.initialShape, joint)), actionProps.initialShape),
      conditionShape: RappidService.setElementProps((RappidService.createCustomShapeObject(actionProps.conditionShape, joint)), actionProps.conditionShape),
      delayShape: RappidService.setElementProps((RappidService.createCustomShapeObject(actionProps.delayShape, joint)), actionProps.delayShape),
      emailShape: RappidService.setElementProps((RappidService.createCustomShapeObject(actionProps.emailShape, joint)), actionProps.emailShape),
      smsShape: RappidService.setElementProps((RappidService.createCustomShapeObject(actionProps.smsShape, joint)), actionProps.smsShape),
      whatsappShape: RappidService.setElementProps((RappidService.createCustomShapeObject(actionProps.whatsappShape, joint)), actionProps.whatsappShape),
      webHookShape: RappidService.setElementProps((RappidService.createCustomShapeObject(actionProps.webHookShape, joint)), actionProps.webHookShape),
      webPushShape: RappidService.setElementProps((RappidService.createCustomShapeObject(actionProps.webPushShape, joint)), actionProps.webPushShape),
      appPushShape: RappidService.setElementProps((RappidService.createCustomShapeObject(actionProps.appPushShape, joint)), actionProps.appPushShape),
      facebookShape:RappidService.setElementProps((RappidService.createCustomShapeObject(actionProps.facebookShape, joint)), actionProps.facebookShape),
    }
  },
  addCss: () => {
    window.$(`#rappid-paper-container .joint-port circle[port-group='yes']+text`).css('transform', 'matrix(1, 0, 0, 1, 0, -11)');
    window.$(`#rappid-paper-container .joint-port circle[port-group='no']+text`).css('transform', 'matrix(1, 0, 0, 1, 0, -11)');
    window.$(`#rappid-paper-container .joint-port circle[port-group='in']+text`).css('transform', 'matrix(1, 0, 0, 1, 8, -6)');
    window.$(`#rappid-paper-container .joint-port circle[port-group='out']+text`).css('transform', 'matrix(1,0,0,1,7,4)');
    window.$(`#rappid-paper-container .joint-port circle, #rappid-paper-container .joint-port text`).css({ 'display': 'block', 'opacity': 1 });
  },
  getFormDataForElement: (actionData, elementId) => {
    return {
      action: {
        type: actionData.actionType, // can be email, sms, onsite, push or webhook
        templateUUID: '', // UUID of any action, most probably template UUID of the selected action.
      },
      ruleType: 'EVENT',
      liveSegmentId: null,
      nextActionCondition: {
        //criteria: [{'property':'','value':[],'operator':''}]
        criteria: [
          { 'property': 'EventName', 'operator': 'Equals', 'value': [] },
          //{'property':'EventValue', 'operator':'', 'value':[]}
        ]
      },
      elementId: elementId, // UUID provided by rappid js
      nextElementIds: [], // array (of String type) of element id's to the next element which is supposed to be action. null in case of endsWith.
      previousElementIds: [], // array (of String type) of elements from where this element has been called. null in case of beginsWith
      wait: {
        type: 'delay', // choose from delay or scheduled. default is delay of 0 seconds
        duration: 60 * 60, // epoch seconds in case of scheduled and seconds in case of delay (which can be converted into minutes, hours, days, weeks, months or years),
        formInputs: {
          delayValue: 1,
          delayDuration: 'hours'
        }
      },
      reach: 0, // number of customers reached to this level.
      actionData: actionData, // for frontend use only
      nextTriggeredElements: {},
      triggerAt: '',
    }
  },
  initialFormValidationData: () => {
    return {
      isFormValid: true,
      reasons: {
        genericReasons: [],
        elementReasons: {}
      }
    }
  },
  initialCriteria: [{ 'property': 'EventName', 'operator': 'Equals', 'value': [] }],
  eventValueOperators: [
    {
      "label": "Equals",
      "key": "Equals"
    },
    {
      "label": "Equals (Ignore case)",
      "key": "EqualsIgnoreCase"
    },
    {
      "label": "Starts with",
      "key": "StartsWith"
    },
    {
      "label": "Ends with",
      "key": "EndsWith"
    },
    {
      "label": "In",
      "key": "IN"
    },
    {
      "label": "Contains",
      "key": "Contains"
    },
    {
      "label": "Regular Exp",
      "key": "RegExp"
    },
    {
      "label": "Doesn't contain",
      "key": "DoesNotContain"
    },
    {
      "label": "NotEquals",
      "key": "NotEquals"
    },
    {
      "label": "ContainsIn",
      "key": "ContainsIn"
    },
    {
      "label": "GreaterThan",
      "key": "GreaterThan"
    },
    {
      "label": "GreaterThanEquals",
      "key": "GreaterThanEquals"
    },
    {
      "label": "LessThan",
      "key": "LessThan"
    },
    {
      "label": "LessThanEquals",
      "key": "LessThanEquals"
    },
    {
      "label": "DoesNotContainsIn",
      "key": "DoesNotContainsIn"
    }
  ],
  getFormActionProps: () => {
    return {
      goal: {
        hasGoal: false,
        eventName: '',
        eventValue: []
      },
      scheduler: {
        isScheduled: false,
        isEnableScheduled: false,
        isDisableScheduled: false,
        enableAtDateTime: 0,
        disableAtDateTime: 0
      },
      followedBy: {
        isFollowedBy: false,
        operator: '',
        eventName: '',
        expiry: '10',
        timeUnit: 'SECONDS'

      }
    };
  },
  getFollowedByDurations: () => {
    return [
      { key: 'SECONDS', label: 'SECONDS' },
      { key: 'MINUTES', label: 'MINUTES' },
      { key: 'HOURS', label: 'HOURS' },
      { key: 'DAYS', label: 'DAYS' }
    ];
  },
  getDelayDuration: () => {
    return [{ key: 'Seconds', value: 'seconds' },
    { key: 'Minutes', value: 'minutes' },
    { key: 'Hours', value: 'hours' },
    { key: 'Days', value: 'days' },
    { key: 'Weeks', value: 'weeks' }];
  },
  getTemplatesForAction: (path) => {
    return axiosInstance.get(path)
  },
  getTimer: () => {
    return {
      occurrences: 1,
      duration: 1,
      durationType: 'DAYS'
    };
  },
  prepareFlowData: (ruleName, ruleDescription, formData, actionProps, timer, uuid, ruleStatus) => {
    let scheduleDateTime = actionProps.scheduler.enableAtDateTime;
    let formActionProps = { ...actionProps };
    let scheduler = { ...actionProps.schduler };
    let goalCondition = {...actionProps.goal};
    !!goalCondition.hasGoal && (() => {
      goalCondition.eventValue = goalCondition.eventValue.map(ev => {
        return ev.id;
      })
    })()
    !!scheduler.isScheduled && (scheduler.enableAtDateTime = Number(scheduleDateTime.getTime() / 1000).toFixed(0));
    !!scheduler.isDisableScheduled && (scheduler.disableAtDateTime = Number(scheduler.disableAtDateTime.getTime() / 1000).toFixed(0));
    formActionProps.schduler = scheduler;
    formActionProps.goal = goalCondition;
    let retVal = {
      graphData: getGraphData(),
      formData: formData,
      timer: timer,
      scheduledDateTime: scheduleDateTime,
      actionProps: formActionProps,
      version: 'v2',
      ruleName: ruleName,
      ruleDescription: ruleDescription,
      uuid: uuid || '',
      ruleStatus: ruleStatus || ''

    }
    return retVal;
  },
  elementTypeActiveImages: {
    initial: process.env.PUBLIC_URL + '/images/flows/active/start.svg',
    condition: process.env.PUBLIC_URL + '/images/flows/active/condition.svg',
    delay: process.env.PUBLIC_URL + '/images/flows/active/delay.svg',
    email: process.env.PUBLIC_URL + '/images/flows/active/email.svg',
    sms: process.env.PUBLIC_URL + '/images/flows/active/sms.svg',
    whatsapp: process.env.PUBLIC_URL + '/images/flows/active/whatsapp.svg',
    webhook: process.env.PUBLIC_URL + '/images/flows/active/webhook.svg',

  },
  elementTypeWarningImages: {
    initial: process.env.PUBLIC_URL + '/images/flows/warning/start.svg',
    condition: process.env.PUBLIC_URL + '/images/flows/warning/condition.svg',
    delay: process.env.PUBLIC_URL + '/images/flows/warning/delay.svg',
    email: process.env.PUBLIC_URL + '/images/flows/warning/email.svg',
    sms: process.env.PUBLIC_URL + '/images/flows/warning/sms.svg',
    whatsapp: process.env.PUBLIC_URL + '/images/flows/warning/whatsapp.svg',
    webhook: process.env.PUBLIC_URL + '/images/flows/warning/webhook.svg',
  },
  elementTypeDefaultImages: {
    initial: process.env.PUBLIC_URL + '/images/flows/white/start.svg',
    condition: process.env.PUBLIC_URL + '/images/flows/white/condition.svg',
    delay: process.env.PUBLIC_URL + '/images/flows/white/delay.svg',
    email: process.env.PUBLIC_URL + '/images/flows/white/email.svg',
    sms: process.env.PUBLIC_URL + '/images/flows/white/sms.svg',
    whatsapp: process.env.PUBLIC_URL + '/images/flows/white/whatsapp.svg',
    webhook: process.env.PUBLIC_URL + '/images/flows/white/webhook.svg',
  },
  elementTypeErrorImages: {
    initial: process.env.PUBLIC_URL + '/images/flows/error/start.svg',
    condition: process.env.PUBLIC_URL + '/images/flows/error/condition.svg',
    delay: process.env.PUBLIC_URL + '/images/flows/error/delay.svg',
    email: process.env.PUBLIC_URL + '/images/flows/error/email.svg',
    sms: process.env.PUBLIC_URL + '/images/flows/error/sms.svg',
    whatsapp: process.env.PUBLIC_URL + '/images/flows/error/whatsapp.svg',
    webhook: process.env.PUBLIC_URL + '/images/flows/error/webhook.svg',
  }
}

export default FlowEditService;