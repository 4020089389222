
import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Input,
  Divider,
  FormControl,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { syncAnalyticsDB, getSyncAnalyticsDB } from "../../Services/api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PowerBiIntegration from "./PowerBiIntegration";
import { useSelector } from "react-redux";
import SyncCustomerDB from "./SyncCustomerDB";

const validationSchema = Yup.object({
  viewNames: Yup.string().required("Name is required"),
});

const OrgFeatures = () => {
  const navigate = useNavigate();
  const token = window.location.pathname.split("/")[3];
  const toast = useToast();
  const [syncData, setSyncData] = useState("");
  const orgInfo=useSelector((state)=>(state.auth.selectedOrg))
  // console.log(orgInfo,"orgIgo")

  const handleNavigation = () => {
    navigate(`/su/allUsers/${token}`);
  };

  const fetchSyncAnalytic = async () => {
    try {
      const response = await getSyncAnalyticsDB(token);
      console.log(response, "res[pnseeee in fetch");
      setSyncData(response.data.data || "");
    } catch (error) {
      console.error("Error while fetching analytics:", error);
    }
  };

  const handleSync = async (values, { setSubmitting }) => {
    // console.log("Form data:", values);
    try {
      await syncAnalyticsDB({ viewNames: values.viewNames }, token);
      toast({
        title: "Success",
        description: "Synced successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      await fetchSyncAnalytic();
    } catch (error) {
      console.error("Sync error:", error);
      toast({
        title: "Error",
        description: "Failed to sync",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchSyncAnalytic();
  }, [token]);

  return (
    <Box p={6} maxW="container.xl" mx="auto">
      <Flex direction="column" gap={6}>
        <Flex direction="column" gap={2}>
          <Text fontSize="2xl" fontWeight="bold">Manage Features for {orgInfo.name}</Text>
          <Text fontSize="xl" color="gray.600">Super Admin</Text>
          
        </Flex>

        <Divider my={4} />

        <Box>
          <Flex align="center" justify="space-between" mb={4}>
            <Text fontSize="lg" fontWeight="bold">User Details</Text>
            <PrimaryButton label={"View Details"} onClick={handleNavigation} size="sm" />
          </Flex>
        </Box>

        <Divider my={4} />

        <Box>
          <Flex direction="column" gap={4}>
            <Flex align="center" justify="space-between" mb={4}>
              <Text fontSize="lg" fontWeight="bold">Sync Analytics DB</Text>
            </Flex>

            <Formik
              initialValues={{ viewNames: syncData }} 
              validationSchema={validationSchema}
              onSubmit={handleSync}
              enableReinitialize 
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormControl isInvalid={!!(ErrorMessage && ErrorMessage.viewNames)}>
                    <Field name="viewNames">
                      {({ field }) => (
                        <Input
                          placeholder="Enter column names, separated by commas"
                          {...field}
                          size="md"
                        />
                      )}
                    </Field>
                    <ErrorMessage name="viewNames" component={FormErrorMessage} />
                  </FormControl>
                  <Box mt={4}>
                    <PrimaryButton label={"Submit"} type="submit" isLoading={isSubmitting} />
                  </Box>
                </Form>
              )}
            </Formik>
            <PowerBiIntegration />
            <SyncCustomerDB/>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default OrgFeatures;
