import React from 'react'
import { Modal, Box, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody } from '@chakra-ui/react'
import Editor from '../Email/Editor'
import { QueryClient, QueryClientProvider } from "react-query";
export default function TemplateCreateOrEditModal({isOpen, onClose, token}) {
    
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: Infinity,
                refetchOnReconnect: false, refetchOnWindowFocus: false, refetchInterval: Infinity
            }
        }
    });
    return (
        <Modal  isOpen={isOpen} onClose={onClose} size={"6xl"} scrollBehavior='inside'>
            <ModalOverlay></ModalOverlay>
            <ModalContent  >
                {/* <ModalHeader fontSize={'sm'}></ModalHeader> */}
                <ModalCloseButton onClick={onClose} ></ModalCloseButton>
                <ModalBody>
                    <QueryClientProvider client={queryClient}><Editor orgToken={token} closeModal={onClose} /></QueryClientProvider>
                </ModalBody>
            </ModalContent>
        </Modal>

    )
}
