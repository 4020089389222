import React, { useEffect, useState } from "react";
import { Checkbox, CheckboxGroup, VStack, Button, Box, useToast, Text } from "@chakra-ui/react";
import axios from "axios";
import axiosInstance from "../../Services/axiosInstance";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";

const JavascriptFeatures = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const toast = useToast();
  const [activeJsFeatures, setActiveJsFeatures] = useState([])
  const options = [
    "LiveActivities",
    "BroadcastCampaign",
    "EventsTracking",
    "BrowserPushNotification",
    "SmartBlock",
    "ExitIntent",
    "OnsitePushNotification",
    "Opt-In On Load",
    "Exit Intent CSS",
    "SPA",
    "Force HTTP Method of Opt-In",
    "IS BIGCOMMERCE",
    "IS SMS CONTACTS MAP",
    "APPEND MKT UTM RESTRICT",
    "HIDE OPT-IN BRANDING"
  ];
  const getJSFeatures = async () => {
    let res = await axiosInstance.get("/admin/jsfeatures")
    console.log(res.data)
  }
  const handleCheckboxChange = (checkedValues) => {
    setCheckedItems(checkedValues);
  };

  const handleSubmit = () => {
    let formData = new FormData()

    checkedItems.forEach((item) => {
      formData.append(item, true);
    });

    // Log formData to see if items are being appended
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
    axiosInstance.post("/admin/jsfeatures",
      formData
    )
      .then(response => {
        toast({
          title: "Success",
          description: "Options updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true
        });
      })
      .catch(error => {
        toast({
          title: "Error",
          description: "There was an error updating the options.",
          status: "error",
          duration: 3000,
          isClosable: true
        });
      });
  };
  useEffect(() => {
    getJSFeatures()
  }, [])

  return (
    <>
      <Box my={2} >
        <Text fontSize={'1.5vw'} >Javascript Fetaures</Text>
        <Text fontSize={'1vw'} >
          Select the features that you want to enable in Javascript SDK
        </Text>
      </Box>
      <VStack spacing={5} maxH={'350px'} bg={"#fff"} p={'3rem'} borderRadius={'8px'} flexWrap={'wrap'} align="start">

        <CheckboxGroup

          onChange={handleCheckboxChange}
        >
          {options.map((option, index) => (
            <Checkbox onChange={handleSubmit} key={index} value={option}>
              {option}
            </Checkbox>
          ))}
        </CheckboxGroup>

        {/* <PrimaryButton  label={'Submit'} onClick={handleSubmit}> */}

        {/* </PrimaryButton> */}
      </VStack>
    </>
  );
};

export default JavascriptFeatures;
