import React, { useEffect, useRef, useState } from 'react'
import { Box, Text, Flex, FormControl, FormLabel, HStack, Icon, Heading, useDisclosure } from '@chakra-ui/react'
import Select from '../../components/themeComponents/react-chakra-select'
import { useQuery } from 'react-query'
import { prepareMultiOrgTemplates, prepareTemplateList } from '../../_helpers/data_helper'
import { BsBox2Fill } from 'react-icons/bs'
import * as htmltoImage from "html-to-image"
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { VscPreview } from "react-icons/vsc";
import { FiEdit } from 'react-icons/fi'
import TemplatePreviewModal from '../Campaigns/components/TemplatePreviewModal'
import MobilePreview from '../Campaigns/components/MobilePreview'
import TemplateCreateOrEditModal from './TemplateCreateOrEditModal'
import store from '../../store/store'
import * as _ from "lodash"
import { CiEdit } from 'react-icons/ci'

function TemplateSelection({ templateQuery, selectedChannel, orgToken, formik, setTemplatesData, templatesData ,templateQueryRefetch}) {
    
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [isPreviewGenerating, setIsPreviewGenerating] = useState(false)
    const [selectedTemplates, setSelectedTemplates] = useState({})
    const [template, setTemplate] = useState({})
    const [createModal, setCreateModal] = useState(false)
    const [selectedTempBody, setSelectedTempBody] = useState("")
    const tempBodyRef = useRef(null)
    const orgList = store.getState().org.orgList
    
    async function generateEmailPreview() {
        setIsPreviewGenerating(true);
        try {
            const tempBody = tempBodyRef.current;
            const canvas = await htmltoImage.toPng(tempBody);
            formik.setFieldValue('tempImg', canvas)
            setIsPreviewGenerating(false);
        } catch (error) {
            console.log('Error in generating preview', error)
        }
    }
    const [selectedToken, setSelectedToken] = useState("")
    const getOrgName = (token) => {
        let orgName = orgList.filter((e) => e.token == token)
        return orgName[0]?.name || ''
    }

    const selectTempData = (e, type) => {
        setSelectedTemplates((prevSelectedTemplates) => {
            const updatedTemplates = {
                ...prevSelectedTemplates,
                [orgToken]: {
                    ...(prevSelectedTemplates[orgToken] || {}),
                    [type]: e,
                },
            };
    
            // Update the templatesData with the newly selected templates
            setTemplatesData((prevTemplatesData) => ({
                ...prevTemplatesData,
                [orgToken]: {
                    ...prevTemplatesData[orgToken],
                    [type]: e,
                },
            }));
    
            return updatedTemplates;
        });
    
        setSelectedTempBody(e.body);
    };
    

    let setCombinedPreview = () => {
        let combinedMarkup = selectedTemplates[orgToken]["header"]['body'] +
            selectedTemplates[orgToken]["body"]['body'] + 
            selectedTemplates[orgToken]["footer"]['body'];
        setSelectedTempBody(combinedMarkup);
        onOpen();
    }

    let openPreview = (body) => {
        setSelectedTempBody(body);
        onOpen();
    }

    const selectTemplate = (e) => {
        const selectedTemplate = templateQuery.data.find(template => template.uuid === e.uuid);
        if (selectedTemplate) {
            setTemplate(selectedTemplate); 
        } else {
            setTemplate({});
        }
    }

    const selectedTempsObj = {}
    useEffect(() => {
        formik.values.orgList.map((e) => {
            selectedTempsObj[e] = {
                header: {},
                body: {},
                footer: {},
            }
        })
    }, [])
useEffect(()=>{
    console.log(templatesData)
},[templatesData])
    return (
        <>
            <Box w={'100%'} m={'0 auto'} mt={5} p={3} id='screenshot' bg={'white'}>
                <Heading fontSize={'1vw'}>{!!templateQuery && getOrgName(templateQuery.org_token)}</Heading>
                <Text color={'heading'} as={'b'}>Template Information</Text>

                <Flex gap={12} mt={4}>
                    {
                        !!prepareMultiOrgTemplates(templateQuery?.otherTemplates,formik.values.selectedChannel) && !!Object.keys(prepareMultiOrgTemplates(templateQuery?.otherTemplates,formik.values.selectedChannel))?.length > 0 && 
                        Object.keys(prepareMultiOrgTemplates(templateQuery.otherTemplates,formik.values.selectedChannel)).map((tempType) => (
                            <Box p={3} bg={'white'} w={'60%'} alignItems={'end'} gap={'15px'} display={'flex'}>
                                <FormControl>
                                    <FormLabel color={'inputLabel'}>Template {tempType} </FormLabel>
                                    <Select
                                    // value={!_.isEmpty(selectedTemplates[orgToken]) &&  _.filter(templateQuery?.otherTemplates,{label: templatesData[orgToken][tempType]?.label})}
                                    value={!_.isEmpty(templatesData[orgToken]) && !_.isEmpty(templatesData[orgToken][tempType]) && templatesData[orgToken][tempType]}
                                    options={ tempType== 'body' ?
                                            prepareTemplateList( formik.values.selectedChannel, prepareMultiOrgTemplates(templateQuery.data,formik.values.selectedChannel)[tempType]) :
                                            prepareTemplateList( formik.values.selectedChannel, prepareMultiOrgTemplates(templateQuery.otherTemplates,formik.values.selectedChannel)[tempType])}
                                        onChange={(e) => {
                                            
                                            selectTempData(e, tempType)}}
                                    />
                                </FormControl>
                                {
                                    !!selectedTempBody &&
                                    <HStack float={'right'} title='Preview' gap={3} cursor={'pointer'}>
                                        <Icon as={!!formik.values.selectedTemp ? FaEye : FaEyeSlash} onClick={() => openPreview(selectedTemplates[orgToken][tempType]['body'])} cursor={'pointer'} mr={1}></Icon>
                                        
                                    </HStack>
                                }
                                {
                                    !!selectedTempBody &&
                                    <HStack float={'right'} title='Preview' gap={3} cursor={'pointer'}>
                                        <Icon as={VscPreview} onClick={() => setCombinedPreview()} cursor={'pointer'}></Icon>
                                    </HStack>
                                }
                                {
                                    tempType=='body' &&
                                     <Icon as={FiEdit} onClick={() =>{
                                        
                                         setSelectedToken(templateQuery.org_token);
                                         setCreateModal(true)
                                        
                                     }} cursor={'pointer'}></Icon> 
                                        }
                            </Box>
                        ))
                    }
                    <Box>
                        {
                           
                                <TemplatePreviewModal isOpen={isOpen} onClose={onClose} tempBody={selectedTempBody}></TemplatePreviewModal>
                        }
                    </Box>
                    <Box>
                        <TemplateCreateOrEditModal token={selectedToken} isOpen={createModal} onClose={() => {
                            templateQueryRefetch()
                            setCreateModal(false)
                        }
                             }/>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}

export default TemplateSelection
