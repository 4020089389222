import React from 'react';
import { useFormik, FieldArray } from 'formik';
import {
  Box,
  Button,
  Input,
  Stack,
  FormLabel,
  FormControl,
  Textarea,
  useToast,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';

const InAppTemplateForm = () => {
  const toast = useToast();
    
  const formik = useFormik({
    initialValues: {
      templateName: '',
      layoutId: '008',
      templateType: { type: 'image&text', pushType: 'inApp' },
      templateTitle: '',
      templateImageUrl: [''],
      templateDescription: '',
      notificationDetails: {
        buttonOrientation: 'horizontal',
        templateOrientation: 'top',
        templateBackground: '#ffffff',
        titleColor: '#000000',
        titleFontSize: '14',
        descriptionFontSize: '12',
        descriptionFontColor: '#060101',
        isClose: true,
        imageOrientation: 'fullLayout',
        textAllignment: 'center',
        media: { isCenter: true },
      },
      buttons: [
        {
          buttonName: '',
          buttonUrl: '',
          buttonColor: '#000000',
          buttonFontColor: '#FFFFFF',
          buttonFontSize: '14',
        },
      ],
      targetPlatform: ['android'],
      templateMessage: '',
    },
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      toast({
        title: 'Form submitted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const setFieldValue = (e) => {
    
    formik.setFieldValue(e.target.name,e.target.value)
}
  return (
    <Box p={8}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={4}>
          {/* Template Name */}
          <FormControl>
            <FormLabel>Template Name</FormLabel>
            <Input
              name="templateName"
              value={formik.values.templateName}
              onChange={setFieldValue}
              onBlur={formik.handleBlur}
            />
          </FormControl>

          {/* Template Title */}
          <FormControl>
            <FormLabel>Template Title</FormLabel>
            <Input
              name="templateTitle"
              value={formik.values.templateTitle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>

          {/* Template Image URL */}
          <FormControl>
            <FormLabel>Template Image URL</FormLabel>
            <FieldArray
              name="templateImageUrl"
              render={(arrayHelpers) => (
                <Stack spacing={2}>
                  {formik.values.templateImageUrl.map((url, index) => (
                    <HStack key={index} spacing={4}>
                      <Input
                        name={`templateImageUrl[${index}]`}
                        value={url}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        colorScheme="red"
                        onClick={() => arrayHelpers.remove(index)}
                        isDisabled={formik.values.templateImageUrl.length === 1}
                      />
                    </HStack>
                  ))}
                  <Button
                    leftIcon={<AddIcon />}
                    onClick={() => arrayHelpers.push('')}
                  >
                    Add Image URL
                  </Button>
                </Stack>
              )}
            />
          </FormControl>

          {/* Template Description */}
          <FormControl>
            <FormLabel>Template Description</FormLabel>
            <Textarea
              name="templateDescription"
              value={formik.values.templateDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>

          {/* Buttons */}
          <FieldArray
            name="buttons"
            render={(arrayHelpers) => (
              <Stack spacing={4}>
                {formik.values.buttons.map((button, index) => (
                  <Box key={index} borderWidth="1px" p={4} rounded="md">
                    <HStack spacing={4}>
                      <FormControl>
                        <FormLabel>Button Name</FormLabel>
                        <Input
                          name={`buttons[${index}].buttonName`}
                          value={formik.values.buttons[index].buttonName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel>Button URL</FormLabel>
                        <Input
                          name={`buttons[${index}].buttonUrl`}
                          value={formik.values.buttons[index].buttonUrl}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </FormControl>

                      {formik.values.buttons.length > 1 && (
                        <IconButton
                          icon={<DeleteIcon />}
                          colorScheme="red"
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      )}
                    </HStack>
                  </Box>
                ))}
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="blue"
                  onClick={() =>
                    arrayHelpers.push({
                      buttonName: '',
                      buttonUrl: '',
                      buttonColor: '#000000',
                      buttonFontColor: '#FFFFFF',
                      buttonFontSize: '14',
                    })
                  }
                >
                  Add Button
                </Button>
              </Stack>
            )}
          />

          {/* Template Message */}
          <FormControl>
            <FormLabel>Template Message</FormLabel>
            <Textarea
              name="templateMessage"
              value={formik.values.templateMessage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>

          {/* Submit Button */}
          <Button type="submit" colorScheme="teal">
            Submit
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default InAppTemplateForm;
