
import React, { Fragment } from 'react'
import CreateSegment from "../Audience/CreateSegment"
import { Modal, ModalOverlay, ModalContent, Text,ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, useToast } from "@chakra-ui/react"
export default function CreateSegmentModal({isOpen,onClose,orgToken,setSegmentId}) {
  return (
    <Fragment>
            <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
                <ModalOverlay></ModalOverlay>
                <ModalContent>
                    {/* <ModalHeader>Save Segment</ModalHeader> */}
                    <ModalCloseButton></ModalCloseButton>
                    <ModalBody>
                        {/* <Text>{JSON.stringify(orgToken.org_token)}</Text> */}
                        <CreateSegment setSegmentId={setSegmentId} closeSegmentModal={onClose} orgId={orgToken} orgToken= {orgToken}  />
                    </ModalBody>
                    <ModalFooter>
                        
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Fragment>

  )
}
