import React from 'react'
import { Thead, Th, Tr } from '@chakra-ui/react'
function TableHead({ headersProps, ...rest }) {

    return (
        <Thead  position={'sticky'} top={"-20px"}>
            <Tr bg={'#f3f3f3'} {...rest}>
                {
                    headersProps.map(head => {
                        return <Th key={'head'} textTransform={'unset'}  fontSize={'0.9vw'}>{head}</Th>
                    })
                }
            </Tr>
        </Thead>
    )
}

export default TableHead
