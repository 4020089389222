import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Button, Input, InputGroup, Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// function DateInput() {
//     return (

//     )
// }
function DateRangeSelection({dateRange, onDateChange, isLoading}) {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(new Date(dateRange.startDate));
    const [endDate, setEndDate] = useState(new Date(dateRange.endDate));
    const [isEdited, setIsEdited] = useState(false);
    const DateInput = forwardRef(({ value, onClick, placholderPrefix }, ref) => (
        <Box onClick={onClick} ref={ref} ml={3} border={'1px solid'} borderColor={'gray.200'} borderRadius={5} p={2} fontSize={'sm'} cursor={'pointer'}>{placholderPrefix + value}</Box>
        //<InputGroup w={'auto'}><Input onClick={onClick} ref={ref} ml={3} value={placholderPrefix + value} w={'auto'}></Input></InputGroup>
    ))
    const applyDateRange = () => {
        onDateChange({startDate, endDate})
        //setIsEdited(!isLoading)
    }
    useEffect(() => {
       
        setIsEdited(!!isLoading)
    }, [isLoading])
    return (
        <Box bg={'white'} mt={-4} p={4} pl={'34px'} display={'flex'} alignItems={'center'} ml={-4} mr={-4} pos={'sticky'} top={20} zIndex={99}>
            <Text>{t('selectperiod')}:</Text>
            <ReactDatePicker
                selected={startDate}
                onChange={(date) => {setStartDate(date); setIsEdited(true) }}
                dateFormat='MMMM d, yyyy'
                selectsStart
                startDate={startDate}
                endDate={endDate}
                customInput={<DateInput placholderPrefix={'from '} />}>

            </ReactDatePicker>
            <ReactDatePicker
                selected={endDate}
                onChange={(date) => {setEndDate(date); setIsEdited(true)}}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat='MMMM d, yyyy'
                maxDate={new Date()}
                customInput={<DateInput placholderPrefix={'to '} />}
            />
            {!!isEdited && <Button onClick={() => applyDateRange()} ml={2} isLoading={isLoading}>Apply</Button>}
        </Box>
    )
}

export default DateRangeSelection
