import React, { Fragment, useState } from 'react';
import { HStack, Box, Image, Text, Container, Icon, Input } from "@chakra-ui/react";
import OutlinedButton from './themeComponents/OutlinedButton';
import { FaCheck, FaArrowRight } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { getSelectedOrgFromLocalSorage, setSelectedOrganization } from '../Services/AuthService';
import SuccessButton from './themeComponents/SuccessButton';
import { IoCheckmarkCircleSharp } from "react-icons/io5";

function OrganizationList({ orgList, selectedOrg, onOrgSelect }) {
    const [searchText, setSearchText] = useState("");
    

    const filteredOrgList = orgList.filter(org => {
        return org.name.toLowerCase().includes(searchText.toLowerCase()) || org.domain.toLowerCase().includes(searchText.toLowerCase()) ;
    });

    return (
        <Fragment>
            <Input
                placeholder="Search organizations..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                mb={4}
            />
            {
                !!filteredOrgList.length && filteredOrgList.map((org, i) => {
                    
                    return (
                        <HStack borderBottom={'1px'} borderColor={'gray.200'} py={2} justifyContent={'space-between'} key={i}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Image src={ org?.orgIcon || '/images/defaultorgicon.png'} height={'50px'} borderRadius={'200px'} width={50} mr={2} />
                                <Box>
                                    {/* edit elipses */}
                                    <Text fontSize={'md'} color={'light'} w={32} textOverflow="ellipsis" overflow={'hidden'}  whiteSpace={'nowrap'} textTransform={'capitalize'}>{org.name}</Text>
                                    <Text fontSize={'sm'} color={'light'} w={32} textOverflow="ellipsis" overflow={'hidden'}  whiteSpace={'nowrap'}>{org.domain}</Text>
                                </Box>
                            </Box>
                            {
                                !(selectedOrg?.token === org.token) ?
                                    <OutlinedButton
                                        label={'Select'}
                                        colorScheme={'primary.main'}
                                        size="sm"
                                        rightIcon={<FaArrowRight />}
                                        onClick={() => onOrgSelect(org)}
                                    ></OutlinedButton>
                                    : <Image as={IoCheckmarkCircleSharp}  color={'success'} boxSize={6} />
                            }
                        </HStack>
                    )
                })
            }
        </Fragment>
    )
}

export default OrganizationList;
