import React from "react";
import { Td, Tr, Box, Text, Flex, VStack, List, ListItem, Badge } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedOrganization } from "../../Services/AuthService";

function AdminTable({ orderData }) {
  const badgeColor = {
    ERROR: "red",
    COMPLETED: "green",
    DRAFT: "yellow",
  };
  
  const orgToken = useSelector((state) => state.auth.selectedOrg.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const selectedOrg = localStorage.getItem("selectedOrg");
  
  const onOrgSelect = (org) => {
    if (org.token !== selectedOrg?.token) {
      setSelectedOrganization(dispatch, org);
      navigate("/");
    }
  };

  const handleNavigation = (link) => {
    navigate(link);
  };

  return (
    <>
      {orderData?.length ? (
        orderData.map((dt, i) => {
          const token = dt.token; 
          const linkItems = [
            // { label: 'Show Dashboard', value: 'Show Dashboard', link: '/' },
            { label: 'Select Organization', value: 'Select Organization', org: dt },
            { label: 'Features', value: 'Features', link: `/su/orgfeatures/${token}` },
          ];

          return (
            <Tr key={i}>
              <Td w={"30%"} fontSize={"sm"} verticalAlign={"middle"}>
                <Flex direction="column" gap={2}>
                  <Text fontWeight="bold" fontSize={"md"}>{dt.name}</Text>
                  <VStack align="start" spacing={1} fontSize={"sm"}>
                    <List spacing={1}>
                      {linkItems.map((item, index) => (
                        <ListItem
                          key={index}
                          padding={1}
                          borderRadius="md"
                          _hover={{ bg: "gray.100", cursor: "pointer" }}
                          onClick={() => {
                            if (item.org) {
                              onOrgSelect(item.org); 
                            } else {
                              handleNavigation(item.link);
                            }
                          }}
                        >
                          <Text>{item.label}</Text>
                        </ListItem>
                      ))}
                    </List>
                  </VStack>
                </Flex>
              </Td>
              <Td w={"20%"} fontSize={"sm"}>
                <Box>
                  <Badge colorScheme={dt.live ? "green" : "red"}>
                    {dt.live ? "Active" : "Not Active"}
                  </Badge>
                </Box>
              </Td>
            </Tr>
          );
        })
      ) : (
        <Tr>
          <Td colSpan="3" textAlign="center" fontStyle="italic">
            No data available
          </Td>
        </Tr>
      )}
    </>
  );
}

export default AdminTable;
