import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { FormControl, FormLabel, Input, VStack, useRadioGroup, Box, Icon, Text, RadioGroup, Stack, Radio } from '@chakra-ui/react';
import { MdOutlineEmail, MdOutlineSms } from "react-icons/md";
import { LuBellDot } from "react-icons/lu";
import RadioCards from './RadioCards';
import { FaWhatsapp } from "react-icons/fa";
import { CHANNELS } from '../../../_helpers/helpers';
import moment from 'moment';
import Select from '../../../components/themeComponents/react-chakra-select';
import { Formik } from 'formik';
import { campaignTypes } from '../../../_helpers/data_helper';

function DraftCampaignForm({ formik }) {

    const optionsForCampaigns = [{ value: 'email', label: 'Email', description: 'Send a targeted message to your contacts through email.', icon: MdOutlineEmail },
    { value: 'sms', label: 'SMS', description: 'Send a targeted message to your contacts through sms', icon: MdOutlineSms }, {
        value: 'push', label: 'Push', description: 'Send a targeted notification to your contacts through push notification', icon: LuBellDot
    },
    {
        value: 'whatsapp', label: 'WhatsApp', description: 'Send a targeted notification to your contacts through whatsapp message', icon: FaWhatsapp
    }];
    const optionsForABTest = [{ value: 'email', label: 'Email', description: 'Send a targeted message to your contacts through email.', icon: MdOutlineEmail },
    {
        value: 'whatsapp', label: 'WhatsApp', description: 'Send a targeted notification to your contacts through whatsapp message', icon: FaWhatsapp
    },
    { value: 'sms', label: 'SMS', description: 'Send a targeted message to your contacts through sms', icon: MdOutlineSms }
    ];

    const optionsForControlGroup = [{ value: 'email', label: 'Email', description: 'Send a targeted message to your contacts through email.', icon: MdOutlineEmail },
        {
            value: 'whatsapp', label: 'WhatsApp', description: 'Send a targeted notification to your contacts through whatsapp message', icon: FaWhatsapp
        },
        { value: 'sms', label: 'SMS', description: 'Send a targeted message to your contacts through sms', icon: MdOutlineSms }
    ];
    const optionsMultiplechedule = [{ value: 'email', label: 'Email', description: 'Send a targeted message to your contacts through email.', icon: MdOutlineEmail },
        {
            value: 'whatsapp', label: 'WhatsApp', description: 'Send a targeted notification to your contacts through whatsapp message', icon: FaWhatsapp
        },
        { value: 'sms', label: 'SMS', description: 'Send a targeted message to your contacts through sms', icon: MdOutlineSms }
    ];

    const availableChannels = ["whatsapp", "email", "sms"]
    const [campaignType, setCampaignType] = useState("Single Send")
    const setBroadcastType = (e) => {

        if (e == "a_b_test") {
            formik.setFieldValue("selectedChannel", "email")
        }
        if (e == "control_group") {
            formik.setFieldValue("campaignType",e.key)
            formik.setFieldValue("broadcast",e.key)
            formik.setFieldValue("broadcastType",e.key)
            formik.setFieldValue("selectedChannel", "email")
        }
        setCampaignType(e.label)
        formik.setFieldValue("campaignType", e.key + "_v2")
        formik.setFieldValue("broadcast", e.key + "_v2")
        formik.setFieldValue("broadcastType", e.key + "_v2")
        formik.values['broadcastType'] = e.key + "_v2"
        formik.values['broadcast'] = e.key + "_v2"
        console.log(formik.values)
    }
    const broadcastTypes = {
        "AB TESTING": (e) => {

            setCampaignType("AB TESTING")
            //   onOpen()
        },
        "SINGLE SEND": (e) => {
            setCampaignType("SINGLE SEND")
            //   onOpen()
        }
    }
    const onChannelChange = (channel) => {
        formik.setFieldValue("selectedChannel", channel);
        formik.setFieldValue("campaignName", CHANNELS[channel] + ' - ' + moment(new Date()).format("lll"))
    }
    const { getRadioProps, getRootProps } = useRadioGroup({
        name: 'channel', defaultValue: 'email', onChange: (value) => onChannelChange(value)
    })
    const abTestChannels = ["email"]
    const group = getRootProps();
    function handleSubmit(values, action) {
        console.log("submit", values)
        //onFormSubmit()
    }
    useEffect(() => {
        formik.setFieldValue("campaignType", "Single Send");
    }, [])
    return (
        <Fragment>
            <FormControl id='campaignName'>
                <FormLabel color={'inputLabel'}>Name</FormLabel>
                <Input type='text' {...formik.getFieldProps("campaignName")} ></Input>
            </FormControl>
            <FormControl id='campaignType' >
                <FormLabel color={'inputLabel'}>Campaign Type</FormLabel>
                <Select defaultValue={[campaignTypes[0]]} options={campaignTypes} name="campaignType" onChange={(e) => { setBroadcastType(e) }} >

                </Select>
            </FormControl>

            <FormControl mt={5}>
                <input type='hidden' {...formik.getFieldProps("selectedChannel")}></input>
                <FormLabel color={'inputLabel'}>Channel {JSON.stringify(formik.va)}</FormLabel>

                <VStack {...group}>
    {
        campaignType === 'Single Send' && optionsForCampaigns.map((value) => {
            const radio = getRadioProps({ value: value.value });
            return (
                <RadioCards 
                    formik={formik} 
                    {...radio} 
                    key={value.value} 
                    isDisabled={formik.values.campaignType === 'a_b_test_v2' && !availableChannels.includes(value.value)}
                >
                    <Box 
                        display={'flex'} 
                        p={3} 
                        alignItems={'center'} 
                        background={formik.values.campaignType === 'a_b_test_v2' ? "#dedede" : undefined} 
                        filter={formik.values.campaignType === 'a_b_test_v2' && !availableChannels.includes(value.value) ? 'grayscale(1)' : undefined} 
                        cursor={formik.values.campaignType === 'a_b_test_v2' && !availableChannels.includes(value.value) ? 'not-allowed' : undefined}
                    >
                        <Icon as={value.icon} mr={4} color={'rgb(0, 25, 90)'} boxSize={6} />
                        <Box>
                            <Text as="b">{value.label}</Text>
                            <Text>{value.description}</Text>
                        </Box>
                    </Box>
                </RadioCards>
            );
        })
    }
    {
        campaignType === 'A/B Testing' && optionsForABTest.map((value) => {
            const radio = getRadioProps({ value: value.value });
            return (
                <RadioCards formik={formik} {...radio} key={value.value}>
                    <Box display={'flex'} p={3} alignItems={'center'}>
                        <Icon as={value.icon} mr={4} color={'rgb(0, 25, 90)'} boxSize={6} />
                        <Box>
                            <Text as="b">{value.label}</Text>
                            <Text>{value.description}</Text>
                        </Box>
                    </Box>
                </RadioCards>
            );
        })
    }
    {
        campaignType === 'Control Group' && optionsForControlGroup.map((value) => {
            const radio = getRadioProps({ value: value.value });
            return (
                <RadioCards formik={formik} {...radio} key={value.value}>
                    <Box display={'flex'} p={3} alignItems={'center'}>
                        <Icon as={value.icon} mr={4} color={'rgb(0, 25, 90)'} boxSize={6} />
                        <Box>
                            <Text as="b">{value.label}</Text>
                            <Text>{value.description}</Text>
                        </Box>
                    </Box>
                </RadioCards>
            );
        })
    }
    {
        campaignType === 'Multiple Schedule' && optionsMultiplechedule.map((value) => {
            const radio = getRadioProps({ value: value.value });
            return (
                <RadioCards formik={formik} {...radio} key={value.value}>
                    <Box display={'flex'} p={3} alignItems={'center'}>
                        <Icon as={value.icon} mr={4} color={'rgb(0, 25, 90)'} boxSize={6} />
                        <Box>
                            <Text as="b">{value.label}</Text>
                            <Text>{value.description}</Text>
                        </Box>
                    </Box>
                </RadioCards>
            );
        })
    }
</VStack>

            </FormControl>
            {/* <FormControl mt={5}>
                <FormLabel color={'inputLabel'}>Type</FormLabel>
                <RadioGroup value={formik.values.campaignType} name="campaignType" onChange={(event) => formik.setFieldValue('campaignType', event)}>
                    <Stack direction={'row'}>
                        <Radio value='SINGLE_SEND'>
                            Single Send
                        </Radio>
                        <Radio value='RECURRING'>
                            Recurring
                        </Radio>
                    </Stack>
                </RadioGroup>
            </FormControl> */}
        </Fragment>


    )
}

export default DraftCampaignForm
