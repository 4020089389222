import React, { useState } from 'react';
import { Box, Text, Input, Icon } from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';

const CsvDropzone = ({ handleCsvChange }) => {
  
  const [isDragging, setIsDragging] = useState(false);

  // Handle drag and drop events
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    
    e.preventDefault();
    setIsDragging(false);
    const file = e?.dataTransfer?.files[0] || e?.target?.files[0] ;
    handleCsvChange({ target: { files: [file] } });
  };

  return (
    <Box
      w="100%"
      h="200px"
      padding={'2rem'}
      borderWidth="2px"
      borderRadius="lg"
      borderColor={isDragging ? 'blue.500' : 'gray.300'}
      borderStyle={isDragging ? 'solid' : 'dashed'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg={isDragging ? 'blue.50' : 'gray.100'}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      cursor="pointer"
      position="relative"
    >
      <Icon as={FiUpload} boxSize={10} color="gray.500" />
      <Text ml={3} color="gray.500">
        {isDragging ? 'Drop your CSV file here' : 'Drag and drop your CSV file here or click to upload'}
      </Text>
      <Input
        type="file"
        accept=".csv"
        onChange={handleDrop}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        opacity="0"
        cursor="pointer"
      />
    </Box>
  );
};

export default CsvDropzone;
