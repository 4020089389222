import React, { Fragment } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, useToast } from "@chakra-ui/react"
import SecondaryButton from '../../components/themeComponents/SecondaryButton'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import { useFormik } from 'formik'
import { getListingType, getType } from './SegmentDataHelper'
import { saveSegment, toggleCustomSegmentStatus } from '../../Services/api'
import { showSwal } from '../../_helpers/helpers'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { saveSegmentModalSchema } from '../../_helpers/validationSchema'
import { FormErrorMessage } from '@chakra-ui/react'
export default function SaveSegmentModal({ isOpen, onClose, onOpen, segmentData, orgId, orgToken,selectedTarget,optForQuery, raw_query ,isCustomOrm,percentage,closeSegmentModal,setSegmentId }) {
    
    const formik = useFormik({
        initialValues: {
            name: '',
            description: ''
        },
        validationSchema: saveSegmentModalSchema,
        onSubmit: (values) => {
            // Initialize the order JSON structure
            let orderJson = {
                "order_criteria": {
                    "criteria_type": "intermediate",
                    "criteria_operation": "and",
                    "criteria": segmentData.criteria
                }
            };
        
            console.log("query", optForQuery);
        
            // Prepare the request data object
            let requestData = {
                criteria: segmentData,
                name: values.name,
                description: values.description,
                org_id: orgToken || orgId,
                target: selectedTarget,
                type: getType(selectedTarget),
                listing_type: getListingType(selectedTarget)
            };
        
            // Initialize an array to hold the targets
            let targets = [];
            let data = segmentData.criteria;
        
            if (!optForQuery && !isCustomOrm ) {
                // Convert datetime values to ISO string format
                data.forEach(e => {
                    if (e.data_type === 'datetime') {
                        e?.values?.forEach((v) => {
                            v.raw = new Date(v.raw).toISOString();
                        });
                    }
                });
        
                // Gather unique targets
                data.forEach(e => {
                    if (!targets.includes(e.target)) {
                        targets.push(e.target);
                    }
                });
        
                // Filter criteria based on target type
                targets.forEach(t => {
                    if (t !== 'customers') {
                        requestData['criteria'][`${t}_criteria`] = segmentData.criteria.filter(c => c.target === t);
                    }
                });
        
                // Filter out criteria not related to 'customers'
                requestData['criteria']['criteria'] = requestData['criteria']['criteria'].filter(c => c.target === 'customers');
        
                requestData['target'] = targets;
        
                // Remove 'criteria' key if 'customers' is not included in targets
                if (!targets.includes('customers')) {
                    delete requestData['criteria']['criteria'];
                }
            }
        
            if (optForQuery || isCustomOrm) {
                // Handle raw query scenario
                requestData['target'] = 'raw_query';
                requestData['criteria']['raw_query'] = btoa(raw_query);
                requestData['criteria']['percentage'] = parseInt(percentage) 
                delete requestData['criteria']['criteria'];
            }
        
            console.log(requestData);
        
            // Show confirmation dialog
            showSwal(Swal, 'Are you sure?', 'Do you want to create the segment?', true, true, 'Yes', 'No', async () => {
                try {
                    let resp = await saveSegment(requestData);
                    if(window.location.href.includes("all-banks")){
                    toggleCustomSegmentStatus(orgId,resp.data.data.segment_id,"DISABLED")
                    setSegmentId(resp.data.data.segment_id)
                    }
                    console.log("Segment saved successfully", resp.data);
                } catch (error) {
                    // Handle error
                    Swal.close();
                    toaster({ title: 'Oops', description: 'Something went wrong while creating the segment. Please try again.', status: 'error' });
                }
            }, (res) => {
                if (res?.isConfirmed) {
                    
                    

                        if(window.location.href.includes("all-banks")){
                            closeSegmentModal()
                            return 
                        }else{
                            onClose();
                            navigate('/audience/segment/list')
                        }
                    
        

                    
                }
                Swal.close();
            });
        }
        
    })
    const toaster = useToast();
    const navigate = useNavigate()
    const SaveSegment = () => {
        let orderJson = {
            "order_criteria": {
                "criteria_type": "intermediate",
                "criteria_operation": "and",
                "criteria": segmentData.criteria
            }
        }
        let requestData = {
            criteria: segmentData,
            name: formik.values.name,
            description: formik.values.description,
            org_id: orgToken || orgId,
            target: selectedTarget,
            //type: data.type,
            //type: $scope.target === AnalyticService.targets.PRODUCT_BUYERS ? 'function': 'model',
            type: getType(selectedTarget),
            //listing_type: ($scope.target === AnalyticService.targets.PRODUCT_BUYERS || $scope.target === AnalyticService.targets.CUSTOMERS ) ? 'customers': $scope.target
            listing_type: getListingType(selectedTarget)
        };
        showSwal(Swal, 'Are you sure?', 'Do you want to create the segment?', true, true, 'Yes', 'No', async () => {
            let resp = await saveSegment(requestData).catch(errro => {
                Swal.close()
                toaster({ title: 'Oops', description: 'Something went wrong while creating the segment. Please try again.', status: 'error' });

            });
            if(resp){

                if(window.location.href.includes("all-banks")){
                    return 
                }else{
                    navigate('/audience/segment/list')
                }
            }

            // console.log("response after saving segment", resp.data)
        }, (res) => {
            
            !!res.isConfirm && (() => {
                onClose();
            
                if(window.location.href.includes("all-banks")){
                    return 
                }else{
                    navigate('/audience/segment/list')
                }
            })()
            Swal.close()
        })

    }
    return (
        <Fragment>
            <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
                <ModalOverlay></ModalOverlay>
                <ModalContent>
                    <ModalHeader>Save Segment</ModalHeader>
                    <ModalCloseButton></ModalCloseButton>
                    <ModalBody>
                        <FormControl isInvalid={formik.touched.name && formik.errors.name}>
                            <FormLabel>Name</FormLabel>
                            <Input name='name' value={formik.values.name} onChange={formik.handleChange}></Input>
                            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={formik.touched.description && formik.errors.description}>
                            <FormLabel>Description</FormLabel>
                            <Input name='description' value={formik.values.description} onChange={formik.handleChange}></Input>
                            <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <SecondaryButton label={'Cancel'} mr={3} onClick={() => onClose()}></SecondaryButton>
                        <PrimaryButton label={'Save'} onClick={formik.handleSubmit} type={'submit'}></PrimaryButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Fragment>

    )
}
