import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Heading,
  VStack,
  Select,
  Input,
  CheckboxGroup,
  Checkbox,
  Stack,
  HStack,
  FormLabel,
  Divider,
  Text,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  Slider,
  SliderTrack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react';
import { QueryBuilder, formatQuery } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.scss';
import { TbTrash } from 'react-icons/tb';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import SecondaryButton from '../../components/themeComponents/SecondaryButton';
import hljs from 'highlight.js/lib/core';
import sql from 'highlight.js/lib/languages/sql'; // Import the SQL language module
import 'highlight.js/styles/github.css'; // Import the desired Highlight.js theme
import { default as MultiSelectDropdown } from "../../components/themeComponents/react-chakra-select"
import { getCountsFromQuery } from '../../Services/api';
// Register the SQL language with Highlight.js


const joinTypes = [
  { value: 'INNER JOIN', label: 'INNER JOIN' },
  { value: 'LEFT JOIN', label: 'LEFT JOIN' },
  { value: 'RIGHT JOIN', label: 'RIGHT JOIN' },
];


const ValueEditorComponent = React.memo(({ value, handleOnChange, inputType }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    
    if (inputRef.current) {
      // console.log('Current input value (useRef):', inputRef.current.value);
    }
    
  }, [value, inputRef?.current?.value]);
  
  return (
    <>
      {
        inputType == 'text' ? <Input
        ref={inputRef}
        value={value || ''}
        onChange={(e) => {
            handleOnChange(inputRef.current.value)
            // console.log('Current input value (onChange):', e.target.value);
            // handleOnChange(e.target.value);
          }}
          placeholder="Enter value"
          type="text"
        /> : <Input

          value={value || ''}
          onChange={(e) => {
            // console.log('Current input value (onChange):', e.target.value);
            handleOnChange(e.target.value);
          }}
          placeholder="Enter value"
          type="datetime-local"
        />
      }

    </>
  );
});

export const CustomOrm = ({ orgId, segmentationData, sendQuery, setPercentage, finalizedQuery, setFinalizedQuery, setUnion, union, i }) => {
  const tables = segmentationData?.props;
  const highlightedRef = useRef(null);
  const [audienceRange, setAudienceRange] = useState(20);
  const [selectedTables, setSelectedTables] = useState([Object.keys(tables)[0]]);
  const [tablesColumns, setTablesColumns] = useState([])
  const [joins, setJoins] = useState([]);
  const [groupBy, setGroupBy] = useState("");
  const [queries, setQueries] = useState({});
  const [selectedAggregates, setSelectedAggregates] = useState({});
  // const [finalizedQuery, setFinalizedQuery] = useState("");
  const [countsFromQuery, setCountsFromQuery] = useState()
  // console.log(countsFromQuery, "countsFromQuery")
  const [tooltipVisibility, setTooltipVisibility] = useState(false)
  const [isLoading, setLoading] = useState(false);

  const operators = [
    { name: '=', value: '=', label: '=' },
    { name: '!=', value: '!=', label: '!=' },
    { name: '<', value: '<', label: '<' },
    { name: '>', value: '>', label: '>' },
    { name: '<=', value: '<=', label: '<=' },
    { name: '>=', value: '>=', label: '>=' },
    { name: 'contains', value: 'contains', label: 'contains' },
    { name: 'beginsWith', value: 'beginsWith', label: 'begins with' },
    { name: 'endsWith', value: 'endsWith', label: 'ends with' },
    { name: 'doesNotContain', value: 'doesNotContain', label: 'does not contain' },
    { name: 'doesNotBeginWith', value: 'doesNotBeginWith', label: 'does not begin with' },
    { name: 'doesNotEndWith', value: 'doesNotEndWith', label: 'does not end with' },
    { name: 'null', value: 'null', label: 'is null' },
    { name: 'notNull', value: 'notNull', label: 'is not null' },
    { name: 'in', value: 'in', label: 'in' },
    { name: 'notIn', value: 'notIn', label: 'not in' },
    { name: 'between', value: 'between', label: 'between' },
    { name: 'notBetween', value: 'notBetween', label: 'not between' },
  ];

  const aggregates = [
    "MAX", "MIN", "AVG", "SUM", "COUNT"
  ]

  const getQueryCounts = async () => {
    setLoading(true);
    let res = await getCountsFromQuery(orgId, finalizedQuery).catch(err => {
      setCountsFromQuery("0")
    })
    if (res?.data) {
      setCountsFromQuery(res?.data?.counts);
    }
    setLoading(false);
  }
  // Get fields for selected tables
  const availableFields = useMemo(() => {
    return selectedTables.reduce((fields, table) => {
      return [
        ...fields,
        ...Object.keys(tables[table]).map((field) => ({
          name: `${table}.${field}`,
          inputType: tables[table][field]["inputType"]
        })),
      ];
    }, []);
  }, [selectedTables, tables]);

  const availableColumns = useMemo(() => {
    return selectedTables.reduce((fields, table) => {

      return [
        ...fields,
        ...Object.keys(tables[table]).map((field) => ({
          label: `${table}.${field}`,
          value: `${table}.${field}`
        })),
      ];
    }, []);

  }, [selectedTables, tables]);

  const joinColumns = useMemo(() => {

    return selectedTables.reduce((fields, table) => {
      return [
        ...fields,
        ...Object.keys(tables[table]).map((field) => ({
          label: `${table}.${field}`,
          value: `${table}.${field}`
        })),
      ];
    }, []);
  }, [selectedTables, tables]);



  const handleTableChange = (selectedValues) => {
    setSelectedTables(selectedValues);
  };

  const handleJoinChange = (index, joinType) => {
    const newJoins = [...joins];
    newJoins[index] = { ...newJoins[index], joinType };
    setJoins(newJoins);
  };
  let handleAudienceRange = (v) => {
    setAudienceRange(v)
  }
  const handleTableJoinChange = (index, table) => {
    const newJoins = [...joins];
    newJoins[index] = { ...newJoins[index], table, columns: [] };
    setJoins(newJoins);
  };

  const handleColumnChange = (index, column) => {
    const newJoins = [...joins];
    newJoins[index] = { ...newJoins[index], columns: column };
    setJoins(newJoins);
  };

  const handleConditionChange = (index, condition) => {
    const newJoins = [...joins];
    newJoins[index] = { ...newJoins[index], condition };
    setJoins(newJoins);
  };

  const handleAddJoin = () => {
    setJoins([
      ...joins,
      { table: '', joinType: joinTypes[0].value, condition: '', columns: [] },
    ]);
  };

  const handleRemoveJoin = (index) => {
    setJoins(joins.filter((_, i) => i !== index));
  };

  const handleQueryChange = (tableName, newQuery) => {
    setQueries((prevQueries) => ({
      ...prevQueries,
      [tableName]: newQuery,
    }));
  };

  const convertDateTimeToEpoch = (query) => {

    const dateTimePattern = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/g;
    const convertedQuery = query.replace(dateTimePattern, (dateTimeString) => {
      const dateTime = new Date(dateTimeString);
      return Math.floor(dateTime.getTime() / 1000);

    });

    return convertedQuery;
  };

  const generateQuery = () => {
    
    let tableColumns = '';

    if (joins.length && tablesColumns.length) {
      tableColumns = tablesColumns
        .map((col) =>
          selectedAggregates[col.value]
            ? `${selectedAggregates[col.value]}(${col.value})`
            : col.value
        )
        .join(', ');
    }

    const selectColumns = tableColumns.length ? tableColumns : '*';

    let finalQuery = `SELECT ${selectColumns} FROM ${selectedTables[0]}`;

    joins.forEach((join) => {
      if (join.table && join.condition) {
        finalQuery += ` ${join.joinType} ${join.table} ON ${join.condition} = ${join.table}.${join.columns}`;
      }
    });

    const whereClauses = Object.keys(queries)
      .map((tableName) => {
        const query = queries[tableName];
        if (query) {
          return formatQuery(query, { format: 'sql' }).replace(/^WHERE\s/, '');
        }
        return null;
      })
      .filter(Boolean)
      .join(' AND ');

    if (whereClauses) {
      finalQuery += ` WHERE ${whereClauses}`;
    }
    if (groupBy) {
      finalQuery += ` GROUP BY ${groupBy}`;
    }
    finalQuery = convertDateTimeToEpoch(finalQuery);

    // console.log(finalQuery);

    setFinalizedQuery({
      ...finalizedQuery,
      [i]: finalQuery
    });
    sendQuery(finalizedQuery);
    // console.log(finalizedQuery)
    setPercentage(audienceRange);

  };



  const handleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    
    if (highlightedRef.current) {
      const highlighted = hljs.highlight(finalizedQuery, { language: 'sql' }).value;
      highlightedRef.current.innerHTML = highlighted;
    }
    generateQuery();
    
  }, [queries, joins, groupBy]);
  
  useEffect(() => {
    selectedTables.reduce((fields, table) => {
      return [
        ...fields,
        ...Object.keys(tables[table]).map((field) => ({
          label: `${field}`, // Prefix field name with table name
          value: `${table}.${field}`
        })),
      ];
    }, []);
    // console.log(tablesColumns)

  }, [])

  return (
    <Box
      p="6"
      w={'100%'}
    >
      <form onSubmit={handleSubmit}>
        <VStack w={'100%'} alignItems={'start'} spacing="4">
          <HStack w={'100%'} justifyContent={'space-between'}>
            <VStack>
              <Text alignSelf={'start'} fontSize={'1vw'}>
                Tables
              </Text>
              <CheckboxGroup
                value={selectedTables}
                onChange={handleTableChange}
                mb="4"
              >
                <Stack spacing="2" align="start">
                  {Object.keys(segmentationData?.props).map((table) => (
                    <Checkbox key={table} value={table}>
                      {table.charAt(0).toUpperCase() + table.slice(1)}
                    </Checkbox>
                  ))}
                </Stack>
                <Divider orientation='vertical' height={'100%'} ml='20px' />
              </CheckboxGroup>
            </VStack>

          </HStack>
          <Stack spacing="4" w={'100%'} alignItems={'start'}>
            {joins.map((join, index) => (
              <Box key={index} borderWidth="1px" p="4" w={'100%'} borderRadius="md">
                <Heading size="md" mb="4">
                  Join {index + 1}
                </Heading>
                <Stack display={"flex"} direction={'row'} spacing="2">
                  <Select
                    value={join.joinType}
                    onChange={(e) => handleJoinChange(index, e.target.value)}
                  >
                    {joinTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </Select>
                  <Select
                    value={join.table}
                    onChange={(e) => handleTableJoinChange(index, e.target.value)}
                  >
                    <option value="" disabled>
                      Select table to join
                    </option>
                    {Object.keys(segmentationData?.props).map((table) => (
                      <option key={table} value={table}>
                        {table.charAt(0).toUpperCase() + table.slice(1)}
                      </option>
                    ))}
                  </Select>

                  <Select
                    // value={join.columns.join(',')}
                    onChange={(e) => handleConditionChange(index, e.target.value.split(','))}
                    multi
                    placeholder="Select columns"
                  >
                    {!!availableColumns.length && availableColumns.map((field) => (
                      <option key={field.value} value={field.value}>
                        {field.value}
                      </option>
                    ))}
                  </Select>
                  <Text>=</Text>
                  <Select
                    value={join.columns.join(',')}
                    onChange={(e) => handleColumnChange(index, e.target.value.split(','))}
                    multi
                    placeholder="Select columns"
                  >
                    {Object.keys(tables[join.table] || {}).map((field) => (
                      <option key={field} value={field}>
                        {field}
                      </option>
                    ))}
                  </Select>

                  {/* <Input
                    value={join.condition}
                    onChange={(e) => handleConditionChange(index, e.target.value)}
                    placeholder="Join condition (e.g., users.id = orders.user_id)"
                  /> */}
                  <SecondaryButton
                    p={'10px'}
                    w={'fit-content'}
                    onClick={() => handleRemoveJoin(index)}
                    as={TbTrash}
                  >
                  </SecondaryButton>
                </Stack>
              </Box>
            ))}
            <PrimaryButton w={'fit-content'} label={"Add Join +"} onClick={handleAddJoin}>
            </PrimaryButton>
          </Stack>
          {
            !!joins.length &&
            <Box minW={"350px"} >
              <Text>
                Select Columns
              </Text>
              <MultiSelectDropdown width={"350px"} isMulti={true} options={availableColumns} closeMenuOnSelect={false} onChange={(e) => {
                setTablesColumns(e);
                generateQuery()
              }} colorScheme='brand' isClearable={true}></MultiSelectDropdown>
                <Text>
                Group By
              </Text>
              <Select
                w={'350px'}
                // value={join.columns.join(',')}
                onChange={(e) => {
                  setGroupBy(e.target.value);
                  generateQuery()

                }}

                placeholder="Group By"
              >
                {!!availableColumns.length && availableColumns.map((field) => (
                  <option key={field.value} value={field.value}>
                    {field.value}
                  </option>
                ))}
              </Select>
            </Box>

          }
          {
            !!tablesColumns.length && <>
              <Box gap={'15px'} flexWrap={'wrap'} display={'flex'} >
                {
                  tablesColumns.map((column) => (
                    <>
                      <VStack>

                        <Text>Aggregates for {column?.value}</Text>
                        <Select
                          value={selectedAggregates[column.value] || ''}
                          onChange={(e) => {
                            setSelectedAggregates((prev) => {
                              const updatedAggregates = {
                                ...prev, // Spread the existing state
                                [column.value]: e.target.value, // Update the aggregate for the specific column
                              }
                              // console.log(`Selected aggregate for ${column.value}:`, e.target.value);
                              // console.log('Updated selectedAggregates state:', updatedAggregates);

                              return updatedAggregates;
                            });
                          }}
                          placeholder="Select aggregate"
                        >
                          {aggregates.map((aggr, index) => (
                            <option key={index} value={aggr}>
                              {aggr}
                            </option>
                          ))}
                        </Select>

                      </VStack>
                    </>
                  ))
                }


              </Box>
            
            </>
          }


          {selectedTables.map((table) => (
            <Box w={'100%'} key={table} borderWidth="1px" p="4" borderRadius="md">
              <Heading size="md" mb="4">
                {table.charAt(0).toUpperCase() + table.slice(1)} Query
              </Heading>
              <QueryBuilder
                key={table}
                bg="gray.100"
                fields={availableFields.filter((field) => field.name.startsWith(table))}
                operators={operators}
                query={queries[table] || { combinator: 'and', rules: [] }}
                onQueryChange={(newQuery) => handleQueryChange(table, newQuery)}
                controlElements={{
                  fieldSelector: ({ value, handleOnChange }) => (
                    <Select
                      value={value}
                      onChange={(e) => handleOnChange(e.target.value)}
                      placeholder="Select field"
                    >
                      {availableFields
                        .filter((field) => field.name.startsWith(table))
                        .map((field) => (
                          <option key={field.name} value={field.name}>
                            {field.name}
                          </option>
                        ))}
                    </Select>
                  ),
                  operatorSelector: ({ value, handleOnChange }) => (
                    <Select
                      value={value}
                      onChange={(e) => handleOnChange(e.target.value)}
                      placeholder="Select operator"
                    >
                      {operators.map((operator) => (
                        <option key={operator.name} value={operator.name}>
                          {operator.label}
                        </option>
                      ))}
                    </Select>
                  ),
                  valueEditor: ValueEditorComponent,
                  combinatorSelector: ({ options, value, handleOnChange }) => (
                    <Select
                      value={value}
                      onChange={(e) => {
                        handleOnChange(e.target.value)
                        generateQuery()

                      }
                    
                    }
                      placeholder="Select combinator"
                    >
                      {options.map((option) => (
                        <option key={option.name} value={option.name}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  ),
                  removeRuleAction: ({ handleOnClick }) => (
                    < SecondaryButton as={TbTrash} p={'10px'} onClick={handleOnClick} />
                  ),
                  addRuleAction: ({ handleOnClick }) => (
                    <PrimaryButton label="Add Rule +" onClick={()=>{
                      handleOnClick()
                      generateQuery()
                      }} size="sm" px='1vw'>
                    </PrimaryButton>
                  ),
                  addGroupAction: ({ handleOnClick }) => (
                    <PrimaryButton label="Add Group +" onClick={handleOnClick} size="sm" px='1vw'>
                      Add Group
                    </PrimaryButton>
                  ),
                }}
              />
            </Box>
          ))}
          {/* <PrimaryButton label={'Generate Query'} type="submit" mt="4"> */}
          {/* </PrimaryButton> */}
          <PrimaryButton label={'Add Union +'} onClick={() => { setUnion([...union, ""]) }} />
          <PrimaryButton label={'Get Count'} onClick={getQueryCounts} isLoading={isLoading} />
          {/* <Heading>
            {
              "Total Reach" + " " + countsFromQuery
            }
          </Heading> */}

          {
            countsFromQuery ?
              (<>
                <Text mt={'2rem'} >

                  Reach for segment is {audienceRange}% of {JSON.stringify(countsFromQuery)}
                </Text>
                <NumberInput maxW='100px' mr='2rem' value={audienceRange} onChange={(e) => setAudienceRange(e)
                }>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Slider aria-label='slider-ex- 1' value={audienceRange}
                  onMouseEnter={() => setTooltipVisibility(true)} onMouseLeave={() => setTooltipVisibility(false)}
                  onChange={(v) => handleAudienceRange(v)} >
                  <SliderTrack>
                    <SliderFilledTrack />
                    <Tooltip
                      hasArrow
                      bg='teal.500'
                      color='white'
                      placement='top'
                      isOpen={tooltipVisibility}
                      label={audienceRange + "%"}
                    >
                      <SliderThumb children={audienceRange} />
                    </Tooltip>
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </>) :
              (<>
                <div>
                  <Text color="red.500">No Counts Avaliable For This Segment</Text>
                </div>
              </>)
          }
        </VStack>
      </form>
    </Box>
  );
};
