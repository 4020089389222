
import React, { useEffect } from 'react';
import {
  Box,
  Image,
  Text,
  Link,
  IconButton,
  HStack,
  VStack,
  useBreakpointValue,
  Container,
  Stack,
  Flex
} from '@chakra-ui/react';
import { CloseIcon, SettingsIcon } from '@chakra-ui/icons';
import store from '../../store/store';
import Timer from '../MobilePush/Timer';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Slider from 'react-slick';

export default function WebPushPreview({ formData, type }) {

  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [slider, setSlider] = React.useState(null);
  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '10px' });

  useEffect(()=>{
    try{
     formData.buttons = JSON.parse(formData.buttons) 
    }catch(e){

    }
  },[])
  return (
    <Box borderRadius="md"   bg="#fff" p={2} py={4} boxShadow="lg" w="100%" >
      {/* Close button */}
      <IconButton
        aria-label="Close notification"
        icon={<CloseIcon />}
        variant="ghost"
        position="absolute"
        top="4px"
        right="4px"
      />

      {/* Notification image */}
      <Box height="100%" alignItems="baseline">
        {formData?.iconUrl || store?.getState()?.auth?.selectedOrg?.orgIcon ? (
          <Image
            src={formData?.iconUrl || store?.getState()?.auth?.selectedOrg?.orgIcon}
            alt="Notification Icon"
            width="30px"
          />
        ) : null}

        <HStack alignItems="baseline" padding="10px">
          {/* Notification text container */}
          <VStack align="start" width="100%" spacing={2} mb={4}>
            <Text fontSize="lg" fontWeight="bold">
              {formData?.title || formData?.templateTitle || 'Notification Title Here'}
            </Text>
            <Text fontSize="sm">
              {formData?.body || formData?.templateMessage || 'Notification Message Here'}
            </Text>
            {formData?.timerDuration && formData.isTimer && <Timer targetDate={formData.timerDuration} />}

            {/* Notification URL container */}
            <HStack justify="space-between" w="100%" align="center">
              {type !== 'mobile' && (
                <>
                  <Link href={formData?.url || '#'} fontSize="sm" color="blue.500">
                    {store.getState().auth.selectedOrg.domain} 
                  </Link>
                  <IconButton
                    aria-label="Settings"
                    icon={<SettingsIcon />}
                    size="sm"
                    variant="ghost"
                  />
                </>
              )}
            </HStack>

            {formData?.imageUrl && formData?.isImageBasedNotification && (
              <Image src={formData.imageUrl} />
            )}

            {type === 'mobile' && formData?.templateType?.type === 'image' && formData?.templateImageUrl[0] && (
              <Image src={formData?.imageUrl || formData?.templateImageUrl[0]} />
            )}

            {type === 'mobile' && formData?.templateType?.type === 'carousel' && formData?.templateImageUrl?.length > 0 && Array.isArray(formData?.templateImageUrl) && formData.templateImageUrl.length > 0 &&  (
              <>
                <Box position={'relative'} height={'200px'} width={'full'} overflow={'hidden'}>
                  {/* CSS files for react-slick */}
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                  />
                  
                  
                  <Slider {...settings} ref={setSlider}>
                    {formData.templateImageUrl?.map((url, index) => (
                      <Box
                      objectFit={"cover"}
                        key={index}
                        height={'200px'}
                        position="relative"
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                        backgroundImage={`url(${url})`}
                      >
                      {
                        formData.templateImageUrl.length>1 &&(
                          <>
                          <IconButton
                    aria-label="left-arrow"
                    // variant="ghost"
                    position="absolute"
                    left={side}
                    top={top}
                    transform={'translate(0%, -50%)'}
                    zIndex={2}
                    onClick={() => slider?.slickPrev()}
                  >
                    <BiLeftArrowAlt size="15px" />
                  </IconButton>
                  <IconButton
                    aria-label="right-arrow"
                    // variant="ghost"
                    position="absolute"
                    right={side}
                    top={top}
                    transform={'translate(0%, -50%)'}
                    zIndex={2}
                    onClick={() => slider?.slickNext()}
                  >
                    <BiRightArrowAlt size="15px"/>
                  </IconButton>
                          </>
                        )
                      }
                    
                  
                        <Container size="container.lg" height="600px" position="relative">
                          <Stack
                            spacing={6}
                            w={'full'}
                            maxW={'lg'}
                            position="absolute"
                            top="50%"
                            transform="translate(0, -50%)"
                          >
                            <Text color="white" fontSize="xl" fontWeight="bold">
                              Image {index + 1}
                            </Text>
                          </Stack>
                        </Container>
                      </Box>
                    ))}
                  </Slider>
                </Box>
              </>
            )}
             <Flex alignItems="center" justifyContent="center" width="100%">
              
      {  !!Array.isArray(formData?.buttons) && formData?.buttons?.length > 0 && (
                <HStack justifyContent="center" spacing={4}>
                    {formData?.buttons.map((btn, index) => (
                        <Text 
                            key={index} 
                            p="0.3rem" 
                            textDecoration="underline" 
                            pt={0}  
                            textAlign="center" 
                        >
                            {btn?.buttonName}
                        </Text>
                    ))}
                </HStack>
            )} 
        </Flex>
            
          </VStack>
        </HStack>
      </Box>
    </Box>
  );
}



