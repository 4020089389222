import React, { useEffect } from 'react'
import { Avatar, Box, HStack, VStack, Text, Image } from '@chakra-ui/react'
import WebPushPreview from '../Push/WebPushPreview'
import 'react-device-frameset/styles/marvel-devices.min.css'







import * as _ from 'lodash'
function MobilePushPreview({ formData ,type}) {





    return (
        <>
            <Box  >


                <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] z-[99] ">
                    <div class="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
                    <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                    <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                    <div class="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>  <div
                        className="rounded-[2rem] overflow-hidden w-[272px] h-[572px]"
                        style={{
                            background: 'linear-gradient(90deg, hsla(332, 53%, 82%, 1) 0%, hsla(176, 57%, 89%, 1) 100%)'
                        }}
                    >
                    <WebPushPreview type={type} formData={formData} />
                    </div>
                </div>

            </Box>
        </>
    )
}

export default MobilePushPreview
