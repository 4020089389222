import React, {Fragment, useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,  ModalFooter, useToast, Toast } from "@chakra-ui/react"
import {
    Box,
    Button,
    Input,
    Radio,
    RadioGroup,
    Stack,
    Select,
    Spinner,
    Text,
    Flex,
    Link,
    
} from '@chakra-ui/react';
// import { SegmentationService } from 'your-service-path';
import { FaFacebook, FaSyncAlt } from 'react-icons/fa';
import axiosInstance from '../../Services/axiosInstance';
import axios from 'axios';
import { applicationSelector } from '@files-ui/core';
import store from '../../store/store';
import { CiExport } from "react-icons/ci";
import moment from 'moment';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';


const ExportCustomAudienceModal = ({ onClose, criteria, type, segmentId, dates,isOpen }) => {
    
    const [loading, setLoading] = useState(false);
    const [selectedAdAccount, setSelectedAdAccount] = useState(null);
    const [customAudienceList, setCustomAudienceList] = useState([]);
    const [hasAdAccount, setHasAdAccount] = useState(false);
    const [hasCustomAudience, setHasCustomAudience] = useState(false);
    const [isFbConnected, setIsFbConnected] = useState(false);
    const [adAccountsList, setAdAccountsList] = useState([])
    const orgId = store.getState().auth.selectedOrg.id
    const getCustomAudienceCreateJson = () => {
        return {
          name: '',
          subtype: 'CUSTOM',
          description: '',
          customer_file_source: 'USER_PROVIDED_ONLY',
        };
      };
    const [customAudience, setCustomAudience] = useState({
      create: false,
      newCustomAudienceData: getCustomAudienceCreateJson(),
      selected: {},
    });
    const toast = useToast();

    let getAdsAccountsList = () => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/zc/zads/account/list/adv`;
        return axiosInstance.get(url);
    };
    
    let checkFbAuth = ()=>{
           let url = `${process.env.REACT_APP_API_ENDPOINT}/fb/fbmessenger/credentials?restful=true&channel=facebook`;
        return axiosInstance.get(url)
    }
    const checkFbCredentials = () => {
        checkFbAuth().then((res) => {
            setIsFbConnected(res.data.loggedIn);
        });
    };

    useEffect(() => {
        checkFbCredentials();
        getAdsAccountsList().then(response => {
            setAdAccountsList(response?.data?.data?.data) 
              });
    }, []);

    const selectAdAccount = (adAccount) => {
        
        setSelectedAdAccount(adAccount);
        setHasAdAccount(true);
        getCustomAudienceList(adAccount);
    };

    const refreshFbAuthenticated = () => {
        checkFbCredentials();
    };
    let getCustomAudience = (accountId)=>{
        let url = `${process.env.REACT_APP_API_ENDPOINT}/analytics/rest/v1/customaudience/accounts/${accountId}/customaudiences`
        return axiosInstance.get(url)
    }

    const getCustomAudienceList = (accountId) => {
        setLoading(true);
        getCustomAudience(accountId).then((response) => {
            if (response.data.data.data.length > 0) setHasCustomAudience(true);
            setCustomAudienceList(response.data.data.data);
            setLoading(false);
        });
    };
    let createNewCustomAudience = (name,desc) =>{
        let url = `${process.env.REACT_APP_API_ENDPOINT}/analytics/rest/v1/customaudience/create/${orgId}/${selectedAdAccount}/customaudiences/new`
        let data = 
            {
                "newCustomAudienceData": {
                    "name": name,
                    "subtype": "CUSTOM",
                    "description": desc,
                    "customer_file_source": "USER_PROVIDED_ONLY"
                },
                "criteria": {
                    "fields": [
                        "email"
                    ]
                },
                "type": "custom",
                "segmentId": segmentId,
                "dates": {
                    "startDate": moment().subtract(30, "days"),
                    "endDate": moment()
                }
            }
        
        return axiosInstance.post(url,data)

    }
    const updateCreateCustomAudience = () => {
        createNewCustomAudience(customAudience.newCustomAudienceData.name,customAudience.newCustomAudienceData.description).then((res)=>{
            console.log(res);
            onClose()
            Toast("Success")
        }).catch((e)=>console.log(e))
    };

    return (
        <Fragment>
            <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
                <ModalOverlay></ModalOverlay>
                <ModalContent>
                    <ModalHeader>Publish Custom Audience</ModalHeader>
                    <ModalCloseButton></ModalCloseButton>
                    <ModalBody>
                        <Box padding="5px 35px 20px 35px" className="custom-aud-export-container">
                            <Flex direction="column">
                                {!isFbConnected && (
                                    <Flex alignItems="center" justifyContent="center" gap={2}>
                                        <Link href="/integration/facebook/#/facebook" isExternal>
                                            <Button colorScheme="facebook" leftIcon={<FaFacebook />}>
                                                Login with Facebook
                                            </Button>
                                        </Link>
                                        <Button variant="link" onClick={refreshFbAuthenticated}>
                                            Refresh <FaSyncAlt />
                                        </Button>
                                    </Flex>
                                )}

                                {isFbConnected && (
                                    <Flex direction="column">
                                        <Text>Select the Ad account you want to publish custom audience in:</Text>
                                        <Select
                                            placeholder="Select Ad Account..."
                                            // value={selectedAdAccount?.account_id || ''}
                                            onChange={(e) => selectAdAccount(e.target.value)}
                                        >
                                            {!!adAccountsList?.length && adAccountsList.map((account) => (
                                                <option key={account.account_id} value={account.account_id}>
                                                    Name: {account.name} Account Id: {account.account_id}
                                                </option>
                                            ))}
                                        </Select>
                                    </Flex>
                                )}

                                {hasAdAccount && selectedAdAccount && !hasCustomAudience && !loading && (
                                    <Text color={'red'} >No Custom Audience Found!</Text>
                                )}

                                {loading && <Spinner />}
                                {
                                    hasAdAccount && selectedAdAccount && 




                                    <Box>
                                        <Text> Create new custom audience:</Text>
                                        <RadioGroup
                                            onChange={(value) =>
                                                setCustomAudience({ ...customAudience, create: value === 'create-new' })
                                            }
                                            value={customAudience.create ? 'create-new' : 'update-existing'}
                                            >
                                            <Stack direction="column">
                                                {/* <Radio value="update-existing">Update Existing</Radio>
                                                <Box display={!customAudience.create ? 'block' : 'none'}>
                                                <Select
                                                placeholder="Select Custom Audience..."
                                                value={customAudience.selected?.id || ''}
                                                onChange={(e) =>
                                                    setCustomAudience({
                                                        ...customAudience,
                                                        selected: customAudienceList.find(
                                                            (aud) => aud.id === e.target.value
                                                            ),
                                                            })
                                                            }
                                                            >
                                                            {customAudienceList.map((aud) => (
                                                                <option key={aud.id} value={aud.id}>
                                                                {aud.name} {aud.description}, Reach: {aud.approximate_count}
                                                                </option>
                                                                ))}
                                                                </Select>
                                                                </Box> */}
                                                <Radio value="create-new">Create New</Radio>
                                                <Box display={customAudience.create ? 'block' : 'none'}>
                                                    <Flex direction="row" gap={4}>
                                                        <Input
                                                            placeholder="Name"
                                                            
                                                            onChange={(e) =>
                                                                setCustomAudience({
                                                                    ...customAudience,
                                                                    newCustomAudienceData: {
                                                                        ...customAudience.newCustomAudienceData,
                                                                        name: e.target.value,
                                                                    },
                                                                })
                                                            }
                                                            />
                                                        <Input
                                                            placeholder="Description"
                                                            
                                                            onChange={(e) =>
                                                                setCustomAudience({
                                                                    ...customAudience,
                                                                    newCustomAudienceData: {
                                                                        ...customAudience.newCustomAudienceData,
                                                                        description: e.target.value,
                                                                    },
                                                                })
                                                            }
                                                            />
                                                    </Flex>
                                                </Box>
                                            </Stack>
                                        </RadioGroup>
                                    </Box>
                                                        }
                                

                                
                            </Flex>
                        </Box>

                    </ModalBody>
                    <ModalFooter>
                        
                    <Flex justifyContent="flex-end" padding="10px" >
                                    <PrimaryButton
                                        label="Publish"
                                        disabled={!selectedAdAccount}
                                        onClick={updateCreateCustomAudience}
                                    >
                                        Publish 
                                    </PrimaryButton>
                                    
                                </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Fragment>

    )
}
export default ExportCustomAudienceModal;
