import React from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { FormControl, FormLabel, Input, VStack, FormErrorMessage } from '@chakra-ui/react';

const DiskWriter = ({ setGatewayData, index }) => {
  const { errors, touched, values, setFieldValue } = useFormikContext();

  const handleChange = (field, event) => {
    const value = event.target.value;
    // Update the credentials state directly
    setFieldValue(`credentials.${field}`, value); 
    setGatewayData({ ...values.credentials, [field]: value });
  };

  return (
    <VStack spacing={4} alignItems="flex-start">
      <FormControl isInvalid={errors.name && touched.name}>
        <FormLabel>Name</FormLabel>
        <Input
          name={`credentials.name`} 
          placeholder="Enter your name"
          value={values.credentials.name || ''}
          onChange={(e) => handleChange('name', e)}
        />
        <ErrorMessage name={`credentials.name`} component={FormErrorMessage} />
      </FormControl>
      <FormControl isInvalid={errors.localPath && touched.localPath}>
        <FormLabel>Local Path</FormLabel>
        <Input
          name={`credentials.localPath`} 
          placeholder="/path/to/local/file"
          value={values.credentials.localPath || ''}
          onChange={(e) => handleChange('localPath', e)}
        />
        <ErrorMessage name={`credentials.localPath`} component={FormErrorMessage} />
      </FormControl>
    </VStack>
  );
};


export default DiskWriter;
