import { Box, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import store from '../../store/store'
import { useState } from 'react'
import TemplateSelection from './TemplateSelection'
import TemplateSelectionWhatsapp from './TemplateSelectionWhatsapp'
import { fetchTemplate, fetchTemplateTG } from '../../Services/api'
import { useQuery } from 'react-query'
import TemplateSelectionSms from './TemplateSelectionSms'
export default function TemplateSelectionTG({ formik, templatesData, setTemplatesData , setSelectedGatewayUuid , selectedGatewayUuid }) {

    const [orgList, setOrgList] = useState(store.getState()?.org?.orgList)
    
    const templateQuery = useQuery(['fetchTemplate'], {
        queryFn: async () => {
            
            let res = await fetchTemplateTG(formik.values.selectedChannel, formik.values.orgList)
            return res.data
        },
        enabled: true
    })

    useEffect(() => {
        
        templateQuery.refetch()
        setOrgList(orgList.filter((org) => {
            return formik.values.orgList.includes(org.token)
        }))
    }, [])
    return (
        <>
            {
                !!templateQuery?.data?.length && templateQuery?.data.map((e, i) => (
                    <Box>
                        <Text>
                            {/* {formik.values.selectedChannel} */}
                            {/* {org.name} */}
                            {

                                formik.values.selectedChannel == 'email' &&  
                                <>
                                    {/* <Text>{JSON.stringify(templateQuery)}</Text> */}
                                    <TemplateSelection templateQueryRefetch={templateQuery.refetch} templatesData={templatesData} setTemplatesData={setTemplatesData} templateQuery={e} selectedChannel={formik.values.selectedChannel} orgToken={e.org_token} formik={formik} />
                                </>
                            }
                            {

                                formik.values.selectedChannel == 'whatsapp' &&
                                <>
                                {/* <Text>{formik.values.selectedChannel}</Text> */}
                                    <TemplateSelectionWhatsapp templatesData={templatesData} setTemplatesData={setTemplatesData} templateQuery={e} selectedChannel={formik.values.selectedChannel} orgToken={e.org_token} formik={formik} />
                                </>
                                
                            }
                            {
                                formik.values.selectedChannel == 'sms' &&
                                <>
                                    <TemplateSelectionSms selectedGatewayUuid={selectedGatewayUuid} setSelectedGatewayUuid = {setSelectedGatewayUuid} templatesData={templatesData} setTemplatesData={setTemplatesData} templateQuery={e} selectedChannel={formik.values.selectedChannel} orgToken={e.org_token} formik={formik} />
                                </>
                                
                            }

                        </Text>

                        {/* <Text>
        {JSON.stringify(targetGroupData) }
    </Text> */}
                    </Box>

                ))
            }
        </>
    )
}
