import React from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { FormControl, FormLabel, Input, VStack, FormErrorMessage } from '@chakra-ui/react';

const NetCore = ({ setGatewayData }) => {
  const { errors, touched, values, setFieldValue } = useFormikContext();

  const handleChange = (field, event) => {
    const value = event.target.value;
    // Update the credentials state directly
    setFieldValue(`credentials.${field}`, value);
    setGatewayData({ ...values.credentials, [field]: value });
  };

  return (
    <VStack spacing={4} alignItems="flex-start">
      <FormControl isInvalid={errors.name && touched.name}>
        <FormLabel>Name</FormLabel>
        <Input
          name={`credentials.name`} 
          placeholder="Enter your name"
          value={values.credentials.name || ''}
          onChange={(e) => handleChange('name', e)}
        />
        <ErrorMessage name={`credentials.name`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.apiKey && touched.apiKey}>
        <FormLabel>API Key</FormLabel>
        <Input
          name={`credentials.apiKey`} // Field name for apiKey
          placeholder="Enter your API key"
          value={values.credentials.apiKey || ''}
          onChange={(e) => handleChange('apiKey', e)}
        />
        <ErrorMessage name={`credentials.apiKey`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.from && touched.from}>
        <FormLabel>From</FormLabel>
        <Input
          name={`credentials.from`} 
          placeholder="example@example.com"
          value={values.credentials.from || ''}
          onChange={(e) => handleChange('from', e)}
        />
        <ErrorMessage name={`credentials.from`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.replyTo && touched.replyTo}>
        <FormLabel>Reply To</FormLabel>
        <Input
          name={`credentials.replyTo`} 
          placeholder="replyto@example.com"
          value={values.credentials.replyTo || ''}
          onChange={(e) => handleChange('replyTo', e)}
        />
        <ErrorMessage name={`credentials.replyTo`} component={FormErrorMessage} />
      </FormControl>
    </VStack>
  );
};

export default NetCore;
