import React from 'react'
import { SimpleGrid, Box, Divider, Heading, Text } from '@chakra-ui/react'
import CustomCard from '../../components/themeComponents/CustomCard';
import Recipecard from '../../components/themeComponents/RecipeCard';
import TemplateCard from './TemplateCard';
import { AiOutlineMail } from 'react-icons/ai';
import { MdOutlineSms } from 'react-icons/md';
import { MdOutlineWhatsapp } from 'react-icons/md';
import { PiNotificationDuotone } from 'react-icons/pi';
import { FaRegBell } from 'react-icons/fa';
import { TbDeviceMobileMessage } from 'react-icons/tb';

export default function Templates() {

  const channels = [
    {

      "title": "Email",
      "description": `Create attractive emails and enhance customer
      retention with a cost-effective solution`,
      "btnLabel": 'CREATE',
      navigateTo: { pathname: '/email/list', state: { "Welcome Message": [], } },
      icon: AiOutlineMail,
      
    },
    {
      title: "WhatsApp",
      description: `Create hyper-personalized messages to maximize conversion rates`,
      btnLabel: 'CREATE',

      navigateTo: { pathname: '/whatsapp/list', state: { } },
      icon: MdOutlineWhatsapp

    },
    {
      title: "SMS",
      description: `Unlock wide-reach & cost-effective conversions through targeted messaging`,
      btnLabel: 'CREATE',

      navigateTo: { pathname: '/sms/list', state: { } },
      icon: MdOutlineSms

    },
    {
      title: "Onsite",
      description: `Engage website visitors in real-time and improve customer experience`,
      btnLabel: 'CREATE',

      navigateTo: { pathname: '/nudge/list', state: { } },
      icon: PiNotificationDuotone

    },
    {
      title: "Push",
      description: `Re-engage visitors and prompt them to return and take appropriate action`,
      btnLabel: 'CREATE',

      navigateTo: { pathname: '/push/list', state: { } },
      icon: FaRegBell,
      comingSoon: false
    },
    {
      title: "App Push",
      description: `Personalise communications based on user preferences to create an interactive experience`,
      btnLabel: 'CREATE',
      navigateTo: { pathname: '/mobilepush/list', state: {  } },
      icon: TbDeviceMobileMessage,
      comingSoon: false

    }

  ];
  return (
    <>
      <Box my={'1vw'}>
        <Text fontSize={'1.2vw'} > 
          Templates
        </Text>
      </Box>
      <Divider />
      <Box my={'1vw'} >
        <Text fontSize={'1vw'} > Select the desired channel to communicate with your customers</Text>
        <Text fontSize={'0.8vw'} > Create an Omni-channel strategy to improve customer retention</Text>
      </Box>
      <Box display={'flex'} justifyContent={'space-around'}  my={'1vw'} p={'1rem'} >
        <SimpleGrid spacing={'2vw'} columns={[2, null, 2]} >
          {
            !!channels.length &&
            channels.map((data, i) => (
              <>
                {
                  <TemplateCard key={i} content={data} />
                }
              </>
            ))
          }

        </SimpleGrid>
      </Box>

    </>
  )
}
