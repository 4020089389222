import { Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Icon, Input, VStack, useRadioGroup, Box, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
import DraftCampaignForm from './components/DraftCampaignForm';
import { useFormik } from 'formik';
import { broadcastCampaign as initialValues } from '../../_helpers/formValues';
import { draftCampaign } from '../../Services/api';
import { useNavigate } from 'react-router-dom';

function CampaignDraftDrawer({ isOpen, onClose, orgToken }) {
    const toast = useToast();
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: handleSubmit
    })
    async function handleSubmit(values, action, obj) {
        console.log('formik values', values, obj)
        action.setSubmitting(true);
        let res = await draftCampaign(orgToken, values);
        console.log(res);
        action.setSubmitting(false);

    }
    const saveCampaign = ({redirectToNext}) => async (values, action) => {
        
        //formik.handleSubmit({redirectToNext: redirectToNext});
        //console.log('formik values', redirectToNext, values, action);
        action.setSubmitting(true);
        let res = await draftCampaign(orgToken, values).catch(error => {
            action.setSubmitting(false);
            toast({title: 'Error'});
        });
        action.setSubmitting(false);
        !!redirectToNext ? navigate(`/campaign/${res.data?.uuid}`) : toast({title: 'Drafted'})
        
    }
    return (
// 
        <Drawer isOpen={isOpen} size={'sm'} onClose={onClose} >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Create  </DrawerHeader>
                <Divider colorScheme={'#000'}></Divider>
                <DrawerBody>
                    <DraftCampaignForm formik={formik}></DraftCampaignForm>
                </DrawerBody>
                <Divider />
                <DrawerFooter justifyContent={'start'}>
                    <PrimaryButton label={'Save & Continue'} mr={2} type={'submit'} onClick={() => saveCampaign({redirectToNext: true})(formik.values, formik)} isLoading={formik.isSubmitting}></PrimaryButton>
                    <OutlinedButton label={'Save Draft'} onClick={() => saveCampaign({redirectToNext: false})(formik.values, formik)} isLoading={formik.isSubmitting}></OutlinedButton>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>

    )
}


export default CampaignDraftDrawer
