import React from 'react';
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, FormControl, FormLabel, Input, FormErrorMessage } from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import OutlinedButton from '../../components/themeComponents/OutlinedButton';

// Validation schema (adjust based on your needs)
const validateEmail = value => {
    let error;
    if (!value) {
        error = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(value)) {
        error = 'Invalid email address';
    }
    return error;
};

function ExportCSV({ isOpen, confirmButtonText, cancelButtonText, title, text, downloadcsv, onCancel }) {

    const handleSubmit = async (values, { resetForm }) => {
        // Create the payload correctly
        
        const payload = {
            email: values.email,
            org_token: "119",
        };
       
        
        
        try {
            await downloadcsv(payload.org_token,payload.email);
        } catch (error) {
            console.error("Error in exporting CSV", error);
        }

        // Optionally reset form and close dialog
        resetForm();
        onCancel(); // Close dialog
    };

    return (
        <AlertDialog isOpen={isOpen} onClose={onCancel}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        {title}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Formik
                            initialValues={{ email: '' }}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <FormControl>
                                        <FormLabel htmlFor="email">Email</FormLabel>
                                        <Field name="email" validate={validateEmail}>
                                            {({ field }) => (
                                                <Input {...field} id="email" placeholder="Enter your email" />
                                            )}
                                        </Field>
                                        <FormErrorMessage>
                                            <ErrorMessage name="email" />
                                        </FormErrorMessage>
                                    </FormControl>
                                    <AlertDialogFooter>
                                        <OutlinedButton
                                            label={cancelButtonText}
                                            isLoading={isSubmitting}
                                            colorScheme={'danger'}
                                            size="sm"
                                            mr="2"
                                            onClick={onCancel}
                                        />
                                        <PrimaryButton
                                            label={confirmButtonText}
                                            type="submit"
                                            size="sm"
                                            isLoading={isSubmitting}
                                        />
                                    </AlertDialogFooter>
                                </Form>
                            )}
                        </Formik>
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}

export default ExportCSV;
