import React, { useState, useEffect } from 'react';
import { Box, Text, FormLabel, HStack, VStack, Input } from '@chakra-ui/react';
import Select from '../../components/themeComponents/react-chakra-select';
import store from '../../store/store';
import { prepareOrgList } from '../../_helpers/data_helper';
import { getEmailConf } from '../../Services/api';
import moment from "moment";
import * as _ from 'lodash';

export default function OrgAndConfig({ formik, selectedOrgList, setSelectedOrgList }) {
  
  const [emailConfiguration, setEmailConfiguration] = useState([]);

  const emailConf = async (token) => {
    try {
      const res = await getEmailConf(token);
      setEmailConfiguration((prevConfig) => [...prevConfig, res.data]);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectChange = (selectedOptions) => {
    // Update selectedOrgList with the new selected options
    setSelectedOrgList(selectedOptions);
  
    // Update formik orgList with selected values
    formik.setFieldValue(
      'orgList',
      selectedOptions.map((e) => e.value)
    );
  
    // Handle removed organizations
    if (selectedOptions.length < selectedOrgList.length) {
      const updatedConfig = emailConfiguration.filter((_, i) =>
        selectedOptions.some(option => option.value === selectedOrgList[i]?.value)
      );
      setEmailConfiguration(updatedConfig);
    }
  
    // Fetch configuration for the newly added organization if applicable
    if (selectedOptions.length > selectedOrgList.length) {
      const latestToken = selectedOptions[selectedOptions.length - 1].value;
      emailConf(latestToken);
    }
  };
  

  const channels = [
    {
      label: "Email",
      value: "email"
    },
    {
      label: "Whatsapp",
      value: "whatsapp"
    },
    {
      label: "SMS",
      value: "sms"
    }
  ];

  useEffect(() => {
    formik.setFieldValue("campaignName", _.capitalize(formik.values.selectedChannel) + "- " + moment(new Date()).format("lll"));
  }, [formik.values.selectedChannel]);

  return (
    <Box maxW="1100px">
      <Text fontSize="1vw">Channel</Text>
      <Select options={channels}
      value={_.filter(channels, { 'value': formik.values.selectedChannel })}
      onChange={(e) => {
        
        formik.setFieldValue("selectedChannel", e.value);
      }} />
      
      <Box mt="1rem">
        <form>
          <Text>Campaign Name </Text>
          <Input type="text" name="campaignName" value={formik.values.campaignName} onChange={formik.handleChange} />
          <Text>Bank</Text>
          <Select isMulti options={prepareOrgList(store.getState().org.orgList)}
            onChange={handleSelectChange}
            value = {    selectedOrgList }
            closeMenuOnSelect={false}
            colorScheme="brand"
            isClearable={false} />
        </form>
      </Box>

      {formik.values.selectedChannel === 'email' && (
        <>
          <FormLabel>Configuration</FormLabel>
          <VStack bg="#fff" w="100%">
            <HStack justifyContent="space-between" w="100%" px="2rem">
              {['Bank', 'From', 'Configuration Type'].map((value) => (
                <Text key={value}>{value}</Text>
              ))}
            </HStack>

            {emailConfiguration.length > 0 ? (
              emailConfiguration.map((config, i) => (
                <HStack key={i} justifyContent="space-between" w="100%" px="2rem">
                  <Text>{config?.orgName || selectedOrgList[i]?.data?.name || 'N/A'}</Text>
                  <Text>{config?.from?.split('<')[1]?.split('>')[0] || 'N/A'}</Text>
                  <Text>{config ? 'Promotional' : 'N/A'}</Text>
                </HStack>
              ))
            ) : (
              <Text>No configurations available</Text>
            )}
          </VStack>
        </>
      )}
    </Box>
  );
}
