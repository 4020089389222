
import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SwitchableTabs from '../../components/themeComponents/SwitchableTabs';
import { Text, Box } from '@chakra-ui/react';
import store from '../../store/store';
import { isProduction } from '../../_helpers/helpers';
import { useTranslation } from 'react-i18next';

function Integration() {
    const { t } = useTranslation();
    const location = useLocation();
    
    const tabContent = [
        {
            label: 'Org Info',
            navigateTo: '/settings/orginfo'
        },
        {
            label: 'Profile',
            navigateTo: '/settings/profiles'
        },
        // {
        //     label: 'Users',
        //     navigateTo: '/integration/application'
        // },
        // {
        //     label: 'Email',
        //     navigateTo: '/integration/application'
        // },
        {
            label: 'SMS',
            navigateTo: '/settings/sms'
        },
        {
            label: 'Whatsapp',
            navigateTo: '/settings/whatsapp'
        },
        {
            label:'Email',
            navigateTo: '/settings/email-integration'
        },
        {
            label: 'Facebook',
            navigateTo: '/fb/auth/login/settings'
        },
        {
            label: 'Javascript Features',
            navigateTo: '/settings/js-features'
        },
        {
            label: 'API Credentials',
            navigateTo: '/settings/api-credentials'
        },
        
        // {
        //     label: 'Billing',
        //     navigateTo: '/integration/integration'
        // },
    ];

    const [integration, setIntegration] = useState(store.getState().auth?.white_label.data);

    // Determine the active tab based on the current path
    const activeTabIndex = tabContent.findIndex(tab => location.pathname.startsWith(tab.navigateTo));

    return (
        <>
            <Box marginLeft={'1rem'} padding={'2rem'} borderRadius={'1vw'} bg={'#EEEEEE'} alignItems={'baseline'} border={'1px solid #bebcbc'} >
                <Text mt={'0.5rem'} fontSize={'1.5vw'}>{t("Settings")}</Text>
                <SwitchableTabs tabContent={tabContent} activeTabIndex={activeTabIndex} />
                <Outlet context={{
                    integration: integration,
                    isProduction: isProduction,
                }} />
            </Box>
        </>
    );
}

export default Integration;
