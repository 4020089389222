import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  useToast,
  FormErrorMessage,
  HStack,
  Checkbox,
  Text,
  Icon,
  VStack
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ImageGallery from '../../components/ImageGallery';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import axiosInstance from '../../Services/axiosInstance';
import WebPushPreview from './WebPushPreview';
import store from '../../store/store';
import { validationSchema } from './validationSchema';
import { FaTrash, FaUpload } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import InputWithEmoji from '../../components/InputWithEmoji';
import { TagsInput } from 'react-tag-input-component';


const CreateTemplate = () => {
  const toast = useToast();
  const navigate = useNavigate()
  const location = useLocation()
  const formik = useFormik({
    initialValues: {
      action: [],
      iconUrlOption: '',
      iconUrl: '',
      templateName: '',
      isDisabled: false,
      isPersistent: false,
      isRenotified: false,
      isImageInserted: false,
      isImageBasedNotification: false,
      imageUrlOption: '',
      imageUrl: '',
      tags: [],
      url: '',
      utmParameters: {
        campaignSource: '',
        campaignName: '',
        campaignMedium: '',
        campaignTerm: '',
        campaignContent: '',
      },

      title: '',
      body: '',
      hasUtm: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        let data = { ...values };
        let url = `/rest/v1/push/template`;
        let method = "post";
    
        
        data.tags = data.tags.map((tag) => ({ "tag" : tag }));
    
    
        if (location?.state?.uuid) {
          url += `/${location.state.uuid}`;
          method = "put";
        }
    
    
        const res = await axiosInstance[method](url, data);
    
    
        if (res) {
          navigate("/push/list");
          toast({
            title: "Form submitted successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {

        toast({
          title: "An error occurred.",
          description: error?.response?.data?.message || "Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    
  });
  useState(() => {
    if (location?.state?.uuid) {
      formik.setValues(
        location.state
      )
    }
  }, [])
  return (
    <HStack>

      <VStack w={'100%'} alignItems={'baseline'}  >
        <Text fontSize={'1.5vw'} >Create Template </Text>
        <Box
          w={'80%'}
          as="form"
          onSubmit={formik.handleSubmit}
          p={4}
          borderRadius={8}
        >
          <FormControl
            mb={4}
            isInvalid={formik.touched.templateName && formik.errors.templateName}
          >
            <FormLabel>Template Name</FormLabel>
            <Input bg={'#ebebeb'} borderRadius={'16px'}
              type="text"
              name="templateName"
              placeholder="Template name"
              value={formik.values.templateName}
              onChange={formik.handleChange}
            />
            <FormErrorMessage>{formik.errors.templateName}</FormErrorMessage>
          </FormControl>


          <FormControl
            mb={4}
            isInvalid={formik.touched.title && formik.errors.title}
          >
            <FormLabel>Title</FormLabel>
            {/* <Input bg={'#ebebeb'} borderRadius={'16px'}
              type="text"
              name="title"
              placeholder="Title"
              value={formik.values.title}
              onChange={formik.handleChange}
            /> */}
            <InputWithEmoji onChange={(e) => formik.setFieldValue("title", e)} value={formik.values.title}></InputWithEmoji>
            <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
          </FormControl>

          {/* Body */}
          <FormControl
            mb={4}
            isInvalid={formik.touched.body && formik.errors.body}
          >
            <FormLabel>Body</FormLabel>
            {/* <Input bg={'#ebebeb'} borderRadius={'16px'}
              type="text"
              name="body"
              placeholder="Body"
              value={formik.values.body}
              onChange={formik.handleChange}
            /> */}
            <InputWithEmoji onChange={(e) => formik.setFieldValue("body", e)} value={formik.values.body}></InputWithEmoji>
            <FormErrorMessage>{formik.errors.body}</FormErrorMessage>
          </FormControl>
          {/* Icon URL */}
          {(
            <FormControl
              mb={4}
              flexDirection={'column'}
              display={'flex'}
              isInvalid={formik.touched.iconUrl && formik.errors.iconUrl}
            >
              <FormLabel>Icon URL</FormLabel>
              <Input bg={'#ebebeb'} borderRadius={'16px'}
                type="url"
                name="iconUrl"
                placeholder="Icon URL"
                value={formik.values.iconUrl || store.getState().auth.selectedOrg.orgIcon}
                onChange={formik.handleChange}
              />
              <FormErrorMessage>{formik.errors.iconUrl}</FormErrorMessage>
              <ImageGallery
                setImageData={(value) =>
                  formik.setFieldValue('iconUrl', value.mediaFileUrl)
                }
                renderComponent={<Text my={3} cursor={'pointer'} decoration={'underline'} alignSelf={'end'} float={'right'} >Upload Icon  <Icon as={FaUpload} /> </Text>}
              />
            </FormControl>
          )}


          <FormErrorMessage>{formik.errors.iconUrl}</FormErrorMessage>
          <Checkbox
            name="isImageBasedNotification"
            isChecked={formik.values.isImageBasedNotification}
            onChange={formik.handleChange}
          >
            Is Image Based Notification
          </Checkbox>
          {!!formik.values.isImageBasedNotification && (
            <FormControl
              mb={6}
              isInvalid={formik.touched.imageUrl && formik.errors.imageUrl}
            >

              <FormLabel>Image URL</FormLabel>
              <Input bg={'#ebebeb'} borderRadius={'16px'}
                type="url"
                name="imageUrl"
                placeholder="Image URL"
                value={formik.values.imageUrl}
                onChange={() => {
                  formik.handleChange()
                  formik.setFieldValue("isImageInserted", !!formik.values.imageUrl)

                }}
              />
              <ImageGallery
                setImageData={(value) =>
                  formik.setFieldValue('imageUrl', value.mediaFileUrl)
                }

                renderComponent={<Text my={3} cursor={'pointer'} float={'right'} decoration={'underline'}  >Upload Image <Icon as={FaUpload} /> </Text>}
              />
            </FormControl>
          )}
          <FormErrorMessage>{formik.errors.imageUrl}</FormErrorMessage>

          {/* URL */}
          <FormControl
            mb={4}
            isInvalid={formik.touched.url && formik.errors.url}
          >
            <FormLabel>Landing Page URL</FormLabel>
            <Input bg={'#ebebeb'} borderRadius={'16px'}
              type="url"
              name="url"
              placeholder="URL"
              value={formik.values.url}
              onChange={formik.handleChange}
            />
            <FormErrorMessage>{formik.errors.url}</FormErrorMessage>
          </FormControl>

          {/* Title */}

          {/* Tags */}
          <FormControl mb={4}>
            <FormLabel>Tags</FormLabel>

            <FormControl mb={2} display={'flex'} gap={2} alignItems={'baseline'} >
              <TagsInput
                style={{ width: "100%" }}
                value={formik.values.tags}
                onChange={(e)=>formik.setFieldValue("tags",e)}
                name="tags"
                placeHolder="Enter Tag(s)"

              />


            </FormControl>

          </FormControl>

          <Stack spacing={4} direction="column">
            <Checkbox
              name="isDisabled"
              isChecked={formik.values.isDisabled}
              onChange={formik.handleChange}
            >
              Is Disabled
            </Checkbox>

            <Checkbox
              name="isPersistent"
              isChecked={formik.values.isPersistent}
              onChange={formik.handleChange}
            >
              Is Persistent
            </Checkbox>

            <Checkbox
              name="isRenotified"
              isChecked={formik.values.isRenotified}
              onChange={formik.handleChange}
            >
              Is Renotified
            </Checkbox>


          </Stack>
          {/* UTM Parameters Checkbox */}
          <HStack alignItems="baseline">
            <Checkbox
              isChecked={formik.values.hasUtm}
              onChange={() => {

                formik.setFieldValue('hasUtm', !formik.values.hasUtm);
              }}
            />
            <FormLabel>UTM Parameters</FormLabel>
          </HStack>

          {/* UTM Parameters */}
          {!!formik.values.hasUtm && (
            <FormControl mb={4}>
              <Stack spacing={2}>
                <FormControl
                  isInvalid={
                    formik.touched.utmParameters?.campaignSource &&
                    formik.errors.utmParameters?.campaignSource
                  }
                >
                  <FormLabel>Campaign Source</FormLabel>
                  <Input bg={'#ebebeb'} borderRadius={'16px'}
                    type="text"
                    name="utmParameters.campaignSource"
                    placeholder="Campaign Source"
                    value={formik.values?.utmParameters?.campaignSource}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>
                    {formik.errors.utmParameters?.campaignSource}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={
                    formik.touched.utmParameters?.campaignName &&
                    formik.errors.utmParameters?.campaignName
                  }
                >
                  <FormLabel>Campaign Name</FormLabel>
                  <Input bg={'#ebebeb'} borderRadius={'16px'}
                    type="text"
                    name="utmParameters.campaignName"
                    placeholder="Campaign Name"
                    value={formik.values.utmParameters?.campaignName}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>
                    {formik.errors.utmParameters?.campaignName}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={
                    formik.touched.utmParameters?.campaignMedium &&
                    formik.errors.utmParameters?.campaignMedium
                  }
                >
                  <FormLabel>Campaign Medium</FormLabel>
                  <Input bg={'#ebebeb'} borderRadius={'16px'}
                    type="text"
                    name="utmParameters.campaignMedium"
                    placeholder="Campaign Medium"
                    value={formik.values.utmParameters?.campaignMedium}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>
                    {formik.errors.utmParameters?.campaignMedium}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={
                    formik.touched.utmParameters?.campaignTerm &&
                    formik.errors.utmParameters?.campaignTerm
                  }
                >
                  <FormLabel>Campaign Term</FormLabel>
                  <Input bg={'#ebebeb'} borderRadius={'16px'}
                    type="text"
                    name="utmParameters.campaignTerm"
                    placeholder="Campaign Term"
                    value={formik.values.utmParameters?.campaignTerm}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>
                    {formik.errors.utmParameters?.campaignTerm}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={
                    formik.touched.utmParameters?.campaignContent &&
                    formik.errors.utmParameters?.campaignContent
                  }
                >
                  <FormLabel>Campaign Content</FormLabel>
                  <Input bg={'#ebebeb'} borderRadius={'16px'}
                    type="text"
                    name="utmParameters.campaignContent"
                    placeholder="Campaign Content"
                    value={formik.values.utmParameters?.campaignContent}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>
                    {formik.errors.utmParameters?.campaignContent}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </FormControl>

          )}


          <PrimaryButton label={location?.state?.uuid ? 'Update' : 'Save'} type="submit" float="right" />
        </Box>
      </VStack>

      {/* Web Push Preview */}
      <Box pt={'2rem'} w={'50%'} display={'flex'} alignSelf={'baseline'} >

        <WebPushPreview
          formData={formik.values}
        />
      </Box>
    </HStack >
  );
};

export default CreateTemplate;
