import React from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { FormControl, FormLabel, Input, Select, VStack, FormErrorMessage } from '@chakra-ui/react';

const AmazonSES = ({ setGatewayData }) => {
  const { errors, touched, values, setFieldValue } = useFormikContext();

  const handleChange = (field, event) => {
    const value = event.target.value;
    // Update the credentials state directly
    setFieldValue(`credentials.${field}`, value);
    setGatewayData({ ...values.credentials, [field]: value });
  };

  return (
    <VStack spacing={4} alignItems="flex-start">
      <FormControl isInvalid={errors.name && touched.name}>
        <FormLabel>Name</FormLabel>
        <Input
          name={`credentials.name`} 
          placeholder="Enter your name"
          value={values.credentials.name || ''}
          onChange={(e) => handleChange('name', e)}
        />
        <ErrorMessage name={`credentials.name`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.accessKey && touched.accessKey}>
        <FormLabel>Access Key</FormLabel>
        <Input
          name={`credentials.accessKey`} // Field name for accessKey
          placeholder="Enter your access key"
          value={values.credentials.accessKey || ''}
          onChange={(e) => handleChange('accessKey', e)}
        />
        <ErrorMessage name={`credentials.accessKey`} component={FormErrorMessage} />
      </FormControl>

     

      <FormControl isInvalid={errors.fromEmail && touched.fromEmail}>
        <FormLabel>From Email</FormLabel>
        <Input
          name={`credentials.fromEmail`} // Field name for fromEmail
          placeholder="example@example.com"
          value={values.credentials.fromEmail || ''}
          onChange={(e) => handleChange('fromEmail', e)}
        />
        <ErrorMessage name={`credentials.fromEmail`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.region && touched.region}>
        <FormLabel>Region</FormLabel>
        <Select
          name={`credentials.region`} // Field name for region
          value={values.credentials.region || ''}
          onChange={(e) => handleChange('region', e)}
        >
          <option value="">Select Region</option>
          <option value="us-west-1">US West</option>
          <option value="us-east-1">US East</option>
          <option value="eu-west-1">EU</option>
        </Select>
        <ErrorMessage name={`credentials.region`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.replyTo && touched.replyTo}>
        <FormLabel>Reply To</FormLabel>
        <Input
          name={`credentials.replyTo`} // Field name for replyTo
          placeholder="replyto@example.com"
          value={values.credentials.replyTo || ''}
          onChange={(e) => handleChange('replyTo', e)}
        />
        <ErrorMessage name={`credentials.replyTo`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.secretKey && touched.secretKey}>
        <FormLabel>Secret Key</FormLabel>
        <Input
          type="password" // To hide the secret key
          name={`credentials.secretKey`} // Field name for secretKey
          placeholder="Enter your secret key"
          value={values.credentials.secretKey || ''}
          onChange={(e) => handleChange('secretKey', e)}
        />
        <ErrorMessage name={`credentials.secretKey`} component={FormErrorMessage} />
      </FormControl>
    </VStack>
  );
};

export default AmazonSES;
