import * as yup from "yup"
export const validationSchema = yup.object({

    templateName: yup
      .string().required('Template name is required'),
    iconUrl: yup
      .string().required('URL is required'),
  
    // imageUrlOption: yup.string().required('Image URL Option is required'),
    // imageUrl: yup
    //   .string()
    //   .url('Invalid URL')
    //   .when('imageUrlOption', {
    //     is: 'IMAGEFROMTEXT',
    //     then: yup.string().required('Image URL is required'),
    //     otherwise: yup.string().notRequired(),
    //   }),
    url: yup.string().url('Invalid URL').required('URL is required'),
    title: yup.string().required('Title is required'),
    body: yup.string().required('Body is required'),
    
    
    utmParameters: yup.object().shape({
      campaignSource: yup.string().test(
        'campaignSource-test',
        'Campaign Source is required',
        function () {
  
          return this.parent.hasUtm ? !!this.parent.campaignSource : true;
        }
      ),
      campaignName: yup.string().test(
        'campaignName-test',
        'Campaign Name is required',
        function () {
          return this.parent.hasUtm ? !!this.parent.campaignName : true;
        }
      ),
      campaignMedium: yup.string().test(
        'campaignMedium-test',
        'Campaign Medium is required',
        function () {
          return this.parent.hasUtm ? !!this.parent.campaignMedium : true;
        }
      ),
      campaignTerm: yup.string().test(
        'campaignTerm-test',
        'Campaign Term is required',
        function () {
          return this.parent.hasUtm ? !!this.parent.campaignTerm : true;
        }
      ),
      campaignContent: yup.string().test(
        'campaignContent-test',
        'Campaign Content is required',
        function () {
          return this.parent.hasUtm ? !!this.parent.campaignContent : true;
        }
      ),
    }),
  });
  