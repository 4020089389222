import React, { useState, useRef, useEffect } from 'react'
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import "highlight.js/styles/monokai.css";
import { ChakraProvider, Box, Input, Button, FormControl, FormErrorMessage, FormLabel, Text, Stack, HStack, Divider } from "@chakra-ui/react";
import JSMapper from './JSMapper';
import Instructions from './Instructions';
import PlatformWidget from '../../components/themeComponents/PlatformWidget';
import CustomIcon from "../../images/Custom Website.png"
import PrimaryButton from '../../components/themeComponents/PrimaryButton';

import { useOutletContext } from 'react-router-dom';
// import PlatformWidget from '../../components/themeComponents/PlatformWidget';
import { integrateShopify } from '../../Services/IntegrationService';
import MagentoIcon from "../../images/Magento.png"
import { MdDownloadForOffline } from "react-icons/md";
import { useSelector } from 'react-redux';
hljs.registerLanguage("javascript", javascript);



function JSCodeIntegration() {
  const { integration } = useOutletContext();
  const orgToken= useSelector((state) => state.auth.selectedOrg.token)
  // console.log(orgToken,"OTD");
  // const url=`${impo}`
  function downlaodfile(type){
    const url = `${process.env.PUBLIC_URL}/push/download-${type}?orgtoken=${orgToken}}`;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = "Integration_Guide";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
  }
  const WidgetContent = () => (
    <Stack justifyItems={'space-around'}  >
      <Text fontSize={'1.2vw'} >Let’s connect your Custom Website to {integration.entityName}</Text>
      <Text fontSize={'0.9vw'} width={'60%'} my={'5px'} >Integrate your Custom Website to this {integration.entityName} account to start the flow of
        events and website activity. Build flows to improve your customer
        retention. Analytics & Customer Data to be integrated separately
      </Text>
      <PrimaryButton   width={'8vw'} marginTop={'5px'} type={'submit'} onClick={()=>downlaodfile("document")} borderRadius={'8px'} height={'1.8vh'} padding={'1vw 1vw'} fontSize={'0.8vw'} label={'Download Guide'} rightIcon={<MdDownloadForOffline />} 
      />
    </Stack>
  )
  return (
    <>
      <Box bg={'#fff'} borderRadius={'1vw'} display={'flex'} flexDirection={'column'} p={'1rem'} justifyContent={'center'} alignItems={'center'} >
        <Box alignItems={'baseline'} display={'flex'} flexDirection={'column'} gap={'1vh'} >
          <PlatformWidget platform={
            {
              title: ``,
            }
          }
            icon={CustomIcon}
            WidgetContent={WidgetContent}

          />
        </Box>
          <Stack p={'1rem'} gap={'2vh'} alignSelf={'baseline'}  >
            <Text fontSize={'1.2vw'} >Add the {integration.entityName} mapper to your website using Javascript code</Text>
            <Text fontSize={'0.8vw'} >If your website is built on Wordpress/Magento/Shopify, integration can be done directly using plugin here</Text>
        <JSMapper />
          <PrimaryButton width={'12vw'} height={'4vh'} borderRadius={'8px'} onClick={()=>downlaodfile("gcmfiles")} fontSize={'0.8vw'} label={'Download Service Worker'} rightIcon={<MdDownloadForOffline/>} />
          </Stack>
      </Box>
    </>
  )
}

export default JSCodeIntegration;