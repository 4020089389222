import React, { useEffect, useState } from "react";
import { Button, Box, Text, FormControl, FormLabel, Input, Stack, FormErrorMessage, Divider, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import { setSFTPCredintials, getSFTPCredintials } from "../../Services/api";

const validationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
  server: Yup.string().required("Server is required"),
  port: Yup.number().required("Port is required").positive().integer(),
  folder: Yup.string().required("Folder is required"),
});

const formatDate = (isoString) => {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const SFTP = () => {
  const navigate = useNavigate();
  const [sftpData, setSftpData] = useState(null);
  const toast = useToast();
  const urlPath = window.location.pathname.split("/")[2];
  const isSftpActive = urlPath === 'sftp';

  const fetchSFTPData = async () => {
    try {
      const response = await getSFTPCredintials();
      // console.log(response, "response for sftp");
      setSftpData(response.data); 
    } catch (error) {
      console.log("Failed to fetch SFTP credentials");
    }
  };

  useEffect(() => {
    fetchSFTPData();
  }, []);

  return (
    <>
      <Box display="flex" gap={4} align="center" mb={4}>
        <Button onClick={() => navigate("/su/manager")} variant="outline" size="sm">
          Super Manager
        </Button>
        <Button
          variant={isSftpActive ? "solid" : "outline"} 
          size="sm"
          colorScheme={isSftpActive ? "blue" : "gray"} 
          onClick={() => navigate("/su/sftp")}
        >
          SFTP
        </Button>
      </Box>

      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
        <Box 
          p={4} 
          borderWidth={1} 
          borderRadius="md" 
          shadow="md" 
          maxWidth="600px"
        >
          <Formik
            initialValues={{
              username: sftpData?.username || "",
              password: sftpData?.password || "",
              server: sftpData?.server || "",
              port: sftpData?.port || "",
              folder: sftpData?.folder || ""
            }}
            validationSchema={validationSchema}
            enableReinitialize 
            onSubmit={async (values, { setSubmitting }) => {
              // console.log("Form data:", values);
              try {
                await setSFTPCredintials(values);
                toast({
                  title: 'Success',
                  description: 'Saved successfully',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });
              } catch (error) {
                toast({
                  title: 'Error',
                  description: 'Failed to save credentials',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel htmlFor="username">Username</FormLabel>
                    <Field as={Input} id="username" name="username" variant="outline" size="sm" />
                    <ErrorMessage name="username" component={FormErrorMessage} />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <Field as={Input} type="password" id="password" name="password" variant="outline" size="sm" />
                    <ErrorMessage name="password" component={FormErrorMessage} />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="server">Server</FormLabel>
                    <Field as={Input} id="server" name="server" variant="outline" size="sm" />
                    <ErrorMessage name="server" component={FormErrorMessage} />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="port">Port</FormLabel>
                    <Field as={Input} type="number" id="port" name="port" variant="outline" size="sm" />
                    <ErrorMessage name="port" component={FormErrorMessage} />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="folder">Folder</FormLabel>
                    <Field as={Input} id="folder" name="folder" variant="outline" size="sm" />
                    <ErrorMessage name="folder" component={FormErrorMessage} />
                  </FormControl>

                  <PrimaryButton label={"Submit"} type={"submit"} isLoading={isSubmitting} />
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>

        <Box p={4} mb={4}>
          {sftpData?.audit_data && (
            <>
              {sftpData.audit_data.map((item, index) => (
                <Box key={index} mb={2}>
                  <Text>Last Updated at: {formatDate(item.lastUpdated)}</Text>
                  <Divider my={2} />
                  <Text>Last Updated by: {item.email}</Text>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SFTP;
