import React, { useState, useEffect, Fragment } from 'react';
import Flatpickr from 'react-flatpickr';
import { Box, Flex, FormLabel, Radio, Stack, Text, Input } from '@chakra-ui/react';
import 'flatpickr/dist/themes/material_green.css';
import store from '../../store/store';

function ScheduleCampaign({ channel, orgToken,scheduleData,setScheduleData,initializeScheduleData }) {
    
    const orgList = store.getState().org.orgList;
    const minDate = new Date();

    

    const getOrgName = (token) => {
        let orgName = orgList.find(e => e.token === token);
        return orgName ? orgName.name : '';
    };

    const onDateSelect = (dateStr, token, field) => {
        setScheduleData(prev => ({
            ...prev,
            [token]: {
                ...prev[token],
                [field]: dateStr[0]
            }
        }));
    };

    const handleRadioChange = (token, status) => {
        setScheduleData(prev => ({
            ...prev,
            [token]: {
                ...prev[token],
                scheduleStatus: status
            }
        }));
    };

    const handleInputChange = (e, token) => {
        const { name, value } = e.target;
        setScheduleData(prev => ({
            ...prev,
            [token]: {
                ...prev[token],
                [name]: value
            }
        }));
    };
    useEffect(() => {
        initializeScheduleData(orgToken);
    }, []);
useEffect(()=>{
    console.log(scheduleData)
},[scheduleData])
    return (
        <Fragment>
            <Box w={'100%'} m={'0 auto'} mt={5} p={3} bg={'white'} maxW={'930px'} id={'scheduleCampaign'}>
                <Text color={'heading'} as={'b'}>
                    {getOrgName(orgToken) + ' '}
                </Text>
                <Text color={'heading'} as={'b'}>Schedule Campaign</Text>
                <Flex direction={'column'} mt={4}>
                    <Stack spacing={4} direction={'column'}>
                        <Radio 
                            value='NOW' 
                            name='scheduleStatus' 
                            isChecked={scheduleData[orgToken]?.scheduleStatus === 'NOW'} 
                            onChange={() => handleRadioChange(orgToken, 'NOW')}
                        >
                            <FormLabel color={'inputLabel'} m={0} fontSize={'sm'}>Send Now</FormLabel>
                        </Radio>
                        <Radio 
                            value='SCHEDULED' 
                            name='scheduleStatus' 
                            isChecked={scheduleData[orgToken]?.scheduleStatus === 'SCHEDULED'} 
                            onChange={() => handleRadioChange(orgToken, 'SCHEDULED')}
                        >
                            <FormLabel color={'inputLabel'} m={0} fontSize={'sm'}>Schedule for Later</FormLabel>
                        </Radio>
                        {
                            scheduleData[orgToken]?.scheduleStatus === 'SCHEDULED' && (
                                <Flex alignItems={'center'} pl={10}>
                                    <Text color={'inputLabel'} fontSize={'sm'}>Specify</Text>
                                    <Box border={'1px solid'} height={10} fontSize={'md'} p={4} display={'flex'} alignItems={'center'} maxW={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                        <Flatpickr
                                            options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }}
                                            onChange={(date) => onDateSelect(date, orgToken, 'scheduleAt')}
                                            value={scheduleData[orgToken]?.scheduleAt}
                                        />
                                    </Box>
                                </Flex>
                            )
                        }
                        <Radio 
                            value='RECURRING' 
                            name='scheduleStatus' 
                            isChecked={scheduleData[orgToken]?.scheduleStatus === 'RECURRING'} 
                            onChange={() => handleRadioChange(orgToken, 'RECURRING')}
                        >
                            <FormLabel color={'inputLabel'} m={0} fontSize={'sm'}>Recurring</FormLabel>
                        </Radio>
                        {
                            scheduleData[orgToken]?.scheduleStatus === 'RECURRING' && (
                                <Box display={'flex'} flexDirection={'column'} gap={3}>
                                    <Flex alignItems={'center'} pl={10}>
                                        <Text color={'inputLabel'} fontSize={'sm'} maxW={'100px'} w={'100%'}>Start At</Text>
                                        <Box border={'1px solid'} height={10} fontSize={'md'} p={4} display={'flex'} alignItems={'center'} maxW={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                            <Flatpickr
                                                options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }}
                                                onChange={(date) => onDateSelect(date, orgToken, 'scheduleAt')}
                                                value={scheduleData[orgToken]?.scheduleAt}
                                            />
                                        </Box>
                                    </Flex>
                                    <Flex alignItems={'center'} pl={10}>
                                        <Text color={'inputLabel'} fontSize={'sm'} maxW={'100px'} w={'100%'}>Repeat Every</Text>
                                        <Input
                                            name='repeatAfterDays'
                                            value={scheduleData[orgToken]?.repeatAfterDays}
                                            onChange={(e) => handleInputChange(e, orgToken)}
                                            ml={3}
                                            maxW={'300px'}
                                        />
                                    </Flex>
                                    <Flex alignItems={'center'} pl={10}>
                                        <Text color={'inputLabel'} fontSize={'sm'} maxW={'100px'} w={'100%'}>Stop At</Text>
                                        <Box border={'1px solid'} height={10} fontSize={'md'} p={4} display={'flex'} alignItems={'center'} maxW={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                            <Flatpickr
                                                options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }}
                                                onChange={(date) => onDateSelect(date, orgToken, 'repeatStopDateTime')}
                                                value={scheduleData[orgToken]?.repeatStopDateTime}
                                            />
                                        </Box>
                                    </Flex>
                                </Box>
                            )
                        }
                    </Stack>
                </Flex>
            </Box>
        </Fragment>
    );
}

export default ScheduleCampaign;
