import React, { useState } from "react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Box,
  Text,
  useToken,
  Button
} from "@chakra-ui/react";
import TableHead from "../Campaigns/components/TableHead";
import AdminTable from "./AdminTable";
import hexToRgba from "hex-to-rgba";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from "@ajna/pagination";
import { useNavigate } from "react-router-dom";

const SuperAdminTable = ({ orderData }) => {
  // console.log(orderData, "OD");
  const tableHeadingData = ["Domain", "Status"];
  const [mainColor] = useToken("colors", ["primary.main"]);
  const [totalCount, setTotalCount] = useState(0);
  const navigate=useNavigate()

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    total: totalCount,
    initialState: { currentPage: 1, pageSize: 5, isDisabled: false },
    limits: { outer: 1, inner: 1 },
  });
  return (
    <>
   
       
      <TableContainer
        bg={"white"}
        maxH={"60vh"}
        w={"99%"}
        overflowY={"auto"}
        mt={0}
      >
        <Table>
          {
            <>
              <TableHead headersProps={tableHeadingData}></TableHead>
              <AdminTable orderData={orderData} />
            </>
          }
        </Table>
      </TableContainer>
      {/* {!!orderData && (
        <Pagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        >
          <PaginationContainer
            alignItems={"center"}
            justifyContent="end"
            mt={2}
          >
            <PaginationPrevious
              mr={2}
              w={8}
              bg={hexToRgba(mainColor, 0.4)}
              p={1}
              fontSize={"xs"}
              height={8}
              minW={8}
            >
              &lt;
            </PaginationPrevious>
            <PaginationPageGroup height={8}>
              {pages.map((page) => (
                <PaginationPage
                  key={`pagination_page_${page}`}
                  page={page}
                  w={10}
                  bg={hexToRgba(mainColor, 0.4)}
                  p={1}
                  fontSize={"xs"}
                  height={8}
                  minW={8}
                  isDisabled={currentPage === page}
                ></PaginationPage>
              ))}
            </PaginationPageGroup>
            <PaginationNext
              ml={2}
              w={8}
              bg={hexToRgba(mainColor, 0.4)}
              p={1}
              fontSize={"xs"}
              height={8}
              minW={8}
            >
              &gt;
            </PaginationNext>
          </PaginationContainer>
        </Pagination>
      )} */}
    </>
  );
};

export default SuperAdminTable;
