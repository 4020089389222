import { Modal, ModalOverlay, Text, ModalContent, ModalCloseButton, ModalHeader, ModalBody } from '@chakra-ui/react'
import { Box, Grid, Progress, Flex } from '@chakra-ui/react';
import React from 'react'

function ControlGroupDetailsModal({ isOpen, onClose, data }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'} scrollBehavior='inside'>
            <ModalOverlay></ModalOverlay>
            <ModalContent w={'auto'} maxW={'700px'}>
                <ModalHeader fontSize={'sm'}>Template Preview</ModalHeader>
                <ModalCloseButton onClick={onClose} ></ModalCloseButton>
                <ModalBody>
                    <Box p={5} maxW="800px" mx="auto" borderWidth="1px" borderRadius="lg" >
                        <Text fontSize="xl" fontWeight="bold" mb={5}>Campaign Statistics</Text>
                        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6}>
                            <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="sm">
                                <Text fontWeight="bold">Transaction Campaign Sent</Text>
                                <Text>{data.transactionCampaignSent}</Text>
                            </Box>
                            <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="sm">
                                <Text fontWeight="bold">Transaction Campaign Not Sent</Text>
                                <Text>{data.transactionCampainNotSent}</Text>
                            </Box>
                            <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="sm">
                                <Text fontWeight="bold">Campaign Sent to Customers</Text>
                                <Text>{data.campaignSentToCustomers}</Text>
                            </Box>
                            <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="sm">
                                <Text fontWeight="bold">Campaign Not Sent to Customers</Text>
                                <Text>{data.campaignNotSentToCustomer}</Text>
                            </Box>
                        </Grid>

                        <Box mt={10}>
                            <Text fontSize="lg" fontWeight="bold" mb={3}>Transaction Campaign Sent vs Not Sent</Text>
                            <Flex justify="space-between" mb={2}>
                                <Text>Sent: {data.transactionCampaignSentPercentage}%</Text>
                                <Text>Not Sent: {data.transactionCampainNotSentPercentage}%</Text>
                            </Flex>
                            <Progress colorScheme="green" value={data.transactionCampaignSentPercentage} />
                        </Box>
                    </Box>
                </ModalBody>
            </ModalContent>

        </Modal>
    )
}

export default ControlGroupDetailsModal
