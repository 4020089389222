import React, { useState, useEffect } from "react";
import Transactional from "./Transactional";
import {
  Flex,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
} from "@chakra-ui/react";
import ProviderList from "./ProviderList";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import { fetchEmailChannels } from '../../Services/api'; // Import your fetch function
import { Heading } from "@chakra-ui/react";
const EmailIntegration = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [emailChannels, setEmailChannels] = useState([]);
  const [transactionalUUID, setTransactionalUUID] = useState(null);
  const [promotional, setPromotional] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const[errorMessage,setErrorMessage]=useState("")
  console.log(errorMessage)
  const onOpen = () => setIsOpen(true);
  const onClose = () => {
    fetchList(); 
    setIsOpen(false);
  };

  const fetchList = async () => {
    setIsLoading(true);
    try {
      const response = await fetchEmailChannels();
      setTransactionalUUID(response.data.default_transactional_uuid);
      setPromotional(response.data.default_promotional_uuid);  
      setEmailChannels(response.data.data);
    } catch (error) {
      setErrorMessage(error.response.data.message)
      console.log("Error while fetching list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchList(); 
  }, []);

  return (
    <Box p={6} backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      <Flex justify="flex-end" mb={4} justifyContent={"space-between"}>
      <Text mb={5} fontSize={"20px"}>SMTP Settings Configuration</Text>
        <PrimaryButton size="sm" label="Add Config +" onClick={onOpen}>
          Add Config
        </PrimaryButton>
      </Flex>

      <ProviderList emailChannels={emailChannels} transactionalUUID={transactionalUUID} promotional={promotional} onRefresh={fetchList} isLoading={isLoading}/>

      <Modal isOpen={isOpen} size={'2xl'} onClose={onClose}>
        <ModalOverlay />
        <ModalContent size="2xl">
          <ModalHeader>Add Transactional Configuration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Transactional onSave={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EmailIntegration;
