import React, { Fragment } from 'react'
import { Box, Flex, IconButton, Image } from "@chakra-ui/react"
import { useSelector } from "react-redux"
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import { FaArrowLeft } from "react-icons/fa6";
import { FaRegEye, FaHistory, FaCode, FaRegSave } from "react-icons/fa";
import { IoMdClose, IoMdUndo, IoMdRedo } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
export default function StripoHeader({ previewTemplate, onOpen }) {
    const whiteLabelData = useSelector(state => state.auth?.white_label)
    const navigate = useNavigate()
    return (
        <Fragment>
            <Box height={20} bg={'white'} borderBottom={'1px solid'} borderColor={'gray.300'} pos={'fixed'} top={0} zIndex={9} right={0} left={0} w={'100%'} display={'flex'} alignItems={'center'}>
                <Box w={60}>
                    <Flex h="20" alignItems="center" justifyContent="space-between" borderRight={'1px solid'} borderColor={'gray.300'}>
                        <Flex h="20" alignItems="center" justifyContent="space-between" w={'100%'} mx={8}>
                            <Image src={whiteLabelData.lightBgLogo} w={'100%'} height={'100%'} objectFit={'cover'} />

                            {/* <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} /> */}
                        </Flex>

                        {/* <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} /> */}
                    </Flex>
                </Box>
                <Flex w={'100%'} h={'100%'} display={'flex'} alignItems={'center'} px={4} gap={4} justifyContent={'space-between'}>
                    <Box></Box>
                    <Flex gap={4}>
                        <IconButton icon={<IoMdUndo />} isRound={true} variant={'outline'} title='Undo' id='undoButton'></IconButton>
                        <IconButton icon={<IoMdRedo />} isRound={true} variant={'outline'} title='Redo' id='redoButton'></IconButton>
                        <IconButton icon={<FaCode />} isRound={true} variant={'outline'} title='Code Editor' id='codeEditor'></IconButton>
                        <IconButton icon={<FaRegEye />} isRound={true} variant={'outline'} title='Preview' onClick={previewTemplate}></IconButton>
                    </Flex>
                    <Flex gap={4}>
                        <IconButton icon={<FaRegSave />} isRound={true} variant={'outline'} title='Save' onClick={onOpen}></IconButton>
                        {
                            !window.location.href.includes("all-banks") &&
                        <IconButton icon={<IoMdClose />} isRound={true} variant={'outline'} title='Exit Editor' onClick={() => navigate('/email/list')}></IconButton>
                        }
                    </Flex>
                </Flex>
            </Box>
        </Fragment>
    )
}
