import React, { useState } from 'react';
import { Box, Text, Flex, FormControl, FormLabel, Icon, useDisclosure, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark } from '@chakra-ui/react';
import Select from '../../../components/themeComponents/react-chakra-select';
import { useQuery } from 'react-query';
import { fetchTemplate, getSmsGatways } from '../../../Services/api';
import { prepareGatewayList, prepareTemplateList, smtpTypes } from '../../../_helpers/data_helper';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import TemplatePreviewModal from './TemplatePreviewModal';
import ABTestMobilePreview from './ABTestMobilePreview';

function TemplateselectionBroadCast({ selectedChannel, orgToken, formik }) {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [selectedGatewayUuid, setSelectedGatewayUuid] = useState("");
    const [template, setTemplate] = useState({
        "0": {},
        "1": {},
        "2": {},
        "3": {},
        "4": {},
        "5": {},
    });

    const [targetRatios, setTargetRatios] = useState({
        "0": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
    });

    const gatewayQuery = useQuery(['gatways'], {
        queryFn: async () => {
            let gatewayData = await getSmsGatways();
            return gatewayData.data;
        },
        enabled: true
    });

    const templateQuery = useQuery(['fetchTemplate'], {
        queryFn: async () => {
            let res = await fetchTemplate(selectedChannel, orgToken, selectedGatewayUuid);
            return prepareTemplateList(selectedChannel, res.data);
        }
    });

    const selectTempData = (e, temp) => {
        formik.setFieldValue(`selectedTemp${temp}`, e.uuid);
        formik.setFieldValue(`selectedTempName${temp}`, e.label);
        if (selectedChannel === 'email') {
            formik.setFieldValue(`temp${temp}Body`, e.body);
        }
        if (selectedChannel === 'whatsapp') {
            selectTemplate(e, temp);
        }
    };

    const selectTemplate = (e, temp) => {
        const selectedTemplates = templateQuery.data.filter((template) => {
            return template.uuid === e.uuid;
        });
        setTemplate((prevState) => ({
            ...prevState,
            [temp]: selectedTemplates[0]
        }));
    };

    const handleTargetRatioChange = (temp, value) => {
        const clampedValue = Math.min(value, 100);
        setTargetRatios((prev) => ({
            ...prev,
            [temp]: clampedValue,
        }));
        
        formik.setFieldValue(`targetedAudience.includes[0].targetRatio${temp}`, clampedValue);
    };

    return (
        <Box w={'100%'} m={'0 auto'} mt={5} p={3} bg={'white'} maxW={'930px'} boxShadow="md" borderRadius="md">
            <Text color={'heading'} as={'b'} fontSize="lg">Template Information</Text>
            <Flex direction={'column'} mt={2} maxH="500px" overflowY="auto">
                <Box p={3} bg={'white'} w={'100%'} borderRadius="md" shadow="sm">
                    {selectedChannel === 'sms' && (
                        <FormControl>
                            <FormLabel fontWeight={'600'} color={'inputLabel'}>Select Provider</FormLabel>
                            {!!gatewayQuery?.data && (
                                <Select 
                                    onChange={(e) => {
                                        formik.setFieldValue("selectedGateway", e.value);
                                        setSelectedGatewayUuid(e.value);
                                        templateQuery.refetch();
                                    }}
                                    options={prepareGatewayList(gatewayQuery?.data?.gatewayList)} 
                                />
                            )}
                        </FormControl>
                    )}

                    
                    {[...Array(6)].map((_, index) => {
                        const tempIndex = (index + 1).toString();
                        return (
                            <FormControl key={tempIndex} mt={index > 0 ? '2rem' : 0}>
                                <FormLabel fontWeight={'600'} color={'inputLabel'}>Template {tempIndex}</FormLabel>
                                <Select 
                                    options={templateQuery?.data} 
                                    onChange={(e) => selectTempData(e, tempIndex)} 
                                />
                                {!!formik.values[`selectedTemp${tempIndex}`] && (
                                    <Box 
                                        float={'right'} 
                                        title='Preview' 
                                        onClick={onOpen} 
                                        cursor={'pointer'}>
                                        <Icon 
                                            as={formik.values[`selectedTemp${tempIndex}`] ? FaEye : FaEyeSlash} 
                                            mr={1} 
                                        />
                                    </Box>
                                )}
                                <FormLabel mt={2} fontWeight={'600'} color={'inputLabel'}>Target Ratio {tempIndex}</FormLabel>
                                <Slider 
                                    value={targetRatios[tempIndex]} 
                                    min={0} 
                                    max={100} 
                                    onChange={(value) => handleTargetRatioChange(tempIndex, value)}
                                >
                                    <SliderTrack>
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb bg='black' />
                                    <SliderMark value={targetRatios[tempIndex]} mt='2' ml='-2.5' color='black'>
                                        {targetRatios[tempIndex]}%
                                    </SliderMark>
                                </Slider>
                            </FormControl>
                        );
                    })}

                    {/* SMTP Settings for Email */}
                    {selectedChannel === 'email' && (
                        <FormControl mt={'2rem'}>
                            <FormLabel color={'inputLabel'}>SMTP Settings</FormLabel>
                            <Select 
                                options={smtpTypes} 
                                value={smtpTypes.find(smtp => smtp.key === formik.values.selectedSmtpType)} 
                                onChange={(e) => formik.setFieldValue("selectedSmtpType", e.key)} 
                            />
                        </FormControl>
                    )}
                </Box>

                {/* Modal for preview */}
                {(selectedChannel === 'whatsapp' || selectedChannel === 'sms') ? (
                    Object.keys(template).map((key) => (
                        !!template[key]?.dt && (
                            <ABTestMobilePreview 
                                key={key} 
                                attachment={template[key]?.dt.attachment} 
                                channel={selectedChannel} 
                                buttons={template[key]?.dt.buttons} 
                                sample_body={template[key]?.dt.sample_body} 
                                onClick={onOpen} 
                                isOpen={isOpen} 
                                onClose={onClose} 
                                selectedChannel={selectedChannel} 
                                message={template[key]?.dt.message || template[key]?.message} 
                            />
                        )
                    ))
                ) : (
                    <TemplatePreviewModal isOpen={isOpen} onClose={onClose} tempBody={formik.values.tempBody} />
                )}
            </Flex>
        </Box>
    );
}

export default TemplateselectionBroadCast;
