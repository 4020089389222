import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Input, FormControl, FormLabel, FormErrorMessage, HStack, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';
import { TagsInput } from "react-tag-input-component";
import PrimaryButton from './themeComponents/PrimaryButton';
import { getShortenedUrl, sendTestWhatsapp, sendTestEmail } from '../Services/api';
import { useToast } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { template } from 'lodash';

function SendTestModal({ isOpen, onClose, channel, uuid }) {
    const toast = useToast();
    const [email, setEmail] = useState("");
    const [phones, setPhones] = useState([]);
    const [error, setError] = useState(false);
    const [smptpType, setSmptpType] = useState("promotional")
    const checkExisting = () => {
        return
    }

    const smptpTypes = [
        {
            key : "Promotional",
            value : "promotional"
        },
        {
            key : "Transactional",
            value : "transactional"
        }
]


    let sendTestActions = {
        "Email": async function () {
            let preparedData = {
                email: email,
                selectedSmtptype: smptpType,
                selectedTemplate: uuid
            }

            let testEmail = await sendTestEmail(preparedData).catch((err)=>toast({title: 'Oops!', description: 'Something went wrong while sending test email', status: 'error'}))
            if(!!testEmail){
                toast({title: 'Success', description: 'Test email sent successfully', status: 'success'})
                onClose();
            }
            

        },
        "Whatsapp": async function () {

            let numbers = []
            let mappedUserPhones = phones.map((num, i) => {
                return numbers[i] = { phone: num }
            })
            let preparedData = {
                mappedUserPhones,
                selectedTemp: uuid
            }

            let testWhatsapp = await sendTestWhatsapp(preparedData).catch((err)=>toast({title: 'Oops!', description: 'Something went wrong while sending test whatsapp', status: 'error'}))
            if(!!testWhatsapp){
                toast({title: 'Success', description: 'Test whatsapp sent successfully', status: 'success'})
            }
        }
    }
    const sendTestCommunication = () => {
        !error &&
            sendTestActions[channel]();
    }
    const validateTag = (value) => {
        let re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        if (re.test(value)) {
            setError(false)
            return re.test(value)
        } else {
            setError(true)
            return re.test(value)
        }
    }
    const validateEmail = (value) => {
        let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (re.test(value)) {
            setError(false)
            return re.test(value)
        } else {
            setError(true)
            return re.test(value)
        }
    }

    return (
        <Modal isOpen={isOpen} size={'xl'} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text>Send Test {channel}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={0}>
                    <HStack spacing={4} w={'100%'} >
                        {
                                channel == 'Email' &&
                            <>
                            <VStack w={'100%'} >
                                <Text></Text>
                                <Input value={email} placeholder='Enter email' onChange={(e) => setEmail(e.target.value)} onKeyUp={(e) => validateEmail(e.target.value)} />
                                <Select value={smptpType} onChange={(e)=>setSmptpType(e.target.value)} >
                                    {
                                        smptpTypes.map((e) => (
                                            <option value={e.value} >
                                                {e.key}
                                            </option>
                                        ))
                                        
                                        }
                                </Select>
                                    </VStack>
                            </>
                        }
                        {
                            channel !== 'Email' &&
                            <TagsInput
                                style={{ width: "100%" }}
                                value={phones}
                                onChange={setPhones}
                                name="phone"
                                placeHolder="Enter Phone(s)"
                                onExisting={checkExisting}
                                beforeAddValidate={validateTag}
                            />
                        }

                    </HStack>
                    {error && <Text size={'sm'} color={'red'} >Enter a valid {channel == 'Email' ? "email" : "number"}</Text>}
                    <PrimaryButton label={'Send'} mt={2} float={'right'} onClick={sendTestCommunication} />
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default SendTestModal;
