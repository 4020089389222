import axios from "axios";
import { extractUserDataFromJWT, setTokenInLocalStorage } from "../_helpers/helpers";
import { LOGIN_CONFIRMED, LOGOUT, SET_ORG } from "../store/Reducers/AuthReducer";
import { SET_ORG_LIST, SET_SELECTED_ORG } from "../store/Reducers/OrgReducer";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { getOrgList } from "./api";
export function register(data){
    data['org_name'] = data?.org_domain.replace(/.+\/\/|www.|\..+/g, '')
   return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/jwt/auth/register`, data)
  // return fetch(`${process.env.REACT_APP_API_ENDPOINT}/video/auth/register`, {method: 'POST', body: JSON.stringify(data), headers})
}

export function login(data){
    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/jwt/auth/login`, data);
}

export function setSelectedOrganization(dispatch, selectedOrg) {
    localStorage.setItem("selectedOrg", JSON.stringify(selectedOrg));
    dispatch(SET_ORG({data: selectedOrg}));
}

export function getSelectedOrgFromLocalSorage(){
    let orgString = localStorage.getItem("selectedOrg");
    let org = {};
    try{
        org = JSON.parse(orgString);
    } catch(error){
        org = {};
    }
    return org;
}

export function loginConfirm(action, toast, data, dispatch, navigate){
    console.log(data,"data for super admin bro")
    console.log(data.data.data.isSuperAdmin,"is admin????")
    toast({
        title: 'Logged In.',  status: 'success', duration: 1000, isClosable: true
    })
    let decodedToken = extractUserDataFromJWT(data);
    console.log(decodedToken,"decoded token")
    let {jwt, user_data, exp} = decodedToken;
    setTokenInLocalStorage({jwt: jwt, exp: exp, user_data: user_data,isSuperAdmin:data.data.data.isSuperAdmin});
    dispatch(LOGIN_CONFIRMED({token: jwt, user_data: user_data, exp: exp,isSuperAdmin:data.data.data.isSuperAdmin}));
    setSelectedOrganization(dispatch, user_data.selectedOrg)
    action.setSubmitting(false);
    if(user_data?.extraData?.isAllBanks){
        navigate('/all-banks/list')    
    }else if(window.location.href.includes("ngage")){
        navigate("/campaign/list")
    }else{
        navigate('/')
    }
} 

export function getOrganization(token) {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/video/orglist`, {headers: {token: token}})
}

export function logout(dispatch) {
    localStorage.removeItem("selectedOrg")
    sessionStorage.removeItem("whiteLabel")
    dispatch(LOGOUT());
}

export function extractSelectedOrgFromUserData(userData) {
    let org = {};
    if(!_.isEmpty(userData.selectedOrg)){
        org = userData.selectedOrg;
    }
    return org;
}

export async function extractOrgListFromUserData(userData) {
    let orgList = []
    if(!_.isEmpty(userData.orgList)){
        orgList = userData.orgList;
    }
    return orgList;
}

