import React, { useState } from 'react';
import {
  Box,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Stack,
  Link,
  Button,
  useToast,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { forgotPSWEmail } from '../../Services/api';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

function ForgotPassword() {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const toast = useToast();

  const handleForgotEmail = async (email) => {
    console.log(email, "emailll");
    try {
      let data = await forgotPSWEmail(email);
      console.log(data, "data from click");
      setMessage(data.data.message);
      
      toast({
        title: "Email Sent",
        description: data.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

    } catch (error) {
      setError("Error while sending email. Please try again.");
      console.log("Error while sending mail", error);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  return (
    <Box maxW={'md'} minW={'xl'} boxShadow={'base'} borderRadius={'xl'} bg={'white'} px={5} py={10}>
      <Text fontSize={'2xl'} fontWeight={'bold'} color={'primary.main'}>Welcome Back</Text>
      <Text fontSize={'md'} color={'light'} mb={4}>Enter your email to receive a password reset link.</Text>
      {!!error && (
        <Alert status='error' mb={4}>
          <AlertIcon />
          <AlertDescription fontSize={14}>{error}</AlertDescription>
        </Alert>
      )}
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values, "values after submitting form");
          handleForgotEmail(values.email);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormControl isRequired mb={4} id='email'>
              <FormLabel color={'inputLabel'}>Email</FormLabel>
              <Field name='email'>
                {({ field, form }) => (
                  <Input type='email' {...field} />
                )}
              </Field>
              <FormErrorMessage>
                <Field name='email'>
                  {({ form }) => form.errors.email}
                </Field>
              </FormErrorMessage>
            </FormControl>
            <Button 
              w={'full'} 
              bg={'primary.main'} 
              color={'white'} 
              _hover={{ bg: 'primary.main', opacity: '.8' }} 
              size={'lg'}  
              type='submit'
              isLoading={isSubmitting} 
            >
              Send Email
            </Button>
          </Form>
        )}
      </Formik>
      <Stack pt={6}>
        <Text align={'center'}>
          <Link as={RouterLink} color={'blue.400'} to="/auth/login">
            Login
          </Link>
        </Text>
      </Stack>
    </Box>
  );
}

export default ForgotPassword;
