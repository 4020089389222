import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getPowerBiSettings, powerBiSettings } from "../../Services/api";
import { Flex, Text, Box, FormControl, Input, FormErrorMessage, useToast } from "@chakra-ui/react";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";

const PowerBiIntegration = () => {
  const toast = useToast();
  const [initialUrl, setInitialUrl] = useState(""); // State to hold the fetched URL
  const token = window.location.pathname.split("/")[3];

  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .url("Invalid URL format")
      .required("URL is required"),
  });

  const fetchPowerBiDetails = async () => {
    try {
      const response = await getPowerBiSettings(token);
      // console.log(response, "response for Power BI"); // Fixed console log
      setInitialUrl(response.data.data || ""); // Assuming response.data.url contains the URL
    } catch (error) {
      console.error("Error while fetching Power BI details:", error); 
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // console.log("Form data in Power BI:", values);
    try {
      const payload = {
        powerbi_url: values.url,
      };
      await powerBiSettings(payload, token);
      toast({
        title: "Success!",
        description: "Power BI settings updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to update settings:", error);
      toast({
        title: "Error!",
        description: "Failed to update Power BI settings.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchPowerBiDetails();
  }, []);

  return (
    <>
      <Flex align="center" justify="space-between" mb={4}>
        <Text fontSize="lg" fontWeight="bold">
          Power BI Integration
        </Text>
      </Flex>
      <Formik
        initialValues={{ url: initialUrl }} 
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize // Allows form to update when initial values change
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <FormControl isInvalid={touched.url && !!errors.url}>
              <Field name="url">
                {({ field }) => (
                  <Input
                    placeholder="Enter URL"
                    {...field}
                    size="md"
                  />
                )}
              </Field>
              <ErrorMessage name="url" component={FormErrorMessage} />
            </FormControl>
            <Box mt={4}>
              <PrimaryButton
                type="submit"
                isLoading={isSubmitting}
                label="Submit"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PowerBiIntegration;
