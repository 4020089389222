import React, { Fragment } from 'react'
import { Box, Text,Divider, VStack, Image, useDisclosure, Menu, MenuButton, MenuList, MenuItem, Popover, PopoverTrigger, Portal, PopoverContent, PopoverHeader, PopoverCloseButton, PopoverBody, Button, PopoverFooter, Icon } from "@chakra-ui/react"
import { connect, useDispatch, useSelector } from 'react-redux'
import OrganizationSelectionModal from './OrganizationSelectionModal';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { HiOutlineOfficeBuilding, HiOutlineUser, HiOutlineCog } from "react-icons/hi";
import { logout } from '../Services/AuthService';
import { Link } from 'react-router-dom';
import { PiPlugsConnectedFill } from "react-icons/pi";
import { FaUserTie } from "react-icons/fa";
import { getTokenFromLoacalStorage } from '../_helpers/helpers';
import { CiSettings } from 'react-icons/ci';
function SelectedOrganization({ selectedOrg }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dispatch = useDispatch()
    const isAllBanks  = getTokenFromLoacalStorage()?.user_data?.extraData?.isAllBanks
    
    // const isSuperAdminFromstate=useSelector((state)=>(state.auth.isSuperAdmin))
    const localStorageData = localStorage.getItem("token")
    const superAdminData = JSON.parse(localStorageData);
    
    return (
        <Fragment>
            <Popover placement='auto'>
                <PopoverTrigger>
                    
                    <Box p={3} pos={'absolute'} bottom={0} w={'100%'} display={'flex'} alignItems={'center'} borderTop={'1px'} borderColor={'gray.200'}
                        bg={'sidebar'} _hover={{ bg: 'gray.100' }} transition={'all .3s ease-in-out'} title={selectedOrg.domain}>
                        
                        {
                        !isAllBanks &&
                        <Image src={selectedOrg.orgIcon} w={'40px'} height={"50px"} width={'50px'} borderRadius={"200px"} objectFit={'contain'} mr={2} fallbackSrc='/images/defaultorgicon.png'></Image>
                        }
                        {
                        !!isAllBanks &&
                        <Icon as={HiOutlineCog} boxSize={6}></Icon>
                        }
                        {
                            !isAllBanks &&
                            <Box>
                            <Text fontSize={'md'} mb={0} textTransform={'capitalize'} color={'gray.700'} w={32} textOverflow="ellipsis" overflow={'hidden'} whiteSpace={'nowrap'}>{selectedOrg?.name}</Text>
                            <Text fontSize={'sm'} color={'light'} w={32} textOverflow="ellipsis" overflow={'hidden'} whiteSpace={'nowrap'}>{selectedOrg?.domain}</Text>
                        </Box>
                        }
                        <Box position={'absolute'} right={5}>
                            <IoIosArrowUp></IoIosArrowUp>
                            <IoIosArrowDown></IoIosArrowDown>
                        </Box>
                    </Box>
                </PopoverTrigger>
                <Portal>
                    <PopoverContent _focusVisible={{ shadow: 'none', outline: 'none' }}>
                        {
!isAllBanks &&
                        
                        <PopoverHeader bg={'gray.200'}>
                            <Box bottom={0} w={'100%'} display={'flex'} alignItems={'center'}
                                transition={'all .3s ease-in-out'} title={selectedOrg.domain}>
                                <Image src={selectedOrg.orgIcon} w={'40px'} objectFit={'contain'} mr={2} height={"50px"} width={'50px'} borderRadius={"200px"} dataValue={JSON.stringify(selectedOrg)}  fallbackSrc={ selectedOrg?.orgIcon || '/images/defaultorgicon.png'}></Image>
                                <Box>
                                    {
                                        
<>

                                        <Text fontSize={'md'} mb={0} textTransform={'capitalize'} color={'gray.700'} w={'100%'} textOverflow="ellipsis" overflow={'hidden'} whiteSpace={'nowrap'}>{selectedOrg?.name}</Text>
                                    <Text fontSize={'sm'} color={'light'} w={'100%'} textOverflow="ellipsis" overflow={'hidden'} whiteSpace={'nowrap'}>{selectedOrg?.domain}</Text>
</>
                                }
                                </Box>
                            </Box>
                        </PopoverHeader>
}
                        {/* <PopoverCloseButton></PopoverCloseButton> */}
                        <PopoverBody>
                            <Divider/>
                            {!isAllBanks &&

                                <Box display={'flex'} py={2} flexDirection={'column'} alignItems={'center'}>
                                <Box display={'flex'} alignSelf={'baseline'} py={2} alignItems={'center'} justifyContent={'start'} >
                                    <Icon as={HiOutlineOfficeBuilding} boxSize={6}></Icon>
                                    <Text fontSize={'sm'} ml={2} mb={0}>Organization</Text>
                                </Box>
                                <Box display={'flex'} gap={'1vw'} width={'100%'} >
                                    {/* <Link to='organization-edit'>
                                        <Text textDecoration={'underline'} fontSize={'xs'} cursor={'pointer'} alignItems={'center'}>Edit</Text>
                                        </Link> */}
                                    <Link cursor={'pointer'}  alignItems={'center'} to='organization-add' >
                                        <Text textDecoration={'underline'} fontSize={'xs'} cursor={'pointer'} alignItems={'center'}>Add+</Text>
                                    </Link>
                                    <Text textDecoration={'underline'} fontSize={'xs'} cursor={'pointer'} display={'inline'} alignItems={'center'} onClick={() => onOpen()}>Switch</Text>
                                </Box>
                            </Box>
                                        }
                            <Divider/>
                            <Box display={'flex'} py={2} cursor={'pointer'} alignItems={'center'}>
                                <Icon as={HiOutlineUser} boxSize={6}></Icon>
                                <Link to="/profile" fontSize={'sm'} ml={2} mb={0}>
                                    <Text fontSize= {'sm'} ml={2} mb={0}>Update Profile</Text>
                                </Link>
                            </Box>
                            <Divider/>
                            <Box display={'flex'} py={2} cursor={'pointer'} alignItems={'center'}>
                                <Icon as={PiPlugsConnectedFill } boxSize={6}></Icon>
                                <Link to="/integrations/platforms" fontSize={'sm'} ml={2} mb={0}>
                                    <Text fontSize= {'sm'} ml={2} mb={0}>Integrations</Text>
                                </Link>
                            </Box>
                            <Box display={'flex'} py={2} cursor={'pointer'} alignItems={'center'}>
                                <Icon as={HiOutlineCog} boxSize={6}></Icon>
                                <Link to="/settings/orginfo" fontSize={'sm'} ml={2} mb={0}>
                                    <Text fontSize= {'sm'} ml={2} mb={0}>Settings</Text>
                                </Link>
                            </Box>
                            {

                            }
                            {
                                superAdminData.isSuperAdmin && (
                                    <>
                                    <Box display={'flex'} py={2} cursor={'pointer'} alignItems={'center'}>
                                <Icon as={FaUserTie} boxSize={6}></Icon>
                                <Link to="/su/manager" fontSize={'sm'} ml={2} mb={0}>
                                    <Text fontSize= {'sm'} ml={2} mb={0}>Super Administrator</Text>
                                </Link>
                            </Box>
                                    </>
                                )
                            }
                            
                        </PopoverBody>
                        <PopoverFooter py={3}>
                            <Button size={'sm'} variant={'link'} float={'right'} fontWeight={'normal'} color={'link'} onClick={() => logout(dispatch)}>
                                Log Out
                            </Button>
                        </PopoverFooter>
                    </PopoverContent>
                </Portal>
            </Popover>
            <OrganizationSelectionModal isOpen={isOpen} onClose={onClose}></OrganizationSelectionModal>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state,
        selectedOrg: state.auth.selectedOrg
    }
}
export default connect(mapStateToProps)(SelectedOrganization)
