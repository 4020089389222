import { Navigate, Outlet, Route, createBrowserRouter, redirect, useNavigate } from "react-router-dom"
import AuthLayout from "../Pages/Auth/AuthLayout"
import Login from "../Pages/Auth/Login";
import { LightMode, Text, useToast } from "@chakra-ui/react"
import Register from "../Pages/Auth/Register";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import PrivateRoutes from "./PrivateRoutes";
import { Fragment, Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganization } from "../Services/AuthService";
import Index from "../Layout/Index";
import Dashboard from "../Pages/Dashboard";
import { QueryClient, QueryClientProvider } from "react-query";
import Logout from "../Pages/Auth/Logout";
import Profile from "../Pages/Profile/Profile";
import Campaigns from "../Pages/Campaigns/Campaigns";
import CampaignList from "../Pages/Campaigns/CampaignList";

import EditOrganization from "../Pages/EditOrganization";
import AddOrganization from "../Pages/Profile/AddOrganization";
import Integration from "../Pages/Integration/Integration";
import JSCodeIntegration from "../Pages/Integration/JSCodeIntegration";
import Platforms from "../Pages/Integration/Platforms";
import Shopify from "../Pages/Integration/Shopify";
import Magento from "../Pages/Integration/Magento";
import GTM from "../Pages/Integration/GTM";
import BigCommerce from "../Pages/Integration/BigCommerce";
import GA4 from "../Pages/Integration/GA4";
import WordPress from "../Pages/Integration/WordPress";
import LightSpeed from "../Pages/Integration/LightSpeed";
import Gorgias from "../Pages/Integration/Gorgias";
import ShopifyAuth from "../Pages/Integration/ShopifyAuth";
import WhatsappTemplate from "../Pages/Whatsapp/WhatsappTemplate";


import CreateCampaign from "../Pages/Campaigns/CreateCampaign";

import Flows from "../Pages/Flows/Flows";
import FlowsList from "../Pages/Flows/FlowsList";
import CreateFlow from "../Pages/Flows/CreateFlow";
import Header from "../Layout/Header";
import { getWLData } from "../Services/WhiteLabelService";
import { WHITE_LABEL } from "../store/Reducers/AuthReducer";
import PredefinedFlows from "../Pages/Flows/PredefinedFlows";
import Recipes from "../Pages/Flows/Recipes";
import RecipeTypes from "../Pages/Flows/components/RecipeTypes";
import Editor from "../Pages/Email/Editor";
// import Templates from "../Pages/Templates";
import Templates from "../Pages/Templates/Templates";

import EmailTemplateList from "../Pages/Email/EmailTemplateList";
import SmsTemplateList from "../Pages/SMS/SmsTemplateList";
import SmsTemplate from "../Pages/SMS/SmsTemplate";
import WhatsappTemplateList from "../Pages/Whatsapp/WhatsappTemplateList";
import NudgeTemplateList from "../Pages/Nudge/NudgeTemplateList";
import AudienceList from "../Pages/Audience/AudienceList";
import SegmentList from "../Pages/Audience/SegmentList";
import CreateSegment from "../Pages/Audience/CreateSegment";
import SegmentIndex from "../Pages/Audience/SegmentIndex";
import NudgeLayout from "../Pages/Nudge/NudgeLayout";
import NudgeTemplateForm from "../Pages/Nudge/NudgeTemplateForm";
import TargetGroup from "../Pages/TargetGroup/TargetGroup";
import Settings from "../Pages/Settings/Settings";
import Whatsapp from "../Pages/Settings/Whatsapp";
import SMS from "../Pages/Settings/SMS";
import OrgInfo from "../Pages/Settings/OrgInfo";
import Orders from "../Pages/Audience/Orders";
import FacebookAuth from "../Pages/Audience/FacebookAuth";
import FacebookPages from "../Pages/Audience/FacebookPages";
import LiveActivities from "../Pages/Audience/LiveActivities";
import SuperAdmin from "../Pages/SuperAdmin/SuperAdmin";
import OrgFeatures from "../Pages/SuperAdmin/OrgFeatures";
import SuppressionList from "../Pages/Audience/SuppressionList";
import JavascriptFeatures from "../Pages/Settings/JavascriptFeatures";
import AllBanksOverview from "../Pages/TargetGroup/AllBanksOverview";
import ApiCredentials from "../Pages/Settings/ApiCredentials";
import OrgUserDetails from "../Pages/SuperAdmin/OrgUserDetails";
import SFTP from "../Pages/SuperAdmin/SFTP";
import CreateTemplate from "../Pages/Push/CreateTemplate";
import PushTemplateList from "../Pages/Push/PushTemplateList";

import InappTemplatesList from "../Pages/Inapp/InappTemplatesList";
import InappTemplateCreate from "../Pages/Inapp/InappTemplateCreate";
import MobilePushCreateTemplate from "../Pages/MobilePush/MobilePushCreateTemplate";
import MobilePushList from "../Pages/MobilePush/MobilePushList";

import EmailIntegration from "../Pages/Settings/EmailIntegration";
import ControlGroup from "../Pages/ControlGroups/ControlGroup";

const Organization = lazy(() => import("../Pages/Organization"))

//const toast = useToast()
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            refetchOnReconnect: false, refetchOnWindowFocus: false, refetchInterval: Infinity
        }
    }
});


const FlowError = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate(0)
    }, [])
    return <Fragment></Fragment>
}

export const routes = createBrowserRouter([
    {
        path: '/',
        element: <PrivateRoutes Component={Index}></PrivateRoutes>,
        // errorElement: <h1>Error</h1>,
        //errorElement: <DashboardChild></DashboardChild>,
        children: [
            {
                index: true,
                element: <QueryClientProvider client={queryClient}><Dashboard /></QueryClientProvider>

            },
            {
                path: '/campaign',
                element: <Campaigns></Campaigns>,
                children: [
                    {
                        index: true,
                        loader() {
                            return redirect('list')
                        }
                    },
                    {
                        path: "list",
                        element: <QueryClientProvider client={queryClient}><CampaignList /></QueryClientProvider>,
                    },
                    {
                        path: ":id",
                        element: <QueryClientProvider client={queryClient}><CreateCampaign /></QueryClientProvider>
                    },
                ]
            },
            {
                path: '/flows',
                element: <Flows></Flows>,
                children: [
                    {
                        index: true,
                        loader() {
                            return redirect('list')
                        }
                    },

                    {
                        path: "predefined",
                        element: <PredefinedFlows />,
                        children: [
                            {
                                path: 'welcome-message',
                                element: <Recipes />
                            },
                            {
                                path: 'recover-abandoned-carts',
                                element: <Recipes />
                            },
                            {
                                path: 'review-request',
                                element: <Recipes />
                            },
                            {
                                path: 'rto-reduction',
                                element: <Recipes />
                            },
                            {
                                path: 'increase-conversion',
                                element: <Recipes />
                            },
                            {
                                path: 'list',
                                element: <RecipeTypes />
                            }
                        ]

                    },

                    {
                        path: "list",
                        element: <QueryClientProvider client={queryClient}><FlowsList></FlowsList></QueryClientProvider>
                    }
                ]
            },
            {
                path:'/control-group',
                element:<ControlGroup/>
            },
            {
                path: '/settings',
                element: <Settings></Settings>,
                children: [
                    {
                        path: 'profiles',
                        element: <Profile />
                    },
                    {
                        path: 'whatsapp',
                        element: <Whatsapp />
                    },
                    {
                        path: 'sms',
                        element: <SMS />
                    },
                    {
                        path: 'orgInfo',
                        element: <OrgInfo />
                    },
                    {
                        path: 'js-features',
                        element: <JavascriptFeatures />
                    },
                    {
                        path: 'api-credentials',
                        element: <ApiCredentials />
                    },
                    {
                        path:'email-integration',
                        element:<EmailIntegration/>
                    }
                ]

            },{
                path:'/su/orgfeatures/:token',
                element:<OrgFeatures/>
            },
            {
                path:'/su/allUsers/:token',
                element:<OrgUserDetails/>

            },
            {
                path:'/su/sftp',
                element:<SFTP/>

            },
            {
                path: "/fb/auth/login/settings/*",
                element: <QueryClientProvider client={queryClient}><FacebookAuth></FacebookAuth></QueryClientProvider>
            },
            {
                path: "facebook-pages",
                element: <QueryClientProvider client={queryClient}><FacebookPages></FacebookPages></QueryClientProvider>
            },  
            {
                path: "/profile",
                element: <Profile />
            },
            {
                path: "/organization-edit",
                element: <EditOrganization />
            },
            {
                path: "/su/manager",
                element: <SuperAdmin/>
            },
            {
                path: "/organization-add",
                element: <AddOrganization />
            },
            {
                path: "/integrations",
                element: <Integration />,
                children: [
                    //         {
                    //             index: true,
                    //             loader() {
                    //                 return redirect('/integrations/platforms')
                    //             }
                    //         },
                    //         {
                    //     path: '*',
                    //     element: <Navigate to={'/integrations/platforms'} />
                    // },
                    {
                        path: '/integrations/platforms',
                        element: <Platforms />
                    },

                    {
                        path: '/integrations/custom',
                        element: <JSCodeIntegration />
                    },
                    {
                        path: '/integrations/shopify',
                        element: <Shopify />

                    },
                    {
                        path: '/integrations/magento',
                        element: <Magento />
                    },
                    {
                        path: '/integrations/gtm',
                        element: <GTM />
                    },
                    {
                        path: '/integrations/big-commerce',
                        element: <BigCommerce />
                    },
                    {
                        path: '/integrations/ga4',
                        element: <GA4 />
                    },
                    {
                        path: '/integrations/wordpress',
                        element: <WordPress />
                    },
                    {
                        path: '/integrations/lightspeed',
                        element: <LightSpeed />
                    },
                    {
                        path: '/integrations/gorgias',
                        element: <Gorgias />
                    },
                    {
                        path: '/integrations/shopify/*',
                        element: <ShopifyAuth />
                    },

                ]
            }, {
                path: '/templates',
                element: <Templates></Templates>,

            }, {
                path: '/email',
                element: <Outlet></Outlet>,
                children: [{
                    path: 'list',
                    element: <QueryClientProvider client={queryClient}><EmailTemplateList></EmailTemplateList></QueryClientProvider>

                }]
            },
            {
                path: '/sms',
                element: <Outlet></Outlet>,
                children: [{
                    path: 'list',
                    element: <QueryClientProvider client={queryClient}><SmsTemplateList></SmsTemplateList></QueryClientProvider>

                },
                {
                    path: 'create',
                    element: <QueryClientProvider client={queryClient}> <SmsTemplate></SmsTemplate></QueryClientProvider>
                }
                ]
            },
            {
                path: '/whatsapp',
                element: <Outlet></Outlet>,
                children: [{
                    path: 'list',
                    element: <QueryClientProvider client={queryClient}><WhatsappTemplateList></WhatsappTemplateList></QueryClientProvider>

                },
                {
                    path: 'create',
                    element: <QueryClientProvider client={queryClient}> <WhatsappTemplate></WhatsappTemplate></QueryClientProvider>
                }
                ]
            }, {
                path: '/nudge',
                element: <Outlet></Outlet>,
                children: [{
                    index: true,
                    loader() {
                        return redirect('list')
                    }
                }, {
                    path: 'list',
                    element: <QueryClientProvider client={queryClient}><NudgeTemplateList></NudgeTemplateList></QueryClientProvider>
                }, {
                    path: 'layout',
                    element: <QueryClientProvider client={queryClient}><NudgeLayout></NudgeLayout></QueryClientProvider>,

                }, {
                    path: 'layout/:templateUuid',
                    element: <QueryClientProvider client={queryClient}><NudgeTemplateForm></NudgeTemplateForm></QueryClientProvider>
                }, {
                    path: 'layout/edit',
                    element: <QueryClientProvider client={queryClient}><NudgeTemplateForm></NudgeTemplateForm></QueryClientProvider>
                }]
            },
            {
                path: '/push',
                element: <Outlet></Outlet>,
                children: [{
                    path: 'list',
                    element: <QueryClientProvider client={queryClient}><PushTemplateList></PushTemplateList></QueryClientProvider>

                },
                {
                    path: 'create',
                    element: <QueryClientProvider client={queryClient}> <CreateTemplate></CreateTemplate></QueryClientProvider>
                }
                ]
            },
            {
                path: '/push/overview',
                element: <QueryClientProvider client={queryClient}><PrivateRoutes Component={CreateTemplate}></PrivateRoutes></QueryClientProvider>
            },
            {
                path: '/inapp',
                element: <Outlet></Outlet>,
                children: [{
                    path: 'list',
                    element: <QueryClientProvider client={queryClient}><InappTemplatesList></InappTemplatesList></QueryClientProvider>

                },
                {
                    path: 'create',
                    element: <QueryClientProvider client={queryClient}> <InappTemplateCreate></InappTemplateCreate></QueryClientProvider>
                }
                ]
            },
            {
                path: '/mobilepush',
                element: <Outlet></Outlet>,
                children: [{
                    path: 'list',
                    element: <QueryClientProvider client={queryClient}><MobilePushList></MobilePushList></QueryClientProvider>

                },
                {
                    path: 'create',
                    element: <QueryClientProvider client={queryClient}> <MobilePushCreateTemplate></MobilePushCreateTemplate></QueryClientProvider>
                }
                ]
            },
            {
                path: '/push/overview',
                element: <QueryClientProvider client={queryClient}><PrivateRoutes Component={CreateTemplate}></PrivateRoutes></QueryClientProvider>
            }

            , {
                path: '/audience',
                element: <Outlet></Outlet>,
                children: [{
                    path: "segment",
                    element: <SegmentIndex></SegmentIndex>,
                    children: [
                        {
                            index: true,
                            loader() {
                                return redirect("list")
                            }
                        }, {
                            path: "list",
                            element: <QueryClientProvider client={queryClient}><SegmentList></SegmentList></QueryClientProvider>
                        }, {
                            path: "create",
                            element: <QueryClientProvider client={queryClient}><CreateSegment></CreateSegment></QueryClientProvider>
                        },

                    ]
                },
                {
                    path: "orders",

                    element: <QueryClientProvider client={queryClient}><Orders /></QueryClientProvider>,
                },
                {
                    path: "live-activities",

                    element: <QueryClientProvider client={queryClient}><LiveActivities /></QueryClientProvider>,
                },
                {
                    path: "suppression-list",

                    element: <QueryClientProvider client={queryClient}><SuppressionList /></QueryClientProvider>,
                },
                
                ]
            },
            {
                path: '/all-banks/create',
                element: <QueryClientProvider client={queryClient}><TargetGroup /></QueryClientProvider>
            },
            {
                path: '/all-banks/list',
                element: <QueryClientProvider client={queryClient}><AllBanksOverview /></QueryClientProvider>
            }
        ],
    }, {
        path: '/auth',
        element: <AuthLayout />,
        children: [
            {
                index: true,
                loader() {
                    return redirect('login')
                }
            },
            {
                path: 'login',
                element: <Login />
            }, {
                path: 'register',
                element: <Register />
            }, {
                path: '*',
                element: <Navigate to={'/auth/login'} />
            }, {
                path: 'forgot-password',
                element: <ForgotPassword />
            }
        ],
    }, {
        path: '/organization',
        element: <Suspense fallback={<Text>Loading.....</Text>}><Organization /></Suspense>
    }, {
        path: '/logout',
        element: <Logout></Logout>
    }, {
        path: '/flows/create',
        element: <QueryClientProvider client={queryClient}><PrivateRoutes Component={CreateFlow}></PrivateRoutes></QueryClientProvider>,
        errorElement: <FlowError></FlowError>,
        //loader: apeendRequiredScript
    }, {
        path: '/flows/create/:uuid',
        element: <QueryClientProvider client={queryClient}><PrivateRoutes Component={CreateFlow}></PrivateRoutes></QueryClientProvider>
    },
    {
        path: '/flows/create/:uuid/:actiontype',
        element: <QueryClientProvider client={queryClient}><PrivateRoutes Component={CreateFlow}></PrivateRoutes></QueryClientProvider>
    }
    ,
    {
        path: '/email/edit',
        element: <QueryClientProvider client={queryClient}><PrivateRoutes Component={Editor}></PrivateRoutes></QueryClientProvider>
    },
    {
        path: '/email/edit/:uuid',
        element: <QueryClientProvider client={queryClient}><PrivateRoutes Component={Editor}></PrivateRoutes></QueryClientProvider>
    },
    


])
