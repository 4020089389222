import React, { Fragment } from 'react';
import { Tr, Td, Box, Text, Flex, Icon, useToast } from '@chakra-ui/react';
import { FiEdit } from "react-icons/fi";
import { FaTrash } from "react-icons/fa";
import moment from 'moment';


export default function SuppressionListTable({ list, deleteFromSuppression }) {
    return (
        <Fragment>
            
            {!!list.length > 0 && list.map((item, i) => (
                <Tr >
                    <Td borderRight={'1px solid #ebebeb'}  >
                        <Box>
                            <Text>{i + 1}</Text>
                        </Box>
                    </Td>
                    <Td borderRight={'1px solid #ebebeb'}  >
                        <Box>
                            <Text >{item.phoneNumber || item.email}</Text>
                        </Box>
                    </Td>
                    <Td borderRight={'1px solid #ebebeb'}  >
                        <Box>
                            <Text >{item.reason}</Text>
                        </Box>
                    </Td>
                    <Td borderRight={'1px solid #ebebeb'}  >
                        <Box>
                        
                            <Text fontSize={'0.6vw'} >{moment(item.createdDate).format("MMM D, YYYY h:mm A")}</Text>
                        </Box>
                    </Td>
                    <Td>
                        <Box>
                            <Icon cursor={"pointer"} as={FaTrash} onClick={() => { deleteFromSuppression(item.phoneNumber || item.email)}} ></Icon>
                        </Box>
                    </Td>
                </Tr>
            ))}
        </Fragment>
    )
}
