import React, { useState } from 'react';
import { updateSMPT, deleteEmailChannels } from '../../Services/api'; 
import { FaTrash } from "react-icons/fa";
import Swal from 'sweetalert2'; 
import { OvalSpinner } from '../../components/Loaders';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Radio,
  RadioGroup,
  Heading,
  IconButton,
  Text,
  Flex
} from '@chakra-ui/react';

const ProviderList = ({ emailChannels, transactionalUUID, promotional, onRefresh,isLoading }) => {
  const[errorMessage,setErrorMessage]=useState("")
  const handleRadioChange = async (uuid, smtpType) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to set this channel as ${smtpType === 'transactional' ? 'Default Transactional' : 'Default Promotional'}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, cancel!',
    });

    if (result.isConfirmed) {
      try {
        await updateSMPT(uuid, smtpType);
        onRefresh(); 
        Swal.fire('Updated!', 'The email channel has been updated.', 'success');
      } catch (error) {
        console.error("Error updating email channel:", error);
        Swal.fire('Error!', 'There was an error updating the email channel.', 'error');
      } 
    }
  };

  const handleDeleteChannels = async (uuid) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      html: `<div style="text-align: center;">You want to delete this channel?</div>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });

    if (result.isConfirmed) {
      try {
        await deleteEmailChannels(uuid);
        onRefresh(); 
        Swal.fire('Deleted!', 'The email channel has been deleted.', 'success');
      } catch (error) {
        console.error("Error deleting email channel:", error);
        // setErrorMessage(error.response.data.message)
        setErrorMessage(error.response.data.message)
        Swal.fire({
          title: 'Error!',
          html: `<div style="text-align: center;">${error.response.data.message}</div>`,
          icon: 'error',
        });
      } 
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <OvalSpinner label={"Loading...."} labelSize={"sm"} height={"60px"} /> 
      </Box>
    );
  }

  return (
    <Box p={5}>
     {
      emailChannels?.length>0?(<><Table variant="simple" colorScheme="teal">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th isNumeric>Default Transactional</Th>
            <Th isNumeric>Default Promotional</Th>
            <Th isNumeric>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {emailChannels.map(channel => (
            <Tr key={channel.uuid}>
              <Td>{channel.name.replace(/"/g, '')}</Td>
              <Td isNumeric>
                <RadioGroup>
                  <Radio 
                    name={`transactional-${channel.uuid}`} 
                    isChecked={channel.uuid === transactionalUUID} 
                    onChange={() => handleRadioChange(channel.uuid, 'transactional')} 
                  />
                </RadioGroup>
              </Td>
              <Td isNumeric>
                <RadioGroup>
                  <Radio 
                    name={`promotional-${channel.uuid}`} 
                    isChecked={channel.uuid === promotional} 
                    onChange={() => handleRadioChange(channel.uuid, 'promotional')} 
                  />
                </RadioGroup>
              </Td>
              <Td isNumeric>
                <IconButton 
                  icon={<FaTrash />} 
                  aria-label="Delete Channel" 
                  onClick={() => handleDeleteChannels(channel.uuid)} 
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table></>):(<><Box textAlign="center" mt={4}>
      <Text>No SMTP Configuration</Text>
    </Box></>)
     }
      
    </Box>
  );
};

export default ProviderList;
