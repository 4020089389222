import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { customerDB, getCustomerDB } from "../../Services/api";
import { Flex, Text, Box, FormControl, Input, FormErrorMessage, useToast } from "@chakra-ui/react";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";

const SyncCustomerDB = () => {
  const toast = useToast();
  const [syncData, setSyncData] = useState("");
  const token = window.location.pathname.split("/")[3];
  

  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .url("Invalid URL format")
      .required("URL is required"),
  });

  const fetchCustomerDBDetails = async () => {
    try {
      const response = await getCustomerDB(token);
      console.log(response,"response for fetch")
   
      setSyncData(response?.data?.data || ""); 
    } catch (error) {
      console.error("Error while fetching Power BI details:", error); 
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
  
    
    try {
      const payload = {
        viewNames:syncData,
      };

      await customerDB(values, token);
      toast({
        title: "Success!",
        description: "Synced successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to update settings:", error);
      toast({
        title: "Error!",
        description: "Failed to update  settings.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchCustomerDBDetails();
  }, []);

  return (
    <>
      <Flex align="center" justify="space-between" mb={4}>
        <Text fontSize="lg" fontWeight="bold">
          sync Customer DB
        </Text>
      </Flex>
      <Formik
        initialValues={{ viewNames: syncData }} 
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize 
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <FormControl isInvalid={touched.syncData && !!errors.syncData}>
              <Field name="viewNames">
                {({ field }) => (
                  <Input
                    placeholder="Enter URL"
                    {...field}
                    size="md"
                  />
                )}
              </Field>
              <ErrorMessage name="url" component={FormErrorMessage} />
            </FormControl>
            <Box mt={4}>
              <PrimaryButton
                type="submit"
                isLoading={isSubmitting}
                label="Submit"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SyncCustomerDB;
