import React, { useEffect, useState } from 'react'
import axiosInstance from '../../Services/axiosInstance'
import { Box, Text, Code, Heading, VStack, HStack } from '@chakra-ui/react';

export default function ApiCredentials() {
  const [apiCredentials, setApiCredentials] = useState({})
  const getApiCredentials = async () => {
    const url = '/account/integration'
    const response = await axiosInstance.get(url)
    if (!!response.data) {
      setApiCredentials(response.data)
    }
  }
  useEffect(() => {
    getApiCredentials()
  }, [])
  return (
    <>
      <Text fontSize={'1.5vw'} mb={4}>
      API Credentials
      </Text>
    <Box p={5}  borderWidth="1px" borderRadius="lg" bg={'#fff'} mx="auto" mt="20px">
       
      <VStack align="start" spacing={4}>

        <HStack w={'50%'} justifyContent={'space-between'} >
          <Text fontWeight="bold">API token:</Text>
          <Code p={'0.5rem'} colorScheme="orange">{apiCredentials?.user?.apiToken}</Code>
        </HStack>

        <HStack w={'50%'} justifyContent={'space-between'}  >
          <Text fontWeight="bold">Org token for {apiCredentials?.org?.name} </Text>
          <Code p={'0.5rem'} colorScheme="orange">{apiCredentials?.org?.token}</Code>
        </HStack>
        <Box>
          <Text fontWeight="bold">Sample Usage:</Text>
          <Text>
            POST
          </Text>
          <Code p={'0.5rem'}>
            {process.env.REACT_APP_API_ENDPOINT}/rest/v1/learn/identify?token={apiCredentials?.user?.apiToken}&org_token={apiCredentials?.org?.token}
          </Code>
        </Box>
        <Box  w={'20%'} >
          <Text fontWeight="bold">JSON body:</Text>
          
          <Code p={'0.5rem'} mt={2}>
{`
          {
            "userId":"unique_user_id",
            "email" : "sample@email.com",
            "phone" : "9876543210",
            "is_active":true,
            "source": "app"
            } `}
          </Code>
        </Box>
      </VStack>
    </Box>
            </>
  );
};

