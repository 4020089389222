import React from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Input,
  Textarea,
  Select,
  Checkbox,
  VStack,
  FormControl,
  FormLabel,
  Text,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import MobilePreview from "../Campaigns/components/MobilePreview";
import ImageGallery from "../../components/ImageGallery";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import MobilePushPreview from "./MobilePushPreview";
import Flatpickr from "react-flatpickr";
import { saveAppPushTemplate } from "../../Services/api";
import { useNavigate } from "react-router-dom";

const MobilePushCreateTemplate = () => {
  const toast = useToast();
  const buttonActions = [
    {
      name: "Navigate to a Screen",
      message: "To take the user to a certain navigation screen",
    },
    {
      name: "Landing Page",
      message: "To take the user to a rich landing page",
    },
    {
      name: "Share",
      message: "To let users share the notification directlyedit",
    },
    {
      name: "Copy",
      message: "To let users share the notification directlyedit",
    },
  ];
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      templateName: "",
      templateTitle: "",
      timerDuration: new Date(),
      templateMessage: "",
      notificationDataMap: {},
      templateType: {
        type: "text",
        pushType: "pushNotification",
      },
      templateImageUrl: [""],
      buttons: [
        {
          buttonName: "",
          buttonAction: "Navigate to a Screen",
          buttonUrl: "",
          couponCode: "",
        },
      ],
      type: {
        type: "text",
        pushType: "pushNotification",
      },
      isTimer: false,
      targetPlatform: ["android"],
    },
    onSubmit: async (values) => {
      let data = values;
      // data["buttons"] = JSON.stringify(values.buttons);
      // data["targetPlatform"] = JSON.stringify(values.targetPlatform);
      // data["templateImageUrl"] = JSON.stringify(values.templateImageUrl);
      let resp = await saveAppPushTemplate(data);
      if (!!resp) {
        navigate("/mobilepush/list");
        try {
          let resp = await saveAppPushTemplate(data);
          if (resp) {
            navigate("/mobilepush/list");
            toast({
              title: "Template saved.",
              description: "Template saved successfully!",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Error.",
              description: "Failed to save the template. Please try again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        } catch (error) {
          toast({
            title: "An error occurred.",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      
      }
    },
  });

  return (
    <HStack gap={"10vw"} w={"100%"}>
      <Box justifySelf={"start"} mt={8} width={"50%"} p={4} >
        <Text fontSize={"1.5vw"}>Create Template</Text>

        <form onSubmit={formik.handleSubmit}>
          <Accordion allowToggle defaultIndex={[0]}>
            {/* First Accordion - Template Information */}
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Template Information
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <VStack spacing={4}>
                  <FormControl>
                    <FormLabel>Target Platform</FormLabel>
                    <Select
                      borderRadius={"16px"}
                      bg={"#ebebeb"}
                      name="targetPlatform"
                      value={formik.values.targetPlatform}
                      onChange={formik.handleChange}
                    >
                      <option value="android">Android</option>
                      <option value="ios">iOS</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Template Name</FormLabel>
                    <Input
                      borderRadius={"16px"}
                      bg={"#ebebeb"}
                      name="templateName"
                      value={formik.values.templateName}
                      onChange={formik.handleChange}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Template Title</FormLabel>
                    <Input
                      borderRadius={"16px"}
                      bg={"#ebebeb"}
                      name="templateTitle"
                      value={formik.values.templateTitle}
                      onChange={formik.handleChange}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Template Message</FormLabel>
                    <Textarea
                      name="templateMessage"
                      value={formik.values.templateMessage}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </VStack>
              </AccordionPanel>
            </AccordionItem>

            {/* Second Accordion - Notification and Image */}
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Notification and Image
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <VStack spacing={4}>
                  {/* 
                  <FormControl>
                    <FormLabel>Notification Data Map (asdasd)</FormLabel>
                    <Input borderRadius={'16px'} bg={'#ebebeb'}
                      name="notificationDataMap.asdasd"
                      value={formik.values.notificationDataMap.asdasd}
                      onChange={formik.handleChange}
                    />
                  </FormControl> */}

                  <FormControl>
                    <FormLabel>Push Type</FormLabel>
                    <Select
                      borderRadius={"16px"}
                      bg={"#ebebeb"}
                      name="templateType.pushType"
                      value={formik.values.templateType.pushType}
                      onChange={formik.handleChange}
                    >
                      <option value="pushNotification">
                        Push Notification
                      </option>
                      <option value="inApp">In App</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Select
                      borderRadius={"16px"}
                      bg={"#ebebeb"}
                      name="type.type"
                      value={formik.values.type.type}
                      onChange={(e) => {
                        formik.setFieldValue("type.type", e.target.value);
                        formik.setFieldValue(
                          "isTimer",
                          formik.values.type.type == e.target.value
                        );
                      }}
                    >
                      <option value="text">Text</option>
                      <option value="media">Media</option>
                      <option value="timer">Timer</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Template Type</FormLabel>
                    <Select
                      borderRadius={"16px"}
                      bg={"#ebebeb"}
                      name="templateType.type"
                      value={formik.values.templateType.type}
                      onChange={formik.handleChange}
                    >
                      <option value="image">Image</option>
                      <option value="carousel">Carousel</option>
                    </Select>
                  </FormControl>
                  {formik.values.type.type == "timer" && (
                    <>
                      <FormControl>
                        <FormLabel>Time Duration</FormLabel>
                        <Box
                          border={"1px solid"}
                          height={10}
                          fontSize={"md"}
                          p={4}
                          display={"flex"}
                          alignItems={"center"}
                          maxW={"300px"}
                          w={"100%"}
                          ml={3}
                          rounded={"md"}
                          borderColor={"rgb(226, 232, 240)"}
                        >
                          <Flatpickr
                            options={{
                              minDate: "today",
                              enableTime: true,
                              dateFormat: "M d Y, h:i K",
                            }}
                            onChange={(date) => {
                              formik.setFieldValue("timerDuration", date);
                              formik.setFieldValue("isTimer", true);
                            }}
                            value={formik.values.timerDuration}
                          />
                        </Box>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Template Image URL</FormLabel>
                        <Input
                          borderRadius={"16px"}
                          bg={"#ebebeb"}
                          type={"text"}
                          name="templateImageUrl"
                          value={formik.values.templateImageUrl[0]}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "templateImageUrl[0]",
                              e.target.value
                            )
                          }
                        />

                        <ImageGallery
                          setImageData={(value) =>
                            formik.setFieldValue(
                              "templateImageUrl[0]",
                              value.mediaFileUrl
                            )
                          }
                          renderComponent={
                            <Text
                              cursor={"pointer"}
                              textDecoration={"underline"}
                              fontSize={"0.8vw"}
                            >
                              Add Image+
                            </Text>
                          }
                        />
                      </FormControl>
                    </>
                  )}
                  {formik.values.type.type === "media" && (
                    <>
                      {formik.values?.templateType?.type === "carousel" ? (
                        <>
                        {Array.isArray(formik.values.templateImageUrl) && 
                        formik.values?.templateImageUrl?.map((url, index) => (
                          <FormControl key={index}>
                            <FormLabel>Template Image URL</FormLabel>
                            <Input
                              borderRadius={"16px"}
                              bg={"#ebebeb"}
                              type={"text"}
                              name={`templateImageUrl[${index}]`}
                              value={url}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `templateImageUrl[${index}]`,
                                  e.target.value
                                )
                              }
                            />
                            <ImageGallery
                              setImageData={(value) =>
                                formik.setFieldValue(
                                  `templateImageUrl[${index}]`,
                                  value.mediaFileUrl
                                )
                              }
                              renderComponent={
                                <Flex gap={2}>
                                  <Text
                                    cursor={"pointer"}
                                    textDecoration={"underline"}
                                    fontSize={"0.8vw"}
                                  >
                                    Upload Image+
                                  </Text>
                                </Flex>
                              }
                            />
                          </FormControl>
                        ))
                        }
                          
                          <Text
                            width={"100%"}
                            // textAlign={"end"}
                            textDecoration={"underline"}
                            cursor={"pointer"}
                            onClick={() => {
                              formik.setFieldValue("templateImageUrl", [
                                ...formik.values.templateImageUrl,
                                "",
                              ]);
                            }}
                          >
                            Add Another Image+
                          </Text>
                        </>
                      ) : (
                        <>
                          <FormControl>
                            <FormLabel>Template Image URL</FormLabel>
                            <Input
                              borderRadius={"16px"}
                              bg={"#ebebeb"}
                              type={"text"}
                              name={`templateImageUrl[0]`}
                              value={formik.values.templateImageUrl[0]}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "templateImageUrl[0]",
                                  e.target.value
                                )
                              }
                            />
                            <ImageGallery
                              setImageData={(value) =>
                                formik.setFieldValue(
                                  "templateImageUrl[0]",
                                  value.mediaFileUrl
                                )
                              }
                              renderComponent={
                                <Text
                                  cursor={"pointer"}
                                  textDecoration={"underline"}
                                  fontSize={"0.8vw"}
                                >
                                  Add Image+
                                </Text>
                              }
                            />
                          </FormControl>
                        </>
                      )}
                    </>
                  )}
                </VStack>
              </AccordionPanel>
            </AccordionItem>

            {/* Third Accordion - Buttons and Other Settings */}
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Buttons and Other Settings
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <VStack spacing={4}>
                  {Array.isArray(formik?.values?.buttons) &&
                    formik?.values?.buttons.map((button, index) => (
                      <Box
                        key={index}
                        borderWidth="1px"
                        borderRadius="md"
                        p={3}
                        w="full"
                      >
                        <FormControl>
                          <FormLabel>Button Name</FormLabel>
                          <Input
                            borderRadius={"16px"}
                            bg={"#ebebeb"}
                            name={`buttons[${index}].buttonName`}
                            value={button.buttonName}
                            onChange={formik.handleChange}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel>Button Action</FormLabel>
                          <Select borderRadius={"16px"} bg={"#ebebeb"}>
                            {buttonActions.map((btn) => (
                              <option
                                value={btn.name.toLocaleLowerCase()}
                                title={btn.message}
                              >
                                {btn.name}
                              </option>
                            ))}
                          </Select>
                          {/* <Input borderRadius={'16px'} bg={'#ebebeb'}
                          name={`buttons[${index}].buttonAction`}
                          value={button.buttonAction}
                          onChange={formik.handleChange}
                        /> */}
                        </FormControl>

                        <FormControl>
                          <FormLabel>Button URL</FormLabel>
                          <Input
                            borderRadius={"16px"}
                            bg={"#ebebeb"}
                            name={`buttons[${index}].buttonUrl`}
                            value={button.buttonUrl}
                            onChange={formik.handleChange}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel>Coupon Code</FormLabel>
                          <Input
                            borderRadius={"16px"}
                            bg={"#ebebeb"}
                            name={`buttons[${index}].couponCode`}
                            value={button.couponCode}
                            onChange={formik.handleChange}
                          />
                        </FormControl>
                      </Box>
                    ))}
                  <Text
                    width={"100%"}
                    textAlign={"end"}
                    textDecoration={"underline"}
                    cursor={"pointer"}
                    onClick={() => {
                      formik.setFieldValue("buttons", [
                        ...formik.values.buttons,
                        {
                          buttonName: "asdsad",
                          buttonAction: "Navigate to a Screen",
                          buttonUrl: "",
                          couponCode: "",
                        },
                      ]);
                    }}
                  >
                    Add Buttons+
                  </Text>

                  <FormControl>
                    <FormLabel>Push Type</FormLabel>
                    <Select
                      borderRadius={"16px"}
                      bg={"#ebebeb"}
                      name="type.pushType"
                      value={formik.values.type.pushType}
                      onChange={formik.handleChange}
                    >
                      <option value="both">Both</option>
                      <option value="push">Push</option>
                      <option value="inApp">In App</option>
                    </Select>
                  </FormControl>

                  {/* <FormControl>
                    <Checkbox
                      name="isTimer"
                      isChecked={formik.values.isTimer}
                      onChange={formik.handleChange}
                    >
                      Is Timer
                    </Checkbox>
                  </FormControl> */}
                </VStack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Custom Key value (optional)
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <VStack spacing={4}>
                  <FormControl>
                    <FormLabel>Key</FormLabel>
                    <Input
                      borderRadius={"16px"}
                      bg={"#ebebeb"}
                      name="notificationDataMap.key" // Updated name
                      value={formik.values.notificationDataMap.key || ""} // Handle undefined
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Value</FormLabel>
                    <Input
                      borderRadius={"16px"}
                      bg={"#ebebeb"}
                      name="notificationDataMap.value" // Updated name
                      value={formik.values.notificationDataMap.value || ""} // Handle undefined
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <PrimaryButton
            type="submit"
            mt={"10px"}
            float={"right"}
            label={"Submit"}
          />
        </form>
      </Box>
      <Box>
        <MobilePushPreview type={"mobile"} formData={formik.values} />
      </Box>
    </HStack>
  );
};

export default MobilePushCreateTemplate;
