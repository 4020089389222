import React, { Fragment, useEffect, useState } from 'react'
import { Button, Modal, ModalOverlay, Input, ModalContent, ModalHeader, Grid, ModalFooter, Box, ModalBody, ModalCloseButton, Card, HStack, Text, Heading, CardBody, Image, Stack, CardFooter, FormLabel, useDisclosure } from "@chakra-ui/react";
import { getImageGallery } from '../Services/api';
import { useQuery } from 'react-query';
import { usePagination } from '@ajna/pagination';
import PrimaryButton from './themeComponents/PrimaryButton';
import { motion } from 'framer-motion';
import { uploadImage } from '../Services/api';


import {
    Pagination,
    PaginationContainer,
    PaginationNext,
    PaginationPage,
    PaginationPageGroup,
    PaginationPrevious,
} from '@ajna/pagination';
import hexToRgba from 'hex-to-rgba';
import { useToken } from '@chakra-ui/react';
import SwitchableTabs from './themeComponents/SwitchableTabs';
import OutlinedButton from './themeComponents/OutlinedButton';
import { FaUpload } from "react-icons/fa6";
import { HiOutlineCloudUpload } from 'react-icons/hi';
export default function ImageGallery({  setImageData, renderComponent }) {
    const [mainColor] = useToken('colors', ['primary.main'])
    const [totalCount, setTotalCount] = useState(0)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { currentPage, setCurrentPage, pagesCount, pages, pageSize } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 10, isDisabled: false }, limits: { outer: 1, inner: 1 } })
    const imageGallleryQuery = useQuery(['images', currentPage, pageSize], {
        queryFn: async () => {
            let imagesList = await getImageGallery(currentPage, pageSize);
            setTotalCount(imagesList?.data?.count)
            
            return imagesList;
        },
    });
    const postImage = async (post) => {
        let resp = await uploadImage(post);
       
        if (!!resp) {
            imageGallleryQuery.refetch()
        }
    }
    const tabContent = [{
        label: 'Images'
    },
    { label: 'Products' }
    ]

    const selectImage = (image) => {
        setImageData(image);
        onClose()
    }
    useEffect(() => {
       
        imageGallleryQuery.refetch()
    }, [])

    return (
        <Fragment>
           
            <Box onClick={onOpen}>{renderComponent}</Box>
            <Modal size={'4xl'} isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent  >
                    <ModalHeader>
                        <Heading fontSize={'xl'} >
                            Gallery
                        </Heading>
                        <SwitchableTabs tabContent={tabContent} />
                    </ModalHeader>
                    <ModalCloseButton onClick={onClose} />
                    <ModalBody maxH={'700px'} overflowY={'auto'} >
                        <label style={{
                            display: 'flex',
                            backgroundColor: '#007bff',
                            color: 'black',
                            background: 'transparent',
                            textDecoration: 'underline',
                            width: 'fit-content',
                            alignItems: 'center',
                            gap: "4px",
                            padding: '8px 16px',
                            fontSize: '16px',
                            cursor: 'pointer',
                            borderRadius: '4px',
                            textAlign: 'center',
                            textDecoration: 'none',
                            marginBottom: '10px'
                        }}>
                            Upload Image <HiOutlineCloudUpload />
                            <input
                                style={{
                                    position: 'absolute',
                                    top: "7vw",
                                    left: '2vw',
                                    opacity: 0,
                                    width: 'fit-content',
                                    width: '15%',
                                    height: '10%',
                                    cursor: 'pointer',
                                }}
                                name="image"
                                type="file"
                                aria-hidden="true"
                                accept="image/*"
                                onChange={(event) => {
                                    postImage(event.currentTarget.files[0]);
                                }}
                            />
                        </label>
                        {/* <FormLabel my={'1rem'} textAlign={'center'} textDecoration={'underline'} htmlFor="image"> */}
                        {/* <OutlinedButton
                                     label={'Upload Photo'} rightIcon={<HiOutlineCloudUpload />} >
                                         </OutlinedButton>

<Input
                                    name="image"
                                    type="file"
                                    
                                    aria-hidden="true"
                                    cursor={'pointer'}
                                    accept="image/*"
                                    onChange={(event) => {
                                        
                                        uploadImage( event.currentTarget.files[0] )
                                        
                                    }}
                                />
                                </FormLabel>
                                 */}


                        {
                            imageGallleryQuery.isLoading && <Text>
                                Loading...
                            </Text>
                        }
                        {
                            !imageGallleryQuery.isLoading && imageGallleryQuery?.data?.data?.images?.length < 1 && <Text>No data availaible</Text>
                        }
                        {
                            <Grid gap={'5px'} templateColumns={'repeat(4, 1fr)'} >
                                {

                                    !imageGallleryQuery.isLoading && !!imageGallleryQuery?.data?.data?.images?.length > 0 && imageGallleryQuery?.data?.data?.images.map((item) => (

                                        <Card
                                            onClick={() => selectImage(item)}
                                            direction={{ base: 'column', sm: 'row' }}
                                            overflow='hidden'
                                            variant='outline'
                                            backgroundImage={item.mediaFileUrl}
                                            height={'10vw'}
                                            cursor={'pointer'}
                                            width={'10vw'}
                                            title='Select Image'
                                            _hover={{ '.text': { opacity: 1 }, borderColor: 'blue.500' }}
                                        >

                                            <Stack w={'100%'}>

                                                <CardBody w={'100%'}>
                                                    <HStack w={'100%'} height={'100%'} alignItems={'end'} justifyContent={'space-between'} >
                                                        <Text
                                                            size='sm'
                                                            className='text'
                                                            opacity={'0'}


                                                        >
                                                            {item?.fileName}
                                                        </Text>
                                                    </HStack>
                                                </CardBody>

                                            </Stack>
                                        </Card>
                                    ))
                                }
                            </Grid>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Box position={'absoulte'} bottom={0} >

                            {!imageGallleryQuery.isLoading && (
                                <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage} >
                                    <PaginationContainer alignItems={'flex-start'} justifyContent="end" mt={2} position={'sticky'} bottom={'0'} zIndex={'999'} bg={'white'} >
                                        <PaginationPrevious mr={2} onClick={() => setCurrentPage(currentPage - 1)} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&lt;</PaginationPrevious>
                                        <PaginationPageGroup>
                                            {
                                                pages.map(page => (
                                                    <PaginationPage key={`pagination_page_${page}`} page={page} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
                                                ))
                                            }
                                        </PaginationPageGroup>
                                        <PaginationNext ml={2} w={8} bg={hexToRgba(mainColor, .4)} onClick={() => setCurrentPage(currentPage + 1)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
                                    </PaginationContainer>
                                </Pagination>
                            )}
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Fragment>
    )
}
