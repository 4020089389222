import { Box, Icon, Input, InputGroup, InputLeftElement, Table, TableContainer, Text, useDisclosure, Select, FormControl, FormLabel, useToken, Flex, HStack } from '@chakra-ui/react'
import React, { Fragment, useRef, useState } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import CampaignDraftDrawer from './CampaignDraftDrawer'
import DateRangeSelection from '../../components/DateRangeSelection'
import moment from 'moment'
import TableHead from './components/TableHead'
import CampaignTable from './components/CampaignTable'
import { useQuery } from 'react-query'
import { getBroadcastData, prepareCampaignListData } from '../../_helpers/data_helper'
import { getBroadcastHeaders, getCampaigns } from '../../Services/api'
import * as _ from 'lodash'

import CampaignFilter from './components/CampaignFilter'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from '@ajna/pagination'
import hexToRgba from 'hex-to-rgba'
import { OvalSpinner } from '../../components/Loaders'
import OutlinedButton from '../../components/themeComponents/OutlinedButton'

function CampaignList({ org }) {
  const [mainColor] = useToken('colors', ['primary.main'])
  const [dateRange, setDateRange] = useState({ startDate: moment().subtract(30, "days"), endDate: moment() });
  const [totalCount, setTotalCount] = useState(0)
  const [selectChannel, setSelectedChannel] = useState("")
  const [broadcastHeaders,setBroadcastHeaders] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [campaignFilter, setCampaignFilter] = useState({
    campaignName: '',
    channel: '',
    status: 'ALL'
  });
  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 5, isDisabled: false }, limits: { outer: 1, inner: 1 } })
  const campaignQuery = useQuery(['campaigns', dateRange, campaignFilter, currentPage], {
    queryFn: async () => {
      let res = await getCampaigns(org.token, campaignFilter, dateRange, currentPage);
      let broadcastHeaders = await getBroadcastHeaders();
      // setSelectedChannel(Object.keys(broadcastHeaders.data)[0])
      setTotalCount(res?.data?.count);
      
      setBroadcastHeaders( broadcastHeaders.data)
      if(campaignFilter.channel == 'Email' || campaignFilter.channel==''){
        if(!broadcastHeaders.data.hasOwnProperty("All")){
          setCampaignFilter({channel:"Email"})
        }
      }
      return prepareCampaignListData(res.data.campaigns, broadcastHeaders.data, campaignFilter)
    }
  })
  
    
  const tableRef = useRef();
  const menuList = useRef();
  // if (!!document.querySelector('.css-1km9ei0')) {
  //   document.querySelector('.css-1km9ei0').addEventListener('scroll', (e) => { 
  //     console.log(menuList.current.id)
  //     if(menuList?.current.offsetParent.style.visibility=='visible'){
  //       menuList.current.offsetParent.style.visibility = 'hidden'
  //     }
  //     // menuList.current.blur()
      
  //     // menuList.current.attributes.style.value = menuList?.current?.attributes?.style.value.replace('visible','hidden')
      
  //   })
  // }


  function onDateChange(date) {
    setDateRange({ startDate: date.startDate, endDate: date.endDate })
  }
  return (
    <Fragment>
      <DateRangeSelection dateRange={dateRange} onDateChange={onDateChange} isLoading={campaignQuery.isLoading}></DateRangeSelection>
      <Box zIndex={99} display={'flex'} alignItems={'center'} justifyContent={'space-between'} my={4}>
        <Text fontSize={'1vw'} as={'b'}>Campaigns</Text>
        <OutlinedButton label={'Create Campaign'} onClick={() => onOpen()}></OutlinedButton>
        {/* <HStack width={'15%'} > 
        <Text>
          Create
        </Text>

        <Select  onClick={(e)=>{ broadcastTypes[e.target.value]() }} >
          { ["AB TESTING", "SINGLE SEND"].map(campaign=>(
            <option>{campaign}</option> 
          ))
        }
        </Select>
        </HStack> */}
      </Box>
      <CampaignFilter  channels={broadcastHeaders} onFilter={(dt) => setCampaignFilter(dt)} />
        
      <TableContainer bg={'white'} maxH={'60vh'} w={'99%'} overflowY={'auto'} mt={0} >
        {
          campaignQuery.isLoading && <Flex justifyContent={'center'} alignItems={'center'} h={'200px'}><OvalSpinner width={50} height={50}></OvalSpinner></Flex>
        }
        <Table >
          {
            !!campaignQuery?.data?.data?.length>0?(<>
            <Fragment>
              <TableHead headersProps={ Object.keys(broadcastHeaders).includes("All") ? ['Campaign Name', 'Status', 'Sent At', 'Performance', 'Enagement', 'Revenue', 'Action'] : broadcastHeaders[((campaignFilter.channel) )]}></TableHead>
              <CampaignTable broadcastHeaders = {broadcastHeaders } tableRef={tableRef} menuList={menuList} campaignData={campaignQuery.data?.data}></CampaignTable>
            </Fragment></>):(<><Text>No campaigns are created</Text></>)   

          }
        </Table>

      </TableContainer>
      
        {
          !campaignQuery.isLoading && !!campaignQuery?.data?.data?.length>0 &&  <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage} >
            <PaginationContainer alignItems={'center'} justifyContent="end" mt={2}>
              <PaginationPrevious  mr={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&lt;</PaginationPrevious>
              <PaginationPageGroup height={8}  >
                {
                  pages.map(page => (
                    <PaginationPage key={`pagination_page_${page}`} page={page} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
                  ))
                }
              </PaginationPageGroup>
              <PaginationNext ml={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
            </PaginationContainer>
          </Pagination>
        }
      
      <CampaignDraftDrawer  isOpen={isOpen} onClose={onClose} orgToken={org.token}></CampaignDraftDrawer>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state,
    org: state.auth.selectedOrg
  }
}
export default connect(mapStateToProps)(CampaignList)
