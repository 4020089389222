import axios from "axios";
import _, { initial } from "lodash"
import { LuBellDot } from "react-icons/lu";
import { MdOutlineEmail, MdOutlineSms, MdOutlineWhatsapp } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import moment from "moment";
import { getTargetAudienceTypes } from "./helpers";
import { UPDATE_INVALID_CSV_COUNT, UPDATE_REACH } from "../store/Reducers/CampaignReducer";
import { AiOutlineMail } from "react-icons/ai";
import { PiNotificationDuotone } from "react-icons/pi";
import { FaRegBell } from "react-icons/fa";
import { TbDeviceMobileMessage } from "react-icons/tb";
export const prepareRevenueBreakdownData = (data) => {
    const initialData = {
        bestPerformingCampaigns: [],
        chartData: {}
    };
    if (!_.isEmpty(data?.best_performing_campaigns)) {

        Object.keys(data?.best_performing_campaigns).map(key => {
            initialData.bestPerformingCampaigns.push({ y: data?.best_performing_campaigns[key], label: key })
        })
    }

    initialData.chartData = [
        { y: data?.revenue_by_broadcast, label: 'Campaigns' },
        { y: data?.revenue_by_automation, label: 'Flows' }
    ]
    return initialData;
}
export const prepareRevenueStats = (data) => {
    let initialData = []
    Object.keys(data).map(key => {
        initialData.push({ x: key, y: data[key] })
    })
    return initialData;
}
export const prepareAllEvents = (data) => {
    let retVal = [];
    !_.isEmpty(data) && _.forEach(data, function (value, key) {
        retVal.push({ label: key, value: key, eventValue: value })
    })
    return retVal;
}
export const prepareDefaultsEvents = (data) => {

    let retVal = [];
    if (!_.isEmpty(data)) {
        let selectedEvents = _.pick(data, Object.keys(getDefalultEvents()));
        _.forEach(selectedEvents, function (value, key) {
            retVal.push({ label: key, value: key, eventValue: value })
        })
    }
    return retVal;

}
export const prepareEventsChartData = (data) => {
    let retVal = [];
    if (!_.isEmpty(data)) {
        let selectedEvents = _.pick(data, Object.keys(getDefalultEvents()));
        _.forEach(selectedEvents, function (value, key) {
            retVal.push({ x: key, y: value })
        })
    }
    return retVal;
}

export const prepareSubscribersCountData = (data) => {

    let retVal = [];
    if (!_.isEmpty(data)) {
        retVal = [
            { label: 'Email', value: data?.email, color: '#65a6fa' },
            { label: 'Phone', value: data?.sms, color: '#00cadc' },
            { label: 'Push', value: data?.push, color: '#1cbbf3' }
        ]
        let totalSub = _.sumBy(retVal, 'value');

        retVal.map(dt => {
            dt['totalSubscribers'] = totalSub;
            dt['width'] = !!dt.value ? (100 * dt.value) / totalSub : 0;
            return dt
        });
    }
    return retVal;
}

export function getBroadcastData() {
    return axios.get('/dummydata.json')
    //return campaignDummyData;
}

export const channels = {
    email: { icon: MdOutlineEmail, label: 'Email', value: 'email' },
    sms: { icon: MdOutlineSms, label: 'SMS', value: 'sms' },
    whatsapp: { icon: FaWhatsapp, label: 'WhatsApp', value: 'whatsapp' },
    push: { icon: LuBellDot, label: 'Push', value: 'push' }
}
export const channelMetrics = {
    sent: { label: 'Sent', color: 'primary.main' }, received: { label: 'Delivered', color: 'chartColors.color1' }, delivered: { label: 'Delivered', color: 'chartColors.color1' }, orders: { label: 'Conversion', color: 'teal' },
    opened: { label: 'Opened', color: 'chartColors.color2' }, clicked: { label: 'Clicked', color: 'chartColors.color3' }, orderNumber: { label: 'Conversion', color: 'teal' }
}
export const engagementMatrics = {
    openedRate: { label: 'Open Rate', color: 'chartColors.color1' }, ctr: { label: 'Click-through-Rate', color: 'chartColors.color2' }, cvr: { label: 'Conversion Rate', color: 'teal' }
}
function getGraphValue(pt, data) {
    let value;
    if (pt === 'sent') {
        value = !!data[pt] ? 100 : 0;
    } else if (pt === 'received') {
        value = data[pt] === data['sent'] ? 100 : (100 * data?.received) / data?.sent
    } else {
        value = (100 * data[pt]) / data?.sent
    }
    return value;
}

function getCampaignPerformanceData(data, headers, selectedChannel) {

    let retVal = [];
    let performanceMetrices = ['sent', 'received', 'opened', 'clicked', 'orderNumber'];
    performanceMetrices.map(pt => {
        retVal.push({
            label: channelMetrics[pt].label,
            value: pt == 'sent' ? data['messagesSentCount'] || data[pt] : data[pt],
            graphValue: getGraphValue(pt, data),
            color: channelMetrics[pt]?.color
        })
    })
    return retVal;

}

export function getCampaignEngagement(data) {
    let retVal = [];
    let engagement = _.isEqual(data?.selectedChannel, 'sms') ? ['ctr', 'cvr'] : ['openedRate', 'ctr', 'cvr'];
    engagement.map(eng => {
        retVal.push({
            label: engagementMatrics[eng].label,
            value: data[eng],
            color: engagementMatrics[eng]?.color
        })
    })
    return retVal;
}
function getActions(data) {
    let retVal = [];
    !!data?.actions?.length && data.actions.map(ac => {
        retVal.push({ label: campaignActions[ac], value: ac })
    })
    return retVal;
}
let calculateTotalReach = function (campaignData) {
    const includes = campaignData.targetedAudience.includes;
    const totalReach = includes.reduce((sum, segment) => sum + segment.estimateReach, 0);
    return totalReach;
};
export const prepareCampaignListData = (data, headers, campaignFilter) => {

    let retVal = {};
    if (!!data.length) {
        retVal['hasData'] = true;
        retVal['data'] = [];
        data.map(dt => {
            retVal.data.push({
                name: dt?.campaignData?.campaignName,
                channel: dt.selectedChannel,
                status: dt.campaignStatus,
                failed: dt.failed,
                clicked: dt.clicked,
                createdDate: dt.lastUpdatedAt,
                sentAt: dt.startedAt,
                revenue: dt.revenue,
                sent: dt?.messagesSentCount || dt?.campaignStatusData?.messagesSentCount || dt.sent || 0 ,
                opened: dt.opened,
                failed: dt.failed,
                total_reach: calculateTotalReach(dt.campaignData),
                delivered: dt.delivered || dt.received,
                performance: getCampaignPerformanceData(dt, headers),
                engagement: getCampaignEngagement(dt),
                actions: getActions(dt),
                uuid: dt?.uuid,
                controlGroupMetrics	: dt.controlGroupMetrics
            })
        })

    } else {
        retVal['hasData'] = false;
    }
    return retVal;
}

export const campaignStatus = [
    { key: 'ALL', label: 'All' },
    { key: 'RUNNING', label: 'Sent' },
    { key: 'RECURRING', label: 'Recurring' },
    { key: 'SCHEDULED', label: 'Scheduled' },
    { key: 'PROCESSING', label: 'Processing' },
    { key: 'COMPLETED', label: 'Completed' },
    { key: 'ERROR', label: 'Error' },
    { key: 'WAITING', label: 'Waiting' },
    { key: "STOPPED", label: "Stopped" },
    { key: "DRAFT", label: 'Draft' }
];

export const campaignSteps = [
    { title: 'First', description: 'Campaign Information & Audience' },
    { title: 'Second', description: 'Template Selection' },
    { title: 'Third', description: 'Schedule Campaign' },
]

export const targetAudienceSteps = [
    { title: 'First', description: 'Organisation & Config' },
    { title: 'Second', description: 'Target Group' },
    { title: 'Third', description: 'Template' },
    { title: 'Fourth', description: 'Schedule Campaign' },
]
export const campaignActions = {
    edit: "Edit",
    publish: "Publish",
    resume: 'Resume',
    delete: "Delete",
    downloadReport: "Download Report",
    pause: "Pause Campaign"

}


export const createQueryDataForCampaign = (filterData, dateRange, pagination) => {
    return {
        status: !!filterData.status ? filterData.status : "ALL",
        channel: (!!filterData.channel && filterData.channel == 'All') ? '' : !!filterData.channel ? filterData.channel.toLowerCase() : '',
        perpage: 5,
        campaignName: filterData.campaignName, startDate: moment(dateRange.startDate).format("yyyy-MM-DD"),
        endDate: moment(dateRange.endDate).format("yyyy-MM-DD"),
        v: 2
    }
    // return queryData;
};


export const prepareDraftCampaignData = (data) => {
    if (!!data && _.isEmpty(data)) return {};
    return {
        selectedChannel: data.selectedChannel,
        campaignName: data.campaignName,
        campaignType: data.campaignData.campaignType,
        // broadcastId: data.broadcastId,
        broadcastType: 'BROADCAST_V2',
        broadcast: "BROADCAST_V2",
        selectedTemp: "",
        selectedSmtpType: "promotional",
        targetedAudience: {
            includes: {
                CSV: [],
                segment: []
            },
            excludes: []
        },
        scheduleData: {
            scheduleStatus: "SCHEDULED",
            scheduleAt: null,
            repeatStopDateTime: null, //after 4 days
            repeatAfterDays: 1,
        }

    }
}

export const prepareSegmentList = (data) => {


    let retVal = [];
    // retVal.push({ label: 'All Subscribers', value: 'sendToAll', segmentType: 'sendToAll' })
    // if (process.env.NODE_ENV === 'development') {
    //     retVal.push({ label: 'All Dummy Subscribers', value: 'sane', segmentType: 'sendToAll' })
    // }
    
    !!data.length && data.map((dt) => {
        retVal.push({ label: dt.name, value: dt.id, segmentType: getTargetAudienceTypes.CUSTOM_SEGMENT, data: dt })
    })
    return retVal;
}
export const prepareOrgList = (data) => {
    let retVal = [];
    !!data?.length && data.map((dt) => {
        retVal.push({ label: dt.name, value: dt.token, id: dt.id })
    })
    return retVal;
}
export const prepareCSVList = (data) => {
    
    let retVal = [];
    !!data?.length && data?.map(dt => {
        retVal.push({ label: dt.name, value: dt.id, segmentType: 'CSV', data: dt })
    })
    return retVal;
}
export const prepareAllBankCSVList = (data, orgToken) => {

    let retVal = {};
    let orgObj = {};
    Object.keys(data).forEach((org) => {
        retVal[org] = []
        !!data[org]['uploadedFiles'].length && data[org]['uploadedFiles'].map(dt => {
            retVal[org].push({ label: dt.name, value: dt.id, segmentType: 'CSV', data: dt })
        })
        orgObj[org] = retVal[org];
    })

    return orgObj;
}

export const checkForInvalidCsv = (csvList, dispatch) => {
    let invalidCsvCount = 0;
    let invalidCSVId = [];
    csvList.length && csvList.map(l => {
        !!_.isEmpty(l.data?.extraData?.validationData) && invalidCSVId.push(l.value);
    })
    dispatch(UPDATE_INVALID_CSV_COUNT(invalidCSVId))

}

export const extractSelectedCsvFromTargetAudience = (list) => {
    return _.filter(list, function (o) {
        return o?.segmentType === 'CSV';
    })
}

export const updateCsvList = (header) => {
    let retVal = {};
    let parsedHeaders = header.map((hd, index) => {
        return { key: hd.trim(), index: index + 1 }
    })
    retVal['parsedHeaders'] = parsedHeaders;
    retVal['headers'] = header;
    return retVal;

}

export const calculateReach = (formikInstance, dispatch, allSubscribersQuery) => {
    setTimeout(() => {
        let selectedSegment = formikInstance.values.targetedAudience.includes.segment;
        let selectedCsv = formikInstance.values.targetedAudience.includes.CSV;

        dispatch(UPDATE_REACH({ count: 0, isLoading: false }))
    }, 10)
}

export let prepareMultiOrgTemplates = (res,channel) => {
    
    if(channel == 'email'){
        let templateTypesEmail = {
            header: [],
            body: [],
            footer: [],
    
        };
    
        res.map((x) => {
            if (!!x.type) {
                templateTypesEmail[x.type].push(x);
            } else {
                templateTypesEmail["body"].push(x)
            }
        });
        return templateTypesEmail;
    }
    if(channel=='whatsapp'){
        let retVal = []
        res.length && res.map((dt) => {
            retVal.push({
                label: dt.templateDisplayName ? dt.templateDisplayName : dt.templateName,
                uuid: dt.uuid, dt
            });
        });
        return retVal;
    }
    if(channel=='sms'){
        let retVal = []
        res?.length && res?.map((dt) => {
            retVal.push({
                label: dt.name,
                uuid: dt.uuid,
                dt
            });
        });
        return retVal;
    }
    
}

export const prepareTemplateList = (channel, data) => {
    
    let retVal = [];
    if (channel === 'email') {
        data?.length && data.map((dt) => {
            retVal.push({ label: dt.name, body: dt.body, uuid: dt.uuid })
        })

    } else if (channel === 'push') {
        data?.length && data.map((dt) => {
            retVal.push({ label: dt.name, uuid: dt.uuid })
        })
    } else if (channel === 'whatsapp') {
        data?.templates.length && data?.templates.map((dt) => {
            retVal.push({
                label: dt.templateDisplayName ? dt.templateDisplayName : dt.templateName,
                uuid: dt.uuid, dt
            });

        });
    }
    else if (channel === 'sms') {

        data?.length && data?.map((dt) => {

            retVal.push({
                label: dt.name,
                uuid: dt.uuid,
                dt
            });

        });
    }

    return retVal;
}
export const prepareGatewayList = (data) => {
    let retVal = []
    data.length && data.map((dt) => {
        retVal.push({ label: dt.gatewayName, value: dt.gatewayUuid })
    })
    return retVal;
}

export const smtpTypes = [{ label: 'Transactional', key: 'transactional' }, { label: 'Promotional', key: 'promotional' }]

export const winningCriteria = {
    email: [{ label: 'Opened', key: 'emailopen' }, { label: 'Clicked', key: 'emaillinkclicked' }],
    sms: [{ label: 'Clicked', key: 'smsclicked' }, { label: 'Delivered', key: 'smsdelivered' }],
    whatsapp: [{ label: 'Clicked', key: 'whatsappclicked' }, { label: 'Viewed', key: 'whatsappviewed' }]

}
//export const campaignScheduleStatus = [{label: 'Send Now', value: 'NOW'}, {label}]

export const updateCsvListAfterValidation = (selectdList, validatedList, orgToken) => {
    if (selectdList) {

        let list = [];
        try {
            list = [...selectdList[orgToken.org_token]];
        } catch (e) {
            list = selectdList;
        }
        validatedList?.length && validatedList?.map((l, index) => {
            let idx;
            try {

                idx = list?.findIndex(x => x?.value === l?.value);
            } catch (e) {
                idx = list['uploadedFiles']?.findIndex(x => x?.value === l?.value);

            }
            (idx > -1) && (list[idx].data['extraData'] = l?.data?.extraData)
        })
        return list;
    }
}
function AudienceChunks(segmentType, selectedSegment, selectedSegmentName, estimatedReach, headers, index, total_rows, actualReach, targetRatio,templateRatio) {
    debugger
    this.segmentType = segmentType;
    this.selectedSegment = selectedSegment;
    this.selectedSegmentName = selectedSegmentName;
    this.estimateReach = estimatedReach;
    this.actualReach = actualReach || estimatedReach;
    this.targetRatio = targetRatio
    this.templateRatio=templateRatio;
    if (segmentType === 'CSV') {
        this.headers = Array.isArray(headers) && headers.length ? headers.join() : "";
        this.communicationIndex = !!index ? index - 1 : null;
        this.totalRows = total_rows;
    }
    return this
}
// export const prepareTargetedAudienceData = (data, broadcastType) => {
// console.log(data,"data for prep")
// console.log(broadcastType,"broadcastType")
//     let retVal = {
//         includes: [],
//         excludes: []
//     }
//     let selectedSegmentData = data?.includes?.segment || data?.segment
//     let selectedCsv = data?.includes?.CSV || data?.csv;
//     let templateRato=data?.includes[0]
//     console.log(templateRato,"TR")
//     if (selectedSegmentData.length && !!(_.findIndex(selectedSegmentData, { segmentType: 'sendToAll' }))) {
//         // let allSubObj = _.find(selectedSegmentData, {segmentType: 'sendToAll'});
//         retVal.includes.push(new AudienceChunks("sendToAll", null, "Send To All", 0))
//     }
//     // if(broadcastType=="BATCH_WISE_CONTROL_GROUP"){
        
//     //         console.log("Reaching here")
//     // }
//     if(broadcastType=="BATCH_WISE_CONTROL_GROUP"){
//         !!selectedSegmentData.length && selectedSegmentData.map(dt => {
            
//             let targetRatio  = 100 - parseInt(dt.targetRatio);
//             let totalReach = dt?.data?.extraData?.validationData?.counts?.estimated_reach;
//             templateRato;
//             let actualReach =  Math.ceil(totalReach - dt?.actualReach)
//             retVal.includes.push(new AudienceChunks(dt?.segmentType, dt?.value, dt.label, 0, undefined, undefined, undefined, actualReach, targetRatio,templateRato));

//         })    
//     }else{

//         !!selectedSegmentData.length && selectedSegmentData.map(dt => {
            
//             retVal.includes.push(new AudienceChunks(dt?.segmentType, dt.value, dt.label, 0, undefined, undefined, undefined, dt.actualReach, dt.targetRatio,))
//         })
//     }
 
        
//     if(broadcastType=="CONTROL_GROUP"){
//         !!selectedSegmentData.length && selectedSegmentData.map(dt => {
            
//             let targetRatio  = 100 - parseInt(dt.targetRatio);
//             let totalReach = dt?.data?.extraData?.validationData?.counts?.estimated_reach

//             let actualReach =  Math.ceil(totalReach - dt?.actualReach)
//             retVal.includes.push(new AudienceChunks(dt?.segmentType, dt?.value, dt.label, 0, undefined, undefined, undefined, actualReach, targetRatio));

//         })    
//     }else{

//         !!selectedSegmentData.length && selectedSegmentData.map(dt => {
            
//             retVal.includes.push(new AudienceChunks(dt?.segmentType, dt.value, dt.label, 0, undefined, undefined, undefined, dt.actualReach, dt.targetRatio))
//         })
//     }
//     if(broadcastType=="CONTROL_GROUP"){
//     !!selectedCsv.length && selectedCsv.map(dt => {
        
//         let targetRatio  = 100 - parseInt(dt.targetRatio);
//         let totalReach = dt.data.extraData.validationData.counts.estimated_reach;
//         let actualReach =  Math.ceil(totalReach - dt?.actualReach)
//         retVal.includes.push(new AudienceChunks("CSV", dt.data.url, dt.label,
//             dt?.data?.extraData?.validationData?.counts?.estimated_reach || 0,
//             dt?.data?.extraData?.headers,
//             Array.isArray(dt?.data?.extraData?.mapped_headers) ? dt?.data?.extraData?.mapped_headers[0]?.index : dt?.data?.extraData?.mapped_headers?.index,
//             dt?.data?.extraData?.validationData?.counts?.total_rows || 0,
//             actualReach,
//             targetRatio
//         ))
//     })
    
//     }else{
//         !!selectedCsv.length && selectedCsv.map(dt => {
//             console.log(dt,"dtttt")

//             retVal.includes.push(new AudienceChunks("CSV", dt.data.url, dt.label,
//                 dt?.data?.extraData?.validationData?.counts?.estimated_reach || 0,
//                 dt?.data?.extraData?.headers,
//                 Array.isArray(dt?.data?.extraData?.mapped_headers) ? dt?.data?.extraData?.mapped_headers[0]?.index : dt?.data?.extraData?.mapped_headers?.index,
//                 dt?.data?.extraData?.validationData?.counts?.total_rows || 0,
//                 dt.actualReach,
//                 dt.targetRatio
//             ))
//         })
//     }
//     // debugger
//     return retVal;
// }
export const prepareTargetedAudienceData = (data, broadcastType,channel) => {
    
    debugger
    let retVal = {
        includes: [],
        excludes: []
    };

    // Extract segment and CSV data
    let selectedSegmentData = data?.includes?.segment || [];
    let selectedCsv = data?.includes?.CSV || [];
    
    
    let templateRatio = data?.includes[0]

    // if (selectedSegmentData.length && !!(_.findIndex(selectedSegmentData, { segmentType: 'sendToAll' }))) {
    //     retVal.includes.push(new AudienceChunks("sendToAll", null, "Send To All", 0));
    // }
    
    
    if (broadcastType === "BATCH_WISE_CONTROL_GROUP") {
        selectedSegmentData.forEach(dt => {
            if(!_.isEmpty(dt?.data?.aggregates) ){
                let targetRatio = 100 - parseInt(dt.targetRatio);
                let totalReach = !!dt?.data?.aggregates[channel=='email' ? 'count_email' : 'count_phone']? dt?.data?.aggregates[channel=='email' ? 'count_email' : 'count_phone'] : 0
                let actualReach = totalReach
                retVal.includes.push(new AudienceChunks(dt?.segmentType, dt?.data?.id, dt.label, actualReach, undefined, undefined, undefined, totalReach, targetRatio || 0,templateRatio));
            }
            if(!_.isEmpty(dt?.aggregates) ){
                let targetRatio = 100 - parseInt(dt.targetRatio);
                let totalReach = !!dt?.aggregates[channel=='email' ? 'count_email' : 'count_phone']? dt?.aggregates[channel=='email' ? 'count_email' : 'count_phone'] : 0
                let actualReach = totalReach
                retVal.includes.push(new AudienceChunks("custom", dt?.id, dt.label, actualReach, undefined, undefined, undefined, totalReach, targetRatio || 0,templateRatio));
            }
        });
    } 

    else if(broadcastType === "CONTROL_GROUP") {
        selectedSegmentData.forEach(dt => {
            if(!_.isEmpty(dt?.data?.aggregates) ){
            let targetRatio = 100 - parseInt(dt.targetRatio);
            let totalReach = !!dt?.data?.aggregates[channel=='email' ? 'count_email' : 'count_phone']? dt?.data?.aggregates[channel=='email' ? 'count_email' : 'count_phone'] : 0;
            let actualReach = Math.ceil(totalReach - dt?.actualReach);
            retVal.includes.push(new AudienceChunks("custom", dt?.data?.id, dt.label, totalReach, undefined, undefined, undefined, actualReach, targetRatio || 0, templateRatio));
            }
            if(!_.isEmpty(dt?.aggregates)){
                let targetRatio = 100 - parseInt(dt.targetRatio);
                let totalReach = !!dt?.aggregates[channel=='email' ? 'count_email' : 'count_phone']? dt?.aggregates[channel=='email' ? 'count_email' : 'count_phone'] : 0;
                let actualReach = Math.ceil(totalReach - dt?.actualReach);
                retVal.includes.push(new AudienceChunks("custom", dt?.id, dt.label, totalReach, undefined, undefined, undefined, actualReach, targetRatio || 0, templateRatio));
                }
        });
    } else {
            selectedSegmentData.forEach(dt => {
                if(!_.isEmpty(dt?.data?.aggregates) ){
                let totalReach = !!dt?.data?.aggregates[channel=='email' ? 'count_email' : 'count_phone'] ? dt?.data?.aggregates[channel=='email' ? 'count_email' : 'count_phone'] : 0
                retVal.includes.push(new AudienceChunks("custom", dt?.data?.id, dt.label,totalReach, undefined, undefined, undefined, dt.actualReach, dt.targetRatio || 0, templateRatio));
                }
                if(!_.isEmpty(dt?.aggregates) ){
                    let totalReach = !!dt?.aggregates[channel=='email' ? 'count_email' : 'count_phone'] ? dt?.aggregates[channel=='email' ? 'count_email' : 'count_phone'] : 0
                    retVal.includes.push(new AudienceChunks("custom", dt?.id, dt.label,totalReach, undefined, undefined, undefined, dt.actualReach, dt.targetRatio || 0, templateRatio));
                    }
            });
        
    }

    
    if (broadcastType === "CONTROL_GROUP") {
        selectedCsv.forEach(dt => {
            let targetRatio = 100 - parseInt(dt.targetRatio);
            let totalReach = dt.data.extraData.validationData.counts.estimated_reach;
            let actualReach = Math.ceil(totalReach - dt?.actualReach);
            retVal.includes.push(new AudienceChunks("CSV", dt.data.url, dt.label,
                dt?.data?.extraData?.validationData?.counts?.estimated_reach || 0,
                dt?.data?.extraData?.headers,
                Array.isArray(dt?.data?.extraData?.mapped_headers) ? dt?.data?.extraData?.mapped_headers[0]?.index : dt?.data?.extraData?.mapped_headers?.index,
                dt?.data?.extraData?.validationData?.counts?.total_rows || 0,
                actualReach,
                targetRatio,
                templateRatio 
            ));
        });
    } else {
        selectedCsv.forEach(dt => {
            retVal.includes.push(new AudienceChunks("CSV", dt.data.url, dt.label,
                dt?.data?.extraData?.validationData?.counts?.estimated_reach || 0,
                dt?.data?.extraData?.headers,
                Array.isArray(dt?.data?.extraData?.mapped_headers) ? dt?.data?.extraData?.mapped_headers[0]?.index : dt?.data?.extraData?.mapped_headers?.index,
                dt?.data?.extraData?.validationData?.counts?.total_rows || 0,
                dt.actualReach,
                dt.targetRatio,
                templateRatio 
            ));
        });
    }

    return retVal; 
}

export const preparePublishData = (id, scheduleData, action, orgId) => {
    // let data = {};
    let data = Object.assign({}, scheduleData);
    data['orgId'] = orgId;
    data["action"] = !!action ? action : scheduleData.scheduleStatus;
    data["id"] = id;
    
    data.scheduleAt = !!data.scheduleAt ? Date.parse(data.scheduleAt) : Date.parse(moment().add(10, "minutes").format('lll'));
    data.repeatStopDateTime = !!data.repeatStopDateTime ? Date.parse(data.repeatStopDateTime) : Date.parse(moment().add(10, "minutes").format('lll'));
    delete data.scheduleStatus;
    return data;
}

export const preparePublishDataAllBanks = (draftResponse, scheduleData) => {
    let data = {};
    // let data = Object.assign({}, scheduleData);
    let arr = [];
    draftResponse.forEach((dt, i) => {
        data["scheduleAt"] = !!scheduleData[i].scheduleAt ? Date.parse(scheduleData[i].scheduleAt) : Date.parse(moment().add(10, "minutes").format('lll'));
        data["action"] = scheduleData[i].scheduleStatus;
        data["id"] = dt.id
        data["orgId"] = dt.orgId
        data["repeatStopDateTime"] = scheduleData[i].repeatStopDateTime
        arr.push(data)
    })
    return arr;
}

export const prepareFlowActionTemplatesList = (data) => {
    let retVal = {};

    retVal['templates'] = data?.data?.templates && !_.isEmpty(data.data.templates) ? data.data.templates : [];
    retVal["hasAccess"] = data?.data && data.data.hasOwnProperty('hasAccess') ? data.data.hasAccess : false
    return retVal;
}
const getAnalyticsObject = (objectString) => {

    // Remove curly braces and split string by commas
    const keyValuePairs = objectString.slice(1, -1).split(", ");

    // Initialize an empty object
    const obj = {};

    // Loop through key-value pairs
    keyValuePairs.forEach(pair => {
        const [key, value] = pair.split("=");
        obj[key.trim()] = isNaN(value) ? value : parseFloat(value);
    });
    return obj;

}
// function getGraphValueForFlows(pt, data) {
//     let value;
//     if (pt === 'sent') {
//         value = !!data[pt] ? 100 : 0;
//     } else if (pt === 'received') {
//         value = data[pt] === data['sent'] ? 100 : (100 * data?.received) / data?.sent
//     } else {
//         value = (100 * data[pt]) / data?.sent
//     }
//     return value;
// }
const getFlowPerformance = (data) => {
    let performanceMatrices = ['Sent', 'Received', 'Opened', 'Clicked', 'Orders'];
    let retVal = [];

    performanceMatrices.map(pt => {

        retVal.push({
            label: channelMetrics[pt.toLowerCase()].label,
            value: data[pt == 'Received' ? 'Delivered' : pt],
            graphValue: getGraphValue(pt.toLowerCase(), data),
            color: channelMetrics[pt.toLowerCase()]?.color
        })
    })
    return retVal;
}
export const prepareFlowListData = (data) => {

    let retVal = [];
    if (!!data?.items.length) {
        //retVal["hasData"] = true;
        //retVal["list"] = [];
        data.items.map(dt => {
            let analyticsObj = getAnalyticsObject(dt.analytics)

            retVal.push({
                name: dt.ruleName,
                lastUpdatedAt: moment(dt.createdTimeFormatted).fromNow(),
                description: dt.description,
                revenue: analyticsObj.Revenue,
                timer: dt.timer,
                performance: getFlowPerformance(analyticsObj),
                uuid: dt.uuid,
                status: dt.ruleStatus
            })
        })
    } else {
        //retVal["hasData"] = false;

    }
    //retVal["totalCount"] = data?.count ?? 0

    return retVal
}

let getDefalultEvents = () => {
    return {
        join: 'New Users', productview: 'Product View', addtocart: "Add to Cart",
        checkoutstarted: "Checkout Started", buy: 'Buy',

    }
};


export let getCustomFonts = () => {
    return [
        {
            "name": "Dubai Bold",
            "fontFamily": "Dubai-Bold",
            "url": "https://storage.googleapis.com/media.orbisdata.ai/fonts/dubai-font/dubai-font.css"
        },
        {
            "name": "Dubai Medium",
            "fontFamily": "Dubai-Medium",
            "url": "https://storage.googleapis.com/media.orbisdata.ai/fonts/dubai-font/dubai-font.css"
        },
        {
            "name": "Dubai Light",
            "fontFamily": "Dubai-Light",
            "url": "https://storage.googleapis.com/media.orbisdata.ai/fonts/dubai-font/dubai-font.css"
        },
        {
            "name": "Dubai Regular",
            "fontFamily": "Dubai-Regular",
            "url": "https://storage.googleapis.com/media.orbisdata.ai/fonts/dubai-font/dubai-font.css"
        }, {
            "name": "Gill Sans",
            "fontFamily": "Gill Sans",
            "url": "http://fonts.cdnfonts.com/css/gill-sans"
        }, {
            "name": "Laviossa Font",
            "fontFamily": "Laviossa",
            "url": "https://fonts.cdnfonts.com/css/laviossa"
        },
        {
            "name": "Montserrat Font",
            "fontFamily": "montserrat",
            "url": " http://fonts.cdnfonts.com/css/montserrat"
        },
        {
            "name": "Adobe Garamond Pro",
            "fontFamily": "Adobe Garamond Pro",
            "url": " https://storage.googleapis.com/media.orbisdata.ai/conf/upload/stylesheet.css"
        },
        {
            "name": "EB Garamond",
            "fontFamily": "EB Garamond",
            "url": " https://storage.googleapis.com/media.orbisdata.ai/conf/upload/stylesheet.css"
        },
        {
            "name": "EB Garamond",
            "fontFamily": "EB Garamond",
            "url": " https://storage.googleapis.com/media.orbisdata.ai/conf/upload/stylesheet.css"
        },
        {
            "name": "Futura Lt BT",
            "fontFamily": "Futura Lt BT",
            "url": " https://storage.googleapis.com/media.orbisdata.ai/conf/upload/stylesheet.css"
        },
        {
            "name": "Futura Bk BT",
            "fontFamily": "Futura Bk BT",
            "url": " https://storage.googleapis.com/media.orbisdata.ai/conf/upload/stylesheet.css"
        },
        {
            "name": "Futura Md BT",
            "fontFamily": "Futura Md BT",
            "url": " https://storage.googleapis.com/media.orbisdata.ai/conf/upload/stylesheet.css"
        },
        {
            "name": "Cormorant Garamond",
            "fontFamily": "Cormorant Garamond",
            "url": " https://storage.googleapis.com/media.orbisdata.ai/conf/upload/stylesheet.css"
        },
        {
            "name": "Gill Sans Nova",
            "fontFamily": "Gill Sans Nova",
            "url": "https://fonts.cdnfonts.com/css/gill-sans-nova"
        },
        {
            "name": "Titillium",
            "fontFamily": "Titillium",
            "url": " https://storage.googleapis.com/media.orbisdata.ai/fonts/TitilliumFont/stylesheet.css"
        },
        {
            "name": "Josefin Sans",
            "fontFamily": "'Josefin Sans',sans-serif ",
            "url": "https://fonts.cdnfonts.com/css/josefin-sans"
        },
        {
            "name": "Poppins",
            "fontFamily": "'Poppins', Courier",
            "url": "https://fonts.cdnfonts.com/css/poppins"
        },
        {
            "name": "Inter",
            "fontFamily": "'Inter', Courier",
            "url": "https://fonts.cdnfonts.com/css/Inter"
        },
        {
            "name": "Work Sans",
            "fontFamily": "'Work Sans'",
            "url": "https://fonts.googleapis.com/css?family=Work+Sans"
        }
    ]
}

export const getDefaultMergeTags = () => {
    return [
        { "label": "mergeTag.label.fullName", "value": "%u%" },
        { "label": "mergeTag.label.firstName", "value": "%firstname%" },
        { "label": "mergeTag.label.firstName", "value": "%lastname%" },
        { "label": "Last Viewed Item Name", "value": "%event:view:item{fallback}%" },
        { "label": "Last Viewed Item Desc", "value": "%event:view:desc{fallback}%" },
        { "label": "Last Viewed Item URL", "value": "%event:view:url{fallback}%" },
        { "label": "Last Product Viewed Item Name", "value": "%event:productview:item{fallback}%" },
        { "label": "Last Product Viewed Item Desc", "value": "%event:productview:desc{fallback}%" },
        { "label": "Last Added To Cart Item URL", "value": "%event:addtocart:url{fallback}%" },
        { "label": "Invoice Url for last order", "value": "%event:buy:invoice_url%{fallback}" }
    ];
}

export const getChannelsCardData = () => {
    return [
        { heading: 'Email', description: 'Create attractive emails and enhance customer retention with a cost-effective solution', icon: AiOutlineMail, url: '/email/list' },
        { heading: 'SMS', description: 'Unlock wide-reach & cost-effective conversions through targeted messaging', icon: MdOutlineSms, url: '/sms/list' },
        { heading: 'WhatsApp', description: 'Create hyper-personalized messages to maximize conversion rates', icon: MdOutlineWhatsapp, url: '/whatsapp/list' },
        { heading: 'Onsite Notifications', description: 'Engage website visitors in real-time and improve customer experience', icon: PiNotificationDuotone, url: '/nudge/list' },
        { heading: 'Push Notifications', description: 'Re-engage visitors and prompt them to return and take appropriate action', icon: FaRegBell },
        { heading: 'RCS', description: 'Personalise communications based on user preferences to create an interactive experience', icon: TbDeviceMobileMessage }
    ]
}

export const whatsappCategories = [
    { key: 'marketing', label: 'Marketing' },
    { key: 'one-time passwords', label: 'Authentication' },
    { key: 'transactional', label: 'Utility' },
]

export const buttonData = {
    buttonTypes: ['URL', 'Quick_Replies'],
    urlTypes: ['Static', 'Dynamic']
}
export const campaignTypes = [
    { label: 'Single Send', key: 'single_send' },
    { label: 'A/B Testing', key: 'a_b_test' },
    { label: 'Control Group', key: 'control_group' },
    { label: 'Multiple Schedule', key: 'multiple_schedule' }
];
export const templateTypes = [
    { key: 'text', label: 'Text' },
    { key: 'quick_reply', label: 'Quick Reply' },
    { key: 'cta', label: 'CTA' },
]
export const mediaType = [
    { key: 'none', label: 'None' },
    { key: 'image', label: 'Image' },
    { key: 'text', label: 'Text' },
    { key: 'document', label: 'Document' },
    { key: 'video', label: 'Video' },
]

export const urlTargetingOperators = [
    { label: 'Equals', value: 'equals' },
    { label: 'Not Equals', value: 'notequals' },
    { label: 'Contains', value: 'contains' },
    { label: 'Does not contain', value: 'doesnotcontain' }
]

export const locationPlatformCriteriaProperties = [
    { label: 'Country', code: 'country' }, { label: 'Browser', code: 'browser' }, { label: "Language", code: "language" }
];

export const locationPlatformCriteriaPropertiesOperator = {
    country: [{ label: 'In', code: 'in' }, { label: 'Not In', code: 'notin' }],
    browser: [{ label: 'In', code: 'in' }, { label: 'Not In', code: 'notin' }, { label: 'Starts With', code: 'startswith' }],
    language: [{ label: 'In', code: 'in' }, { label: 'Not In', code: 'notin' }, { label: 'Starts With', code: 'startswith' }]
}

export const browserList = [
    { "name": "Chrome", "code": "Chrome" },
    { "name": "Firefox", "code": "Firefox" },
    { "name": "Safari", "code": "Safari" },
    { "name": "Internet Explorer", "code": "Internet Explorer" },
    { "name": "Opera", "code": "Opera" },
    { "name": "Microsoft Edge", "code": "Microsoft Edge" },
    { "name": "IE Mobile", "code": "IE Mobile" },
    { "name": "Xbox", "code": "Xbox" },
    { "name": "BlackBerry", "code": "BlackBerry" },
    { "name": "Konqueror", "code": "Konqueror" },
    { "name": "Dolphin", "code": "Dolphin" },
    { "name": "Camino", "code": "Camino" },
    { "name": "Apple WebKit", "code": "Apple WebKit" },
    { "name": "Flock", "code": "Flock" }
]

export const selectProvidersValue = [
    {
        key: "Kaleyra",
        value: "Kaleyra"
    },
    {
        key: "Routemobile",
        value: "Routemobile"
    },
    {
        key: "Gupshup",
        value: "Gupshup"
    },
    {
        key: "Sinch",
        value: "Sinch"
    },
    {
        key: "Trustsignal",
        value: "Trustsignal"
    }
]
export const eventProperty = [
    { "name": "Click Stream Events", "code": "Click Stream Events" },
    { "name": "Segment Change Events", "code": "Segment Change events" }
]
export const automationOptions=[
    
    { "name": "Automation", "code": "AUTOMATION" },
    { "name": "Control Group", "code": "CONTROL_GROUP" }
    
]

export const eventValueOperators = [
    {
        "label": "Equals",
        "key": "Equals"
    },
    {
        "label": "Equals (Ignore case)",
        "key": "EqualsIgnoreCase"
    },
    {
        "label": "Starts with",
        "key": "StartsWith"
    },
    {
        "label": "Ends with",
        "key": "EndsWith"
    },
    {
        "label": "In",
        "key": "IN"
    },
    {
        "label": "Contains",
        "key": "Contains"
    },
    {
        "label": "Regular Exp",
        "key": "RegExp"
    },
    {
        "label": "Doesn't contain",
        "key": "DoesNotContain"
    },
    {
        "label": "NotEquals",
        "key": "NotEquals"
    },
    {
        "label": "ContainsIn",
        "key": "ContainsIn"
    },
    {
        "label": "GreaterThan",
        "key": "GreaterThan"
    },
    {
        "label": "GreaterThanEquals",
        "key": "GreaterThanEquals"
    },
    {
        "label": "LessThan",
        "key": "LessThan"
    },
    {
        "label": "LessThanEquals",
        "key": "LessThanEquals"
    },
    {
        "label": "DoesNotContainsIn",
        "key": "DoesNotContainsIn"
    }
];

export const smsChannelData = [
    {
        "label": "Telnyx",
        "key": "Telnyx"
    },

    {
        "label": "Kaleyra",
        "key": "Kaleyra"
    },

    {
        "label": "RouteMobile",
        "key": "RouteMobile"
    },
    {
        "label": "TrustSignal",
        "key": "TrustSignal"
    },
    {
        "label": "Sinch",
        "key": "Sinch"
    }
];
export const smsChannelGatwayType = [
    {
        "label": "Transactional",
        "key": "Transactional",
    },
    {
        "label": "Promotional",
        "key": "Promotional"
    }
]
export const searchDropDown=[
    {
        "label":"OrgId/OrgToken",
        "key": "org_id"
    },{
        "label":"domain",
        "key":"domain"
    },{
        "label":"userName",
        "key":"email"
    }
]
export const emailProviders = [
    {
        "label": "Disk Writer",
        "key": "DiskWriter"
    },

    {
        "label": "Generic SMTP",
        "key": "GenericSMTP"
    },

    {
        "label": "Netcore",
        "key": "Netcore"
    },
    {
        "label": "Mandrill",
        "key": "Mandrill"
    },
    {
        "label": "SendGrid",
        "key": "SendGrid"
    },
    {
        "label": "Amazon SES",
        "key": "AmazonSES"
    },
    {
        "label": "SparkPost",
        "key": "SparkPost"
    }
];

